import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"

import "../../css/pages/messages/view.scss"
import { getResource, specialPut } from "../../redux/action"
import { formatDateTime } from "../../utils/Date"
import {
	isDefined,
	renderIfDefined,
	renderIfDefinedParams,
} from "../../utils/Data"

const MessageView = () => {
	const getId = () => {
		const pathname = window.location.pathname
		const split = pathname.split("/")
		return split[2]
	}

	const id = getId()
	const [message, setMessage] = useState(null)
	const { rawToken, user } = useSelector(({ apiReducer }) => apiReducer)
	const dispatch = useDispatch()

	const getMessaging = (message) =>
		message.messagings.find(
			(messaging) => messaging.receiver.id === user.id
		)

	useEffect(() => {
		dispatch(getResource("messages", id, rawToken)).then((message) => {
			setMessage(message)
		})
	}, [])

	useEffect(() => {
		if (isDefined(message) && isDefined(user)) {
			const messaging = getMessaging(message)
			if (isDefined(messaging))
				dispatch(
					specialPut(messaging.id, rawToken, "messagings", "see")
				)
		}
	}, [message, user])

	return (
		<div className={"box messages-view"}>
			<div className={"box-header"}>
				<div className={"title"}>Message</div>
			</div>
			<div className={"box-body"}>
				{message !== null ? (
					<>
						<div className={"flex"}>
							<div className={"property"}>
								<i className={"fas fa-calendar-alt"} /> Reçu le{" "}
								{formatDateTime(message.sent)}
							</div>
							{message.project !== null ? (
								<>
									<div className={"property"}>
										<i className={"fas fa-archive"} />{" "}
										{renderIfDefinedParams(
											message.project,
											"number"
										)}
									</div>
									<div className={"property"}>
										<i className={"fas fa-users"} />{" "}
										{isDefined(message.project)
											? isDefined(
													message.project.customer
											  )
												? message.project.customer
														.code +
												  " - " +
												  message.project.customer.name
												: ""
											: ""}
									</div>
								</>
							) : (
								""
							)}
							<div className={"property"}>
								<i className={"fas fa-user"} />{" "}
								{renderIfDefinedParams(
									message.sender,
									"firstname"
								) +
									" " +
									renderIfDefinedParams(
										message.sender,
										"lastname"
									)}
							</div>
						</div>
						<h4 className={"title"}>
							{renderIfDefined(message.title)}
						</h4>
						<div className={"text"}>
							{renderIfDefined(message.message)}
						</div>
					</>
				) : (
					"Chargement ..."
				)}

				<Link to={"/messages/" + id + "/repondre"}>
					<button
						className={"sr-btn answer"}
						title={"Répondre au message"}
					>
						Répondre
					</button>
				</Link>
			</div>
		</div>
	)
}

export default withRouter(MessageView)
