import React, { Component } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"

import SubMenu from "./SubMenu"

class MenuItemWithSubMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			submenuOpened: false,
		}
	}

	onOpenedSubmenu = () => {
		this.setState((prevState) => ({
			submenuOpened: !prevState.submenuOpened,
		}))
	}

	render() {
		return (
			<li
				className={"sr-menu-link sr-menu-submenu"}
				onClick={this.onOpenedSubmenu}
			>
				<i className={this.props.icon} />{" "}
				<span>{this.props.title}</span>
				{this.props.mobile ? (
					<i
						className={
							this.state.submenuOpened === false
								? "fa fa-chevron-left chevron"
								: "fa fa-chevron-down chevron"
						}
					/>
				) : (
					""
				)}
				<SubMenu
					mobile={this.props.mobile}
					menuItems={this.props.subMenu}
					opened={this.state.submenuOpened}
					onOpenedSubmenu={this.onOpenedSubmenu}
				/>
			</li>
		)
	}
}

const mapStateToProps = (state) => {
	return { rawToken: state.rawToken }
}

export default withRouter(connect(mapStateToProps)(MenuItemWithSubMenu))
