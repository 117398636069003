import React, { Component } from "react"
import { Redirect } from "react-router"
import { Route } from "react-router-dom"
import { connect } from "react-redux"
import { isDefined } from "../utils/Data"

import PrivateComponent from "./PrivateComponent"
import { getMessagesOfUser, getUser } from "../redux/action"
import { onDisconnected } from "../utils/Alert"

class PrivateRoute extends Component {
	componentDidMount() {
		if (this.props.rawToken) {
			this.checkConnection()

			this.props
				.dispatch(getUser(this.props.payload.id, this.props.rawToken))
				.then(() =>
					this.props.dispatch(
						getMessagesOfUser(
							this.props.user?.id,
							this.props.rawToken
						)
					)
				)
		}
	}

	checkConnection = () => {
		if (isDefined(localStorage.getItem("jwt"))) {
			const token = JSON.parse(localStorage.getItem("jwt"))
			const tokenExpiresAt = token.payload.exp
			const currentTimestamp = Date.now() / 1000
			const threshold = 300

			if (currentTimestamp + threshold >= tokenExpiresAt) onDisconnected()
		}
	}

	render() {
		const getSession = this.props.rawToken !== null
		return (
			<Route path={this.props.path} {...this.props.rest}>
				{getSession === true ? (
					/*checkPermissions(this.props.path, this.props.user) === true ?*/
					<PrivateComponent
						component={this.props.component}
						isCalendar={this.props.isCalendar}
						{...this.props}
					/>
				) : (
					/*: <Redirect to={{pathname: '/', state: {  prevLocation: this.props.path, error: "Vous n'avez pas la permission d'accéder à cette page", }}} />*/

					<Redirect
						to={{
							pathname: "/connexion",
							state: {
								prevLocation: this.props.path,
								error: "Vous devez être connecté pour accéder à cette page",
							},
						}}
					/>
				)}
			</Route>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		payload: apiReducer.payload,
		user: apiReducer.user,
	}
}

export default connect(mapStateToProps)(PrivateRoute)
