import React from "react"
import { useDispatch, useSelector } from "react-redux"
import CreatableSelect from "react-select/creatable"

import { changeQuotation } from "../../../redux/QuotationFormAction"

export const Settlement = () => {
	const dispatch = useDispatch()

	const { settlements } = useSelector(({ apiReducer }: any) => apiReducer)

	const form = useSelector(
		({ quotationFormReducer }: any) => quotationFormReducer.form
	)

	const items = settlements.sort((a, b) => {
		if (a.name < b.name) {
			return -1
		}
		if (a.name > b.name) {
			return 1
		}
		return 0
	})

	const options = [
		{
			value: null,
			label: "",
		},
	].concat(
		items.map((item) => ({
			value: item.id,
			label: item.name,
		}))
	)

	const onChange = (obj) => {
		dispatch(changeQuotation(null, "settlement", obj.value))
	}

	const thereAreSettlements: boolean = settlements?.length > 0

	return (
		<>
			<label>Mode de règlement</label>
			<CreatableSelect
				disabled={!thereAreSettlements}
				options={options}
				name={"settlement"}
				placeholder={
					thereAreSettlements ? "Mode de règlement" : "Chargement ..."
				}
				value={options.find(
					(option) => option.value === form.settlement.value
				)}
				onChange={onChange}
			/>
		</>
	)
}
