import { ActionTypes } from "./action.types"

const {
	CREATE_RESOURCE,
	DELETE_RESOURCE,
	GET_RESOURCE,
	GET_RESOURCES,
	GET_TOKEN,
	GET_USER,
	GET_USERS,
	LOGOUT,
	UPDATE_RESOURCE,
	ERROR,
	GET_NEW_NUM,
	GET_NUMBERS,
	GET_RESOURCE_BY_NUMBER,
	RESET_RESOURCE,
	GET_MESSAGES_OF_USER,
} = ActionTypes

const initialState = {
	error: null,
	rawToken: null,
	payload: null,
	user: null,
	newNum: "",
	projects: [],
	projectsInProgress: [],
	projectsDelivered: [],
	projectsWaiting: [],
	projectsRevived: [],
	projectsToBill: [],
	steps: [],
	notes: [],
	quotations: [],
	quotations_models: [],
	quotationsDrafted: [],
	quotationMeasures: [],
	bills: [],
	billsModels: [],
	articleCategories: [],
	articles: [],
	providers: [],
	productGroups: [],
	productTypes: [],
	products: [],
	customers: [],
	users: [],
	contacts: [],
	messages: [],
	messagings: [],
	messagesSent: [],
	settlements: [],
	deliveryDelays: [],
	stepSettings: [],
	adresses: [],
	colors: [],
	roles: [],
	permissions: [],
	repertoireContacts: [],
	orders: [],
	productsOrders: [],
	numbers: [],
	resource: null,
	item: null,
	pages: 0,
}

const apiReducer = (state = initialState, action) => {
	switch (action.type) {
		case ERROR: {
			return {
				...state,
				error: action.message,
			}
		}
		case GET_TOKEN:
			return {
				...state,
				payload: action.payload,
				rawToken: action.rawToken,
			}
		case GET_USER:
			return {
				...state,
				user: { ...action.user, messages: [] },
			}
		case LOGOUT:
			return {
				...state,
				rawToken: null,
			}
		case GET_USERS:
			return {
				...state,
				users: action.users,
			}
		case GET_RESOURCES:
			return {
				...state,
				[action.name]: action.resources,
				pages: action.pages,
			}
		case CREATE_RESOURCE:
			return {
				...state,
				[action.name]: [...state[action.name], action.data],
			}
		case GET_RESOURCE: {
			return {
				...state,
				item: action.data,
			}
		}
		case RESET_RESOURCE: {
			return {
				...state,
				item: initialState.item,
			}
		}
		case UPDATE_RESOURCE: {
			return {
				...state,
				[action.name]: state[action.name].map((item) =>
					item.id === action.id ? action.data : item
				),
			}
		}
		case DELETE_RESOURCE: {
			return {
				...state,
				[action.name]: state[action.name].filter(
					(item) => item.id !== action.id
				),
			}
		}
		case GET_NEW_NUM:
			return {
				...state,
				newNum: action.number,
			}
		case GET_NUMBERS:
			return {
				...state,
				numbers: {
					...state.numbers,
					[action.name]: action.resources.map((item) =>
						item[action.property].toString()
					),
				},
			}
		case GET_RESOURCE_BY_NUMBER:
			return {
				...state,
				resource: {
					...state.resource,
					[action.name]: action.data,
				},
			}
		case GET_MESSAGES_OF_USER:
			return {
				...state,
				user: {
					...state.user,
					messages: action.data,
				},
			}
		default:
			return state
	}
}

export default apiReducer
