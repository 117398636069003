import { combineReducers } from "redux"

import designReducer from "./designReducer"
import apiReducer from "./apiReducer"
import quotationFormReducer from "./quotationFormReducer"
import quotationWriteReducer from "./quotationWriteReducer"
import calendarReducer from "./calendarReducer"
import tableReducer from "./tableReducer"
import customerFormReducer from "./reducers/forms/customerFormReducer"
import permissionFormReducer from "./reducers/forms/permissionFormReducer"
import repertoireFormReducer from "./reducers/forms/repertoireFormReducer"
import orderFormReducer from "./reducers/forms/orderFormReducer"

const reducer = combineReducers({
	designReducer,
	apiReducer,
	quotationFormReducer,
	quotationWriteReducer,
	calendarReducer,
	tableReducer,
	customerFormReducer,
	permissionFormReducer,
	repertoireFormReducer,
	orderFormReducer,
})

export type RootState = ReturnType<typeof reducer>
export default reducer
