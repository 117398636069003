import React, { Component } from "react"
import { connect } from "react-redux"

import "../../css/components/table.scss"
import { deleteResource } from "../../redux/action"
import { Link } from "react-router-dom"
import Table from "../../components/Table"
import { getColumnsWidth, getDefaultFiltered } from "../../utils/List"
import { TextFilter } from "../../components/List/index"
import Page from "../../components/Page/Page"
import { onDelete } from "../../utils/Alert"

const frenchName = "types de produits"
const localName = "productTypes"
const apiName = "productTypes"

let localNames = ["name"]
localNames = localNames.map((name) => localName + "-" + name)

class ProductTypes extends Component {
	constructor(props) {
		super(props)
		this.state = {
			columns: [
				{
					Header: "Désignation",
					accessor: "name",
					width: getColumnsWidth(localNames[0], 1650),
				},
				{
					Header: "Actions",
					accessor: "id",
					sortable: false,
					resizable: false,
					width: 100,
					Cell: ({ row }) => {
						return (
							<div className={"btn-flex"}>
								<Link
									to={
										"/types-produits/" +
										row._original.id +
										"/modifier"
									}
								>
									<div
										className="btn-circle btn-warning"
										title={"Modifier"}
									>
										<i className="fa fa-edit" />
									</div>
								</Link>
								<div
									className="btn-circle btn-danger"
									title={"Supprimer"}
									onClick={() =>
										this.handleDelete(row._original.id)
									}
								>
									<i className="fa fa-trash" />
								</div>
							</div>
						)
					},
				},
			],
		}
	}

	componentDidMount() {
		this.state.columns.forEach((column, i) => {
			if (i < this.state.columns.length)
				column.Filter = ({ filter, onChange }) => (
					<TextFilter
						localName={localNames[i]}
						filter={filter}
						onChange={onChange}
					/>
				)
		})
	}

	handleDelete = (id) =>
		onDelete(
			"",
			"Etes-vous sûr de vouloir supprimer ce type de produits ?",
			() =>
				this.props.dispatch(
					deleteResource(apiName, id, this.props.rawToken)
				)
		)

	buttonsRight = () => (
		<Link to={"/types-produits/creer"}>
			<button className={"btn-circle add-resource"} title={"Ajouter"}>
				<i className={"fas fa-plus"} />
			</button>
		</Link>
	)

	render() {
		return (
			<Page
				siteTitle={"Types de produits"}
				title={"Gestion des types de produits"}
				headerButtons={this.buttonsRight()}
			>
				<Table
					name={localName + "-list"}
					data={this.props[apiName]}
					columns={this.state.columns}
					frenchName={frenchName}
					localName={localName}
					apiName={apiName}
					defaultFiltered={getDefaultFiltered(
						localName,
						this.state.columns
					)}
					defaultSorted={[
						{
							id: "name",
							desc: false,
						},
					]}
				/>
			</Page>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		[apiName]: apiReducer[apiName],
	}
}

export default connect(mapStateToProps)(ProductTypes)
