import React from "react"
import { stepPriorities, StepPriority } from "../../types/step"

interface Props {
	priority: number
}

export const StepPriorityText = ({ priority }: Props) => {
	const stepPriority: StepPriority | undefined = stepPriorities.find(
		(stepPriority: StepPriority) => stepPriority.priority === priority
	)

	return stepPriority ? (
		<div className={"priority "}>
			<span className={"badge " + stepPriority.className}>
				{stepPriority.key}
			</span>
		</div>
	) : (
		<></>
	)
}
