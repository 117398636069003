import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import "../../css/pages/permissions.scss"
import Page from "../../components/Page/Page"
import { getResource, getResources } from "../../redux/action"
import { getParams } from "../../utils/Data"

const Role = () => {
	const { id } = getParams()
	const dispatch = useDispatch()
	const { item, permissions, rawToken } = useSelector(
		({ apiReducer }) => apiReducer
	)

	useEffect(() => {
		dispatch(getResource("roles", id, rawToken))
		dispatch(getResources("permissions", rawToken))
	}, [])

	const onSubmit = (e) => {
		e.preventDefault()
	}

	const contain = (array, value) => {
		let bool = false
		for (let i = 0; i < array.length; i++) {
			if (array[i] === value) bool = true
		}
		return bool
	}

	return (
		<Page title={"Role"} className={"role"}>
			{item !== null ? (
				<form onSubmit={onSubmit}>
					<div>Permissions :</div>
					{permissions.map((permission, i) => (
						<div key={i} className={"row"}>
							<label htmlFor={"permission-" + permission.id}>
								{permission.description}
							</label>
							<input
								id={"permission-" + permission.id}
								name={""}
								type={"checkbox"}
								defaultChecked={contain(
									item.permissions.map((p) => p.id),
									permission.id
								)}
							/>
						</div>
					))}
				</form>
			) : (
				"Chargement ..."
			)}
		</Page>
	)
}

export default Role
