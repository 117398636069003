import React, { useEffect, useState } from "react"

import { CheckAppVersionApiRepository } from "../infrastructure/check-app-version.api.repository"
import { CheckAppVersion } from "../domain/check-app-version"
import { UpdateAppVersionContainer } from "../../update-app-version/application/update-app-version.container"

export const CheckAppVersionContainer = () => {
	const [isUpToDate, setIsUpToDate] = useState(true)

	useEffect(() => {
		const checkAppVersionRepository = new CheckAppVersionApiRepository()
		const checkAppVersion = new CheckAppVersion(checkAppVersionRepository)

		checkAppVersion.execute().then((isUpToDate: boolean) => {
			setIsUpToDate(isUpToDate)
		})
	})

	return !isUpToDate && <UpdateAppVersionContainer />
}
