import { isDefined } from "./Data"

export const formatDate = (date) => {
	if (date === null) return ""

	let string = date.split("T")
	let dateSplited = string[0].split("-")
	let year = dateSplited[0]
	let month = dateSplited[1]
	let day = dateSplited[2]

	return day + "/" + month + "/" + year
}

export const formatDateTime = (date) => {
	if (date === null) return ""

	const string = date.split("T")
	const dateSplited = string[0].split("-")
	const year = dateSplited[0]
	const month = dateSplited[1]
	const day = dateSplited[2]
	const timeSplited = string[1].split(":")
	const hours = timeSplited[0]
	const minutes = timeSplited[1]

	return day + "/" + month + "/" + year + " à " + hours + "h" + minutes
}

export const getDateFormated = (date) => {
	let dd = date.getDate()
	let mm = date.getMonth() + 1

	const yyyy = date.getFullYear()
	if (dd < 10) {
		dd = "0" + dd
	}
	if (mm < 10) {
		mm = "0" + mm
	}
	return dd + "/" + mm + "/" + yyyy
}

export const getDateFormatedEN = (date) => {
	let dd = date.getDate()
	let mm = date.getMonth() + 1

	const yyyy = date.getFullYear()
	if (dd < 10) {
		dd = "0" + dd
	}
	if (mm < 10) {
		mm = "0" + mm
	}
	return +yyyy + "-" + mm + "-" + dd
}

export const getDayOfDate = (date) =>
	date.toLocaleDateString("fr", { weekday: "long" })

export const getDays = (date = new Date()) => {
	let week = []

	date.setDate(date.getDate() - date.getDay() + 1)

	for (let i = 0; i < 5; i++) {
		week.push(new Date(date))
		date.setDate(date.getDate() + 1)
	}

	return week
}

export const getLast5Days = (date, week = null) => {
	if (week !== null) {
		week.splice(week.length - 1, 1)
		week.splice(0, 0, checkSamediAndDimanche(date))
		return week
	} else {
		let days = []
		let newDate = new Date(date.setDate(date.getDate() + 1))

		for (let i = 0; i < 5; i++) {
			newDate.setDate(newDate.getDate() - 1)
			newDate = checkSamediAndDimanche(new Date(newDate))
			days.push(new Date(newDate))
		}
		return days.reverse()
	}
}

export const getNext5Days = (date) => {
	let days = []
	let newDate = new Date(date.setDate(date.getDate() - 1))
	for (let i = 0; i < 5; i++) {
		newDate.setDate(newDate.getDate() + 1)
		newDate = checkSamediAndDimanche(new Date(newDate), false)
		days.push(new Date(newDate))
	}

	return days
}

export const getPreviousDay = (date = new Date()) => {
	let newDate = new Date(date.setDate(date.getDate() - 1))
	return checkSamediAndDimanche(newDate)
}

export const getLastWeekDay = (date = new Date()) => {
	let newDate = new Date(date.setDate(date.getDate() - 8))
	return checkSamediAndDimanche(newDate, false)
}

export const getNextDay = (date = new Date()) => {
	let newDate = new Date(date.setDate(date.getDate() + 1))
	return checkSamediAndDimanche(newDate, false)
}

export const getNextWeekDay = (date = new Date()) => {
	let newDate = new Date(date.setDate(date.getDate() + 6))
	return checkSamediAndDimanche(newDate, false)
}

export const checkSamediAndDimanche = (date, previous = true) => {
	let newDate = date

	if (getDayOfDate(newDate) === "dimanche") {
		previous
			? date.setDate(date.getDate() - 2)
			: date.setDate(date.getDate() + 1)
		newDate = new Date(date)
	} else if (getDayOfDate(newDate) === "samedi") {
		previous
			? date.setDate(date.getDate() - 1)
			: date.setDate(date.getDate() + 2)
		newDate = new Date(date)
	}

	return newDate
}

export const months = [
	"Janvier",
	"Février",
	"Mars",
	"Avril",
	"Mai",
	"Juin",
	"Juillet",
	"Août",
	"Septembre",
	"Octobre",
	"Novembre",
	"Décembre",
]

export const getMonth = (datetime) => {
	const date = datetime.split("T")
	const splitDate = date[0].split("-")
	const month = splitDate[1]
	return months[month - 1]
}

export const getYear = (datetime) => {
	const date = datetime.split("T")
	const splitDate = date[0].split("-")
	return parseInt(splitDate[0])
}

export const getMonthByYear = (datetime, month, year) =>
	getMonth(datetime) === month && getYear(datetime) === year

export const getYearsBetweenDates = (date1, date2) => {
	const year1 = date1.getFullYear()
	const year2 = date2.getFullYear()

	const diff = year1 - year2
	let years = []
	for (let i = 0; i <= diff; i++) {
		years.push(year2 + i)
	}
	return years
}

export const getDateOfDateJS = (date) => {
	const day = addZero(date.getUTCDate())
	const month = addZero(date.getUTCMonth() + 1)
	const year = date.getUTCFullYear()

	return year + "-" + month + "-" + day
}

export const addZero = (number) => (number < 10 ? "0" + number : number)

export const datimeToDate = (datetime) =>
	isDefined(datetime) ? datetime.split("T")[0] : getDateOfDateJS(new Date())

export const sortDate = (dates, property) => {
	return dates.sort((a, b) => {
		return (
			(a[property] === null) - (b[property] === null) ||
			+(new Date(a[property]) > new Date(b[property])) ||
			-(new Date(a[property]) < new Date(b[property]))
		)
	})
}
