import React, { Component } from "react"
import ReactTable from "react-table"
import "react-table/react-table.css"
import { connect } from "react-redux"
import ReactLoading from "react-loading"

import "../css/components/table.scss"
import { getResourcesWithPages } from "../redux/action"
import { isDefined } from "../utils/Data"
import { getScrollPosition } from "../utils/List"
import { setContrast } from "../utils/Color"

class Table extends Component {
	constructor(props) {
		super(props)

		this.tableRef = React.createRef()
		this.state = {
			loading: false,
			selected: -1,
			filtered: isDefined(this.props.defaultFiltered)
				? this.props.defaultFiltered
				: [],
			sorted: isDefined(this.props.defaultSorted)
				? this.props.defaultSorted
				: [],
			page: this.getPage(),
			pageSize: this.getPageSize(),
		}
	}

	componentDidMount() {
		if (!this.props.dontLoadDataOnMount) this.refreshData()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.data !== this.props.data && this.props.data.length > 0) {
			const el = document.querySelector(".rt-tbody")
			if (el) {
				el.addEventListener("scroll", this.changeScroll)

				const localScroll = localStorage.getItem(this.props.name)
				if (localScroll) el.scrollTo(0, parseFloat(localScroll))
			}
		}
	}

	componentWillUnmount() {
		const el = document.querySelector(".rt-tbody")
		if (el) el.removeEventListener("scroll", this.changeScroll)
	}

	selectRow = (id) => {
		this.setState({
			selected: id,
		})
	}

	changeScroll = () => {
		localStorage.setItem(this.props.name, getScrollPosition(".rt-tbody"))
	}

	getSortedDirection = () => {
		let sorted = this.state.sorted
		let direction = this.state.sorted

		if (isDefined(this.state.sorted))
			if (this.state.sorted[0]) {
				sorted = this.state.sorted[0].id
				direction = this.state.sorted[0].desc === true ? "desc" : "asc"
			}

		return {
			sorted: sorted,
			direction: direction,
		}
	}

	getSearch = () => {
		let search = ""

		if (isDefined(this.state.filtered))
			if (this.state.filtered.length > 0) {
				const filters = this.state.filtered.filter(
					(filter) => filter.value !== ""
				)
				filters.forEach((filter, i) => {
					if (i > 0) search += ","

					search += filter.id + "," + filter.value
				})
			}

		return search
	}

	refreshData = () => {
		this.setState({ loading: true })

		const { dispatch, apiName, rawToken } = this.props
		const { page, pageSize } = this.state
		const { sorted, direction } = this.getSortedDirection()
		const search = this.getSearch()

		dispatch(
			getResourcesWithPages(
				apiName,
				rawToken,
				page + 1,
				pageSize,
				sorted,
				direction,
				search
			)
		).then(() => {
			this.setState({ loading: false })
		})
	}

	onChange = (name, value) => {
		this.setState({ [name]: value }, () => this.refreshData())
	}

	getTrProps = (state, rowInfo) => {
		const props = this.props

		if (typeof rowInfo !== "undefined") {
			let colorOfRowByAPI = null
			if (
				typeof rowInfo.row._original.color !== "undefined" &&
				rowInfo.row._original.color !== null
			)
				colorOfRowByAPI = rowInfo.row._original.color.hexa

			return {
				onClick: (e, handleOriginal) => {
					this.selectRow(rowInfo.index)
					localStorage.setItem(
						props.localName + "-rowSelected",
						rowInfo.index
					)
					if (handleOriginal) {
						handleOriginal()
					}
				},
				style: {
					border:
						rowInfo.index === this.state.selected ||
						rowInfo.index ===
							parseInt(
								localStorage.getItem(
									props.localName + "-rowSelected"
								)
							)
							? "4px solid orange"
							: "4px solid transparent",
					background:
						colorOfRowByAPI !== null ? colorOfRowByAPI : "#FFFFFF",
					color: setContrast(
						colorOfRowByAPI !== null ? colorOfRowByAPI : "#FFFFFF"
					),
				},
			}
		} else {
			return {
				onClick: (e, handleOriginal) => {
					if (handleOriginal) {
						handleOriginal()
					}
				},
				style: {
					background: "#FFFFFF",
					color: "#000000",
				},
			}
		}
	}

	getColumns = () => {
		let colunms = this.props.columns
		colunms[colunms.length - 1].Filter = () => (
			<button
				className={"btn btn-primary"}
				title={"Réinitialiser les filtres"}
				onClick={() => this.resetFilters()}
			>
				Réinitialiser
			</button>
		)

		return colunms
	}

	getPage = () => {
		const local = localStorage.getItem(this.props.localName + "-page")
		return isDefined(local) ? parseInt(local) : 0
	}

	getPageSize = () => {
		const local = localStorage.getItem(this.props.localName + "-pageSize")
		return isDefined(local)
			? parseInt(local)
			: isDefined(this.props.defaultPageSize)
			? this.props.defaultPageSize
			: 200
	}

	onPageChange = (index) => {
		this.onChange("page", index)
		localStorage.setItem(this.props.localName + "-page", index)
	}

	onPageSizeChange = (size) => {
		this.onChange("pageSize", size)
		localStorage.setItem(this.props.localName + "-pageSize", size)
		this.onPageChange(1)
	}

	resetFilters = () => {
		this.setState({ filtered: [] }, () => this.refreshData())

		this.props.columns.forEach((column) => {
			const localItem = localStorage.getItem(
				this.props.localName + "-" + column.accessor + "-filter"
			)

			if (localItem !== undefined)
				localStorage.removeItem(
					this.props.localName + "-" + column.accessor + "-filter"
				)
		})
	}

	render() {
		const props = this.props
		return (
			<ReactTable
				ref={this.tableRef}
				className={isDefined(props.className) ? props.className : ""}
				data={props.data}
				page={this.state.page}
				pages={props.pages}
				pageSize={this.state.pageSize}
				columns={this.getColumns()}
				filtered={this.state.filtered}
				sorted={this.state.sorted}
				filterable={
					isDefined(props.filterable) ? props.filterable : true
				}
				sortable={isDefined(props.sortable) ? props.sortable : true}
				multiSort={isDefined(props.multiSort) ? props.multiSort : true}
				keyField={isDefined(props.keyField) ? props.keyField : "id"}
				pageSizeOptions={[5, 25, 50, 75, 100, 150, 200]}
				defaultPageSize={
					isDefined(props.defaultPageSize)
						? props.defaultPageSize
						: 200
				}
				previousText={"Précédent"}
				nextText={"Suivant"}
				loadingText={
					<ReactLoading
						type={"spin"}
						color={"#f8ac59"}
						className={"sr-loading"}
					/>
				}
				noDataText={"Aucun " + props.frenchName + " disponibles"}
				ofText={"sur"}
				rowsText={props.frenchName}
				style={isDefined(props.style) ? props.style : {}}
				getTrProps={this.getTrProps}
				showPagination={
					isDefined(props.showPagination)
						? props.showPagination
						: true
				}
				showPageJump={
					isDefined(props.showPageJump) ? props.showPageJump : true
				}
				defaultSorted={
					isDefined(props.defaultSorted)
						? props.defaultSorted
						: [{ id: "id", desc: true }]
				}
				defaultFiltered={
					isDefined(props.defaultFiltered)
						? props.defaultFiltered
						: []
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				}
				loading={this.state.loading}
				manual
				//onFetchData={this.onFetchData}
				onResizedChange={(newResized) =>
					newResized.map((newResize) =>
						localStorage.setItem(
							props.localName + "-" + newResize.id,
							newResize.value
						)
					)
				}
				onPageSizeChange={(size) => this.onPageSizeChange(size)}
				onPageChange={(pageIndex) => this.onPageChange(pageIndex)}
				onFilteredChange={(filtered) =>
					this.onChange("filtered", filtered)
				}
				onSortedChange={(sorted) => this.onChange("sorted", sorted)}
			/>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		pages: apiReducer.pages,
	}
}

export default connect(mapStateToProps)(Table)
