import React, { Component } from "react"
import { connect } from "react-redux"
import ReactLoading from "react-loading"
import { Link, withRouter } from "react-router-dom"
import { message } from "antd"
import { getResource, specialPut } from "../../redux/action"
import GeneralInfos from "./card/GeneralInfos"
import Contacts from "./card/Contacts"
import "../../css/pages/projects/project.scss"
import Steps from "./card/Steps"
import Notes from "./card/Notes"
import Accounting from "./card/Accounting"
import Page from "../../components/Page/Page"
import { getParams } from "../../utils/Data"
import { onConfirmation } from "../../utils/Alert"

class Project extends Component {
	constructor(props) {
		super(props)

		const pathname = window.location.pathname
		const split = pathname.split("/")

		this.state = {
			id: split[2],
			project: null,
			customer: null,
		}
	}

	componentDidMount() {
		this.getProject()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const params = getParams()
		if (this.state.id !== params.id) {
			this.setState(
				{
					id: params.id,
				},
				() => this.getProject()
			)
		}
	}

	getProject = () => {
		this.props
			.dispatch(
				getResource("projects", this.state.id, this.props.rawToken)
			)
			.then((project) => {
				if (project) {
					this.setState(
						{
							project: project,
						},
						() => {
							this.loadProjectCustomer(project)
						}
					)
				} else message.error("Le projet n'a pas pu être chargé", 5)
			})
	}

	loadProjectCustomer = (project) => {
		this.props
			.dispatch(
				getResource(
					"customers",
					project.customer.id,
					this.props.rawToken
				)
			)
			.then((customer) => {
				if (customer)
					this.setState({
						customer: customer,
					})
				else
					message.error(
						"Le client associé au projet n'a pas pu être chargé",
						5
					)
			})
	}

	handleBack = () => {
		this.props.history.goBack()
	}

	handleDeliver = () => {
		onConfirmation(
			"Projet livré",
			"Etes-vous sur de vouloir déplacer ce projet dans les projets livrés ?",
			() =>
				this.props
					.dispatch(
						specialPut(
							this.state.id,
							this.props.rawToken,
							"projects",
							"deliver"
						)
					)
					.then(() => this.handleBack()),
			"Le projet a bien été déplacé dans les projets livrés",
			"L'action a bien été annulée"
		)
	}

	handleWaiting = () => {
		onConfirmation(
			"Projet commande",
			"Etes-vous sur de vouloir déplacer ce projet dans les projets commande ?",
			() =>
				this.props
					.dispatch(
						specialPut(
							this.state.id,
							this.props.rawToken,
							"projects",
							"waiting"
						)
					)
					.then(() => this.handleBack()),
			"Le projet a bien été déplacé dans les projets commande",
			"L'action a bien été annulée"
		)
	}

	handleRevive = () => {
		onConfirmation(
			"Devis à relancer",
			"Etes-vous sur de vouloir déplacer ce projet dans les devis à relancer ?",
			() =>
				this.props
					.dispatch(
						specialPut(
							this.state.id,
							this.props.rawToken,
							"projects",
							"revive"
						)
					)
					.then(() => this.handleBack()),
			"Le projet a bien été déplacé dans les devis à relancer",
			"L'action a bien été annulée"
		)
	}

	handleToBill = () => {
		onConfirmation(
			"Projet à facturer",
			"Etes-vous sur de vouloir déplacer ce projet dans les projets à facturer ?",
			() =>
				this.props
					.dispatch(
						specialPut(
							this.state.id,
							this.props.rawToken,
							"projects",
							"toBill"
						)
					)
					.then(() => this.handleBack()),
			"Le projet a bien été déplacé dans les projets à facturer",
			"L'action a bien été annulée"
		)
	}

	buttonsRight = () =>
		this.state.project !== null ? (
			<div className={"btn-header-right"}>
				<button
					className={"btn-circle btn-primary"}
					title={"Retour"}
					onClick={this.handleBack}
				>
					<i className={"fas fa-arrow-left"} />
				</button>

				<button
					className={"btn-circle btn-secondary"}
					title={"Commande"}
					onClick={this.handleWaiting}
				>
					<i className={"fas fa-box-open"} />
				</button>

				<button
					className={"btn-circle btn-danger"}
					title={"Relancer un devis"}
					onClick={this.handleRevive}
				>
					<i className={"fas fa-redo"} />
				</button>

				<button
					className={"btn-circle btn-success"}
					title={"A facturer"}
					onClick={this.handleToBill}
				>
					<i className={"fas fa-euro-sign"} />
				</button>

				<Link to={"/projets/" + this.state.id + "/modifier"}>
					<button
						className={"btn-circle btn-warning"}
						title={"Modifier"}
					>
						<i className={"fas fa-pencil-alt"} />
					</button>
				</Link>

				<Link to={"/projet/" + this.state.id + "/message"}>
					<button
						className={"btn-circle btn-info"}
						title={"Envoyer un message"}
						onClick={this.handleShowForm}
					>
						<i className={"fas fa-envelope"} />
					</button>
				</Link>

				<button
					className={"btn-circle btn-primary"}
					title={"Livrer"}
					onClick={this.handleDeliver}
				>
					<i className={"fas fa-truck-loading"} />
				</button>
			</div>
		) : (
			"Chargement ..."
		)

	addSubResource = (name, subResource) => {
		this.setState((prev) => {
			return {
				...prev,
				project: {
					...prev.project,
					[name]: [...prev.project[name].concat(subResource)],
				},
			}
		})
	}

	updateSubResource = (name, subResource) => {
		this.setState((prev) => {
			return {
				...prev,
				project: {
					...prev.project,
					[name]: [
						...prev.project[name].map((item) => {
							if (item.id === subResource.id) return subResource
							else return item
						}),
					],
				},
			}
		})
	}

	removeSubResource = (name, id) => {
		this.setState((prev) => {
			return {
				...prev,
				project: {
					...prev.project,
					[name]: [
						...prev.project[name].filter((item) => item.id !== id),
					],
				},
			}
		})
	}

	render() {
		return (
			<Page
				title={"Fiche d'un projet"}
				headerButtons={this.buttonsRight()}
				className={"project"}
			>
				{this.state.project ? (
					<>
						<GeneralInfos project={this.state.project} />
						<Accounting project={this.state.project} />
						<Contacts contacts={this.state.project.contacts} />
						<Steps
							project={this.state.project}
							addStep={this.addSubResource}
							updateStep={this.updateSubResource}
							removeStep={this.removeSubResource}
						/>
						<Notes
							project={this.state.project}
							addNote={this.addSubResource}
							updateNote={this.updateSubResource}
							removeNote={this.removeSubResource}
						/>
						<div className={"block"}>
							<div className={"messages"}></div>
						</div>
					</>
				) : (
					<ReactLoading
						type={"spin"}
						color={"#f8ac59"}
						height={70}
						width={70}
						className={"sr-loading"}
					/>
				)}
			</Page>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
	}
}

export default withRouter(connect(mapStateToProps)(Project))
