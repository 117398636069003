import { ActionTypes } from "../../action.types"
import { createResource, resetResource, updateResource } from "../../action"

const { ERROR } = ActionTypes

export const CHANGE_PROPERTY = "CHANGE_PROPERTY",
	RESET = "RESET"

export const changeProperty = (name, value) => (dispatch) => {
	dispatch({
		type: CHANGE_PROPERTY,
		name: name,
		value: value,
	})
}

export const resourceToForm = (resource) => (dispatch) =>
	Object.keys(resource).forEach((property) =>
		dispatch(changeProperty(property, resource[property]))
	)

export const submit =
	(id, data, rawToken, callback = null) =>
	(dispatch) => {
		try {
			if (id === "creer")
				dispatch(submitCreate(id, data, rawToken, callback))
			else dispatch(submitUpdate(id, data, rawToken, callback))
		} catch (e) {
			dispatch({
				type: ERROR,
				e,
			})
		}
	}

export const submitCreate =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		try {
			await dispatch(createResource("permissions", data, rawToken)).then(
				() => {
					if (callback !== null) callback()
				}
			)
		} catch (e) {}
	}

export const submitUpdate =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		try {
			await dispatch(
				updateResource("permissions", id, data, rawToken)
			).then(() => {
				if (callback !== null) callback()
			})
		} catch (e) {}
	}

export const reset = () => (dispatch) => {
	dispatch({ type: RESET })
	dispatch(resetResource())
}
