import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import MyModal from "../../../components/MyModal"
import ProductsList from "../../products/ProductsList"
import { getResource } from "../../../redux/action"
import {
	addProduct,
	addProductToRow,
} from "../../../redux/actions/forms/orderFormAction"
import { isDefined } from "../../../utils/Data"
import { onErrorAlert, onSuccessAlert } from "../../../utils/Alert"

const OrdersArticlesModal = ({ row }) => {
	const [isOpen, setIsOpen] = useState(false)

	const dispatch = useDispatch()
	const { rawToken } = useSelector(({ apiReducer }) => apiReducer)
	const products = useSelector(
		({ orderFormReducer }) => orderFormReducer.data.productsOrders
	)

	const openAddProduct = (e) => {
		e.preventDefault()

		setIsOpen(true)
	}

	const handleAdd = (id) => {
		dispatch(getResource("products", id, rawToken)).then((product) => {
			if (isDefined(product)) {
				const res = dispatch(addProductToRow(product, products, row))
				if (res !== false)
					onSuccessAlert("Le produit a été ajouté à la commande")
				else onErrorAlert("Le produit n'a pas pû être ajouté", "Erreur")
			} else {
				onErrorAlert("Le produit n'a pas pû être récupéré", "Erreur")
			}
			setIsOpen(false)
		})
	}

	return (
		<>
			<MyModal
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				title={"Catalogue des produits"}
				content={
					<ProductsList
						addButton={true}
						handleAdd={(id) => handleAdd(id)}
						dontLoadDataOnMount={true}
					/>
				}
				className={"products-modal"}
			/>
			<div
				className={"btn-circle btn-products btn-warning"}
				title={"Ouvrir le catalogue"}
				onClick={openAddProduct}
			>
				<i className="fas fa-book" />
			</div>
		</>
	)
}

export default OrdersArticlesModal
