import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	getNewNum,
	getNewNumModel,
	getResource,
	getResourcesModels,
} from "../../redux/action"
import { resetQuotationForm } from "../../redux/QuotationFormAction"
import { AccountingElementType } from "../../types/accounting"

interface Props {
	type: AccountingElementType
	resourceToInput: (fieldName: string) => void
	changeHandler: (
		event?: any,
		newName?: string,
		newValue?: string,
		callback?: any
	) => void
	changeState: (value: any, callback: any) => void
	selectModel: (selected?: boolean) => void
}

export const AccountingFormModel = ({
	type,
	resourceToInput,
	changeHandler,
	changeState,
	selectModel,
}: Props) => {
	const dispatch: any = useDispatch()

	const { rawToken, newNum, quotations, bills } = useSelector(
		({ apiReducer }: any) => apiReducer
	)
	const data = useSelector(
		({ quotationFormReducer }: any) => quotationFormReducer.form
	)

	let models = type === "quotations" ? quotations : bills
	models = models.filter((item) => item.model === true)

	useEffect(() => {
		dispatch(getResourcesModels(type, rawToken))
	}, [])

	const onSelectModel = (e) => {
		e.preventDefault()

		const value = e.target.value

		if (value === "default") {
			dispatch(resetQuotationForm()).then(
				dispatch(getNewNum(type, rawToken)).then(() =>
					changeHandler(null, "number", newNum)
				)
			)
			selectModel(false)
		} else {
			dispatch(getResource(type, value, rawToken)).then((quotation) => {
				changeState(quotation, () =>
					Object.keys(data).map((input) => {
						resourceToInput(input)
						dispatch(getNewNumModel(type, rawToken)).then(() =>
							changeHandler(null, "number", newNum)
						)
					})
				)
			})
			selectModel()
		}
	}

	const modelDisplayed = (model: any) =>
		"N° " +
		model.number +
		" - " +
		model.name +
		" (" +
		parseFloat(model.totalPrice).toFixed(2) +
		" €)"

	return (
		<div className={"form-group"}>
			<select
				name={"models"}
				className={"models"}
				onChange={onSelectModel}
			>
				<option value={"default"}>
					Choisir un modèle
					{type === "quotations" ? " de devis" : " de facture"}
				</option>
				{models.map((model, i) => (
					<option key={i} value={model.id}>
						{modelDisplayed(model)}
					</option>
				))}
			</select>
		</div>
	)
}
