import React from "react"
import PropTypes from "prop-types"

import { isDefined } from "../../utils/Data"
import { setContrast } from "../../utils/Color"

const CalendarHours = ({ key, user }) => {
	const bg = isDefined(user.color) ? user.color.hexa : "#FFFFFF"

	const style = {
		backgroundColor: bg,
		color: setContrast(bg),
		margin: ".2em 0",
	}

	return (
		<div key={key}>
			<span className="badge" style={style}>
				{user.firstname + " : " + user.hours + "h"}
			</span>
		</div>
	)
}

CalendarHours.propTypes = {
	key: PropTypes.number.isRequired,
	user: PropTypes.object.isRequired,
}

export default CalendarHours
