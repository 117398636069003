import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import "../../css/components/messages-alert.scss"

const MessagesAlert = () => {
	const { user } = useSelector(({ apiReducer }) => apiReducer)
	const haveMessagesNotView = () =>
		user !== null ? user.messages.length > 0 : false

	const [choice, setChoice] = useState(true)
	const [show, setShow] = useState(choice && haveMessagesNotView)

	useEffect(() => {
		setShow(choice && haveMessagesNotView)
	}, [user, choice])

	return show ? (
		<div className={"sr-messages-alert"}>
			<h2 className={"sr-messages-alert-title"}>Nouveaux messages</h2>
			<div
				className={"close"}
				onClick={() => setChoice(false)}
				title={"Fermer"}
			>
				<i className={"fa fa-times-circle"} />
			</div>
			<Link to={"/messages"}>Aller à la messagerie</Link>
		</div>
	) : (
		<></>
	)
}

export default MessagesAlert
