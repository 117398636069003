import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import { createResource, getResource, updateResource } from "../../redux/action"
import "../../css/pages/products/form.scss"
import Page from "../../components/Page/Page"

class ProviderForm extends Component {
	constructor(props) {
		super(props)

		const pathname = window.location.pathname
		const split = pathname.split("/")

		this.state = {
			id: split[2],
			provider: null,
			form: {
				name: {
					value: "",
				},
				description: {
					value: "",
				},
				email: {
					value: "",
				},
				phone: {
					value: "",
				},
				fax: {
					value: "",
				},
				address: {
					number: {
						value: "",
					},
					street: {
						value: "",
					},
					postalCode: {
						value: "",
					},
					city: {
						value: "",
					},
					complement: {
						value: "",
					},
				},
				note: {
					value: "",
				},
			},
		}
	}

	componentDidMount() {
		if (this.state.id !== "creer") {
			this.props
				.dispatch(
					getResource("providers", this.state.id, this.props.rawToken)
				)
				.then((provider) => {
					this.setState({
						provider: provider,
					})
					Object.keys(this.state.form).map((input) =>
						this.providerToInput(input)
					)
				})
		}
	}

	providerToInput = (input) => {
		if (input === "address") {
			if (this.state.provider.address !== null)
				Object.keys(this.state.form.address).map((input) => {
					this.setState({
						form: {
							...this.state.form,
							address: {
								...this.state.form.address,
								[input]: {
									...this.state.form.address[input],
									value:
										this.state.provider.address[input] !==
										null
											? this.state.provider.address[input]
											: "",
								},
							},
						},
					})
				})
		}

		this.setState({
			form: {
				...this.state.form,
				[input]: {
					...this.state.form[input],
					value:
						this.state.provider[input] !== null
							? this.state.provider[input]
							: "",
				},
			},
		})
	}

	changeHandler = (e) => {
		const name = e.target.name
		const value = e.target.value

		let split = name.split(".")
		if (split.length > 1) {
			this.setState({
				form: {
					...this.state.form,
					[split[0]]: {
						...this.state.form[split[0]],
						[split[1]]: {
							...this.state.form[split[0]][split[1]],
							value,
						},
					},
				},
			})
		} else {
			this.setState({
				form: {
					...this.state.form,
					[name]: {
						...this.state.form[name],
						value,
					},
				},
			})
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		let body = {}
		Object.keys(this.state.form).map((input) => {
			if (input === "address") {
				body.address = {}
				Object.keys(this.state.form.address).map((subInput) => {
					body.address[subInput] =
						this.state.form.address[subInput].value
				})
			} else {
				body[input] = this.state.form[input].value
			}
		})

		if (this.state.id === "creer") {
			this.props
				.dispatch(
					createResource(
						"adresses",
						body.address,
						this.props.rawToken
					)
				)
				.then((res) => {
					delete body.address
					body.address = res.id
					this.props
						.dispatch(
							createResource(
								"providers",
								body,
								this.props.rawToken
							)
						)
						.then(() => this.props.history.goBack())
				})
		} else {
			if (this.state.provider.address !== null) {
				try {
					await this.props
						.dispatch(
							updateResource(
								"adresses",
								this.state.provider.address.id,
								body.address,
								this.props.rawToken
							)
						)
						.then(async (res) => {
							delete body.address
							body.address = res.id

							try {
								await this.props
									.dispatch(
										updateResource(
											"providers",
											this.state.id,
											body,
											this.props.rawToken
										)
									)
									.then(() => this.props.history.goBack())
							} catch (e) {}
						})
				} catch (e) {}
			} else {
				this.props
					.dispatch(
						createResource(
							"adresses",
							body.address,
							this.props.rawToken
						)
					)
					.then(async (res) => {
						delete body.address
						body.address = res.id

						try {
							await this.props
								.dispatch(
									updateResource(
										"providers",
										this.state.id,
										body,
										this.props.rawToken
									)
								)
								.then(() => this.props.history.goBack())
						} catch (e) {}
					})
			}
		}
	}

	render() {
		return (
			<Page
				title={
					this.state.id !== null && this.state.id !== "creer"
						? "Modification d'un fournisseur"
						: "Création d'un fournisseur"
				}
			>
				<form onSubmit={this.handleSubmit} className={"form-block"}>
					<div className={"row"}>
						<div className={"col-12 col-lg-6"}>
							<label>Nom du fournisseur</label>
							<input
								type={"text"}
								name={"name"}
								placeholder={"Nom"}
								value={this.state.form.name.value}
								onChange={this.changeHandler}
							/>
						</div>
						<div className={"col-12 col-lg-6"}>
							<label>Description</label>
							<input
								type={"text"}
								name={"description"}
								placeholder={"Description"}
								value={this.state.form.description.value}
								onChange={this.changeHandler}
							/>
						</div>
					</div>

					<div className={"row"}>
						<div className={"col-12 col-lg-1"}>
							<label>N°</label>
							<input
								type={"text"}
								name={"address.number"}
								placeholder={"N°"}
								value={this.state.form.address.number.value}
								onChange={this.changeHandler}
							/>
						</div>
						<div className={"col-12 col-lg-5"}>
							<label>Adresse</label>
							<input
								type={"text"}
								name={"address.street"}
								placeholder={"Adresse"}
								value={this.state.form.address.street.value}
								onChange={this.changeHandler}
							/>
						</div>
						<div className={"col-12 col-lg-1"}>
							<label>Code postal</label>
							<input
								type={"text"}
								name={"address.postalCode"}
								placeholder={"Code postal"}
								value={this.state.form.address.postalCode.value}
								onChange={this.changeHandler}
							/>
						</div>
						<div className={"col-12 col-lg-5"}>
							<label>Ville</label>
							<input
								type={"text"}
								name={"address.city"}
								placeholder={"Ville"}
								value={this.state.form.address.city.value}
								onChange={this.changeHandler}
							/>
						</div>
					</div>

					<div className={"row"}>
						<div className={"col-12 col-lg-6"}>
							<label>Adresse mail</label>
							<input
								type={"email"}
								name={"email"}
								placeholder={"E-mail"}
								value={this.state.form.email.value}
								onChange={this.changeHandler}
							/>
						</div>
						<div className={"col-12 col-lg-3"}>
							<label>Téléphone</label>
							<input
								type={"text"}
								name={"phone"}
								placeholder={"Téléphone"}
								value={this.state.form.phone.value}
								onChange={this.changeHandler}
							/>
						</div>
						<div className={"col-12 col-lg-3"}>
							<label>Téléphone</label>
							<input
								type={"text"}
								name={"fax"}
								placeholder={"Fax"}
								value={this.state.form.fax.value}
								onChange={this.changeHandler}
							/>
						</div>
					</div>

					<div className={"row"}>
						<div className={"col-12"}>
							<label>Note</label>
							<textarea
								placeholder={"Note"}
								className={"form-control"}
								name={"note"}
								value={this.state.form.note.value}
								onChange={this.changeHandler}
								rows={3}
							/>
						</div>
					</div>

					{this.props.id !== undefined ||
					(this.state.id !== null && this.state.id !== "creer") ? (
						<button title={"Modifier"}>Modifier</button>
					) : (
						<button title={"Créer"}>Créer</button>
					)}
				</form>
			</Page>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
	}
}

export default withRouter(connect(mapStateToProps)(ProviderForm))
