import { GetAppVersionOutput } from "../domain/get-app-version.output"
import { httpClient } from "../../../../utils/http-client"

export class GetAppVersionApiRepository implements GetAppVersionOutput {
	getNewAppVersion(): Promise<string> {
		return httpClient
			.get("/apiSettings/getByName/webapp-version")
			.then(({ data }) => {
				return Promise.resolve(data.value)
			})
			.catch((error) => {
				return Promise.resolve(error.message)
			})
	}
}
