import React, { Component } from "react"
import { connect } from "react-redux"

import {
	changeProduct,
	changeTotalGroupProducts,
} from "../../../redux/QuotationFormAction"
import ProductRow from "./ProductRow"
import { renderIfDefinedParams } from "../../../utils/Data"

class MovementForm extends Component {
	onDelete = () => this.props.onDelete()

	getData = () =>
		this.props.form.products.value.find(
			(product) => product.id === this.props.id
		)

	changeHandler = (e, newName, newValue) => {
		newValue = newName === "type" ? newValue.id : newValue
		this.props
			.dispatch(changeProduct(e, this.props.id, newName, newValue))
			.then(() =>
				this.getTotals().then(
					this.props.dispatch(
						changeTotalGroupProducts(this.props.idGroup)
					)
				)
			)
	}

	getTotals = async () => {
		const data = this.getData()
		const quantity = parseFloat(data.quantity) || 0
		const unitPrice = parseFloat(data.unitPrice) || 0
		const dailyCost = parseFloat(quantity * unitPrice).toFixed(2) || 0
		const daysNumber = parseFloat(data.daysNumber) || 0
		const sellingPrice = parseFloat(dailyCost * daysNumber).toFixed(2) || 0

		return Promise.all([
			this.props.dispatch(
				changeProduct(null, this.props.id, "dailyCost", dailyCost)
			),
			this.props
				.dispatch(
					changeProduct(
						null,
						this.props.id,
						"sellingPrice",
						sellingPrice
					)
				)
				.then(() => this.props.changeTotalPrice()),
		])
	}

	inputs = () => {
		const data = this.getData()
		return (
			<>
				<td className={"cell-long"}>
					<input
						type={"text"}
						name={"designation"}
						placeholder={"Déplacement"}
						value={renderIfDefinedParams(data, "designation")}
						onChange={this.changeHandler}
					/>
				</td>
				<td>
					<input
						type={"text"}
						name={"quantity"}
						placeholder={"Quantité"}
						value={renderIfDefinedParams(data, "quantity")}
						onChange={this.changeHandler}
					/>
				</td>
				<td>
					<input
						type={"text"}
						name={"unitPrice"}
						placeholder={"Tarif unique"}
						value={renderIfDefinedParams(data, "unitPrice")}
						onChange={this.changeHandler}
					/>
				</td>
				<td>
					<input
						type={"text"}
						name={"dailyCost"}
						placeholder={"Coût / jour"}
						value={renderIfDefinedParams(data, "dailyCost")}
						onChange={this.changeHandler}
					/>
				</td>
				<td>
					<input
						type={"text"}
						name={"daysNumber"}
						placeholder={"Nb jours"}
						value={renderIfDefinedParams(data, "daysNumber")}
						onChange={this.changeHandler}
					/>
				</td>
			</>
		)
	}

	render() {
		const props = this.props
		return (
			<ProductRow
				id={props.id}
				idGroup={props.idGroup}
				data={this.getData()}
				product={props.product}
				inputs={this.inputs()}
				changeHandler={this.changeHandler}
				onDelete={() => this.onDelete()}
				getTotals={this.getTotals}
			/>
		)
	}
}

const mapStateToProps = ({ apiReducer, quotationFormReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		products: apiReducer.products,
		form: quotationFormReducer.form,
	}
}

export default connect(mapStateToProps)(MovementForm)
