import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import { addContactToCustomer, createResource } from "../../redux/action"
import "../../css/pages/projects/contactForm.scss"

class ContactForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			form: {
				lastname: {
					value: "",
				},
				firstname: {
					value: "",
				},
				email: {
					value: "",
				},
				phone: {
					value: "",
				},
				homePhone: {
					value: "",
				},
				fax: {
					value: "",
				},
			},
		}
	}

	changeHandler = (e) => {
		const name = e.target.name
		const value = e.target.value

		this.setState({
			form: {
				...this.state.form,
				[name]: {
					...this.state.form[name],
					value,
				},
			},
		})
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		let body = {}
		Object.keys(this.state.form).map(
			(input) => (body[input] = this.state.form[input].value)
		)

		try {
			await this.props
				.dispatch(createResource("contacts", body, this.props.rawToken))
				.then((contact) =>
					this.props
						.dispatch(
							addContactToCustomer(
								this.props.customer,
								contact.id,
								this.props.rawToken
							)
						)
						.then(() =>
							this.props.toggleWindowPortal(
								false,
								this.props.onChangeCustomer(
									null,
									"idCustomer",
									this.props.customer
								)
							)
						)
				)
		} catch (e) {}
	}

	render() {
		return (
			<div className={"box contactForm"}>
				<div className={"box-header"}>
					<div className={"title"}>Création d'un contact</div>
				</div>
				<div className={"box-body"}>
					<form
						onSubmit={this.handleSubmit}
						className={"form-group form-contact"}
					>
						<div className={"flex-form"}>
							<input
								type={"text"}
								name={"lastname"}
								placeholder={"Nom"}
								value={this.state.form.lastname.value}
								onChange={this.changeHandler}
							/>
							<input
								type={"text"}
								name={"firstname"}
								placeholder={"Prénom"}
								value={this.state.form.firstname.value}
								onChange={this.changeHandler}
							/>
							<input
								type={"email"}
								name={"email"}
								placeholder={"Adresse mail"}
								value={this.state.form.email.value}
								onChange={this.changeHandler}
							/>
							<input
								type={"text"}
								name={"phone"}
								placeholder={"Portable"}
								value={this.state.form.phone.value}
								onChange={this.changeHandler}
							/>
							<input
								type={"text"}
								name={"homePhone"}
								placeholder={"Fixe"}
								value={this.state.form.homePhone.value}
								onChange={this.changeHandler}
							/>
							<input
								type={"text"}
								name={"fax"}
								placeholder={"Fax"}
								value={this.state.form.fax.value}
								onChange={this.changeHandler}
							/>
						</div>

						<button title={"Créer"}>Créer</button>
					</form>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
	}
}

export default withRouter(connect(mapStateToProps)(ContactForm))
