import React, { Component } from "react"
import { connect } from "react-redux"

import { getResource } from "../../../redux/action"
import {
	changeProduct,
	changeTotalGroupProducts,
} from "../../../redux/QuotationFormAction"
import { MyAutosuggest } from "../../../components/MyAutosuggest"
import MyModal from "../../../components/MyModal"
import ProductsList from "../../products/ProductsList"
import { onDelete } from "../../../utils/Alert"

class ProductRow extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modalIsOpen: false,
		}
	}

	componentDidMount() {
		this.productToInput()
		this.changeHandler(null, "productGroup", this.props.idGroup)
	}

	productToInput = () => {
		const product = this.props.product
		if (product !== null) {
			Object.keys(product).forEach((input) => {
				if (input !== "id" && input !== "type")
					this.itemToInput(
						input,
						product[input] !== null ? product[input] : ""
					)
			})
			this.props.getTotals().then(() => {
				this.props.dispatch(
					changeTotalGroupProducts(this.props.idGroup)
				)
			})
		}
	}

	itemToInput = (input, item) =>
		this.props.dispatch(changeProduct(null, this.props.id, input, item))

	onDelete = () =>
		onDelete("", "Etes-vous sûr de vouloir supprimer ce produit ?", () =>
			this.props.onDelete()
		)

	changeHandler = (e, newName, newValue) =>
		this.props.changeHandler(e, newName, newValue)

	onChangeReference = (e, newName, newValue) => {
		this.changeHandler(e, newName, newValue)

		const product = this.props.products.find(
			(product) => product.reference === newValue
		)
		if (product) {
			this.changeWithProduct(product)
		}
	}

	getUnitPrice = (product) => {
		const unitPrice = parseFloat(product.unitPrice) || 0
		const mlPrice = parseFloat(product.mlPrice) || 0
		const m2Price = parseFloat(product.squarePrice) || 0
		let price = 0

		if (unitPrice > 0) price = unitPrice
		else if (mlPrice > 0) price = mlPrice
		else if (m2Price > 0) price = m2Price

		return price.toFixed(2)
	}

	changeWithProduct = (product) => {
		if (typeof product !== "undefined" && product !== null) {
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"code",
					product.code !== null ? product.code : ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"reference",
					product.reference !== null ? product.reference : ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"designation",
					product.designation !== null ? product.designation : ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"format",
					product.format !== null ? product.format : ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"quantity",
					product.quantity !== null ? product.quantity : ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"surface",
					product.surface !== null ? product.surface : ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"totalSurface",
					product.totalSurface !== null ? product.totalSurface : ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"unitPrice",
					this.getUnitPrice(product)
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"totalha",
					product.totalha !== null ? product.totalha : ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"marginCoefficient",
					product.marginCoefficient !== null
						? product.marginCoefficient
						: ""
				)
			)
			this.props.dispatch(
				changeProduct(
					null,
					this.props.id,
					"sellingPrice",
					product.sellingPrice !== null ? product.sellingPrice : ""
				)
			)
		}
	}

	handleAdd = (id) => {
		this.props
			.dispatch(getResource("products", id, this.props.rawToken))
			.then((data) => {
				this.changeWithProduct(data)
				this.setState({
					modalIsOpen: false,
				})
			})
	}

	render() {
		const data = this.props.data

		return data !== undefined ? (
			<tr className={"products-row"}>
				<MyModal
					isOpen={this.state.modalIsOpen}
					handleClose={() => this.setState({ modalIsOpen: false })}
					title={"Catalogue des produits"}
					content={
						<ProductsList
							addButton={true}
							handleAdd={(id) => this.handleAdd(id)}
							dontLoadDataOnMount={true}
						/>
					}
					className={"products-modal"}
				/>
				<td className={"cell-reference"}>
					<div className={"flex"}>
						<div
							className={"btn-circle btn-products btn-warning"}
							title={"Ouvrir le catalogue"}
							onClick={() => this.setState({ modalIsOpen: true })}
						>
							<i className="fas fa-book" />
						</div>
						<MyAutosuggest
							searchWithoutName={true}
							items={this.props.numbers}
							name={"reference"}
							placeholder={
								this.props.numbers
									? this.props.numbers.length > 0
										? "Référence"
										: "Chargement ..."
									: "Chargement ..."
							}
							value={data.reference}
							disabled={
								this.props.numbers
									? !this.props.numbers.length > 0
									: true
							}
							onChange={this.onChangeReference}
						/>
					</div>
				</td>
				{this.props.inputs}
				<td>
					<input
						type={"text"}
						name={"sellingPrice"}
						placeholder={"Prix de vente"}
						value={data.sellingPrice}
						onChange={this.changeHandler}
					/>
				</td>
				<td>
					<div
						className={"deleteProduct btn-circle btn-danger"}
						title={"Supprimer la ligne"}
						onClick={this.onDelete}
					>
						<i className="fas fa-trash-alt" />
					</div>
				</td>
			</tr>
		) : (
			""
		)
	}
}

const mapStateToProps = ({ apiReducer, quotationFormReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		products: apiReducer.products,
		form: quotationFormReducer.form,
		numbers: apiReducer.numbers.products,
	}
}

export default connect(mapStateToProps)(ProductRow)
