import { CheckAppVersionOutput } from "../domain/check-app-version.output"
import { httpClient } from "../../../../utils/http-client"

export class CheckAppVersionApiRepository implements CheckAppVersionOutput {
	checkActualAppVersion(): string {
		return localStorage.getItem("app-version")
	}

	checkNewAppVersion(): Promise<string> {
		return httpClient
			.get("/apiSettings/getByName/webapp-version")
			.then(({ data }) => {
				return Promise.resolve(data.value)
			})
			.catch((error) => {
				return Promise.resolve(error?.message)
			})
	}
}
