import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeRow, deleteRow } from "../../../redux/QuotationWriteAction"
import { deleteResource, getResource } from "../../../redux/action"
import { onDelete } from "../../../utils/Alert"
import { WriteRowView } from "./write-row.view"

interface IProps {
	article: any
	id: number
	onDrag: (id: number, up?: boolean) => void
}

export const WriteRowContainer = ({ article, id, onDrag }: IProps) => {
	const dispatch = useDispatch()

	const [modalIsOpen, setModalIsOpen] = useState(false)

	const apiState = useSelector(({ apiReducer }: any) => apiReducer)
	const { form } = useSelector(
		({ quotationWriteReducer }: any) => quotationWriteReducer
	)

	const { rawToken, articles } = apiState

	const numbers = apiState.numbers.articles

	const row = form.rows.find((row) => row.id === id)

	useEffect(() => {
		resourceToInput()
	}, [])

	const resourceToInput = () => {
		if (article !== null)
			Object.keys(article).map((input) => {
				itemToInput(
					input,
					article[input] !== null ? article[input] : ""
				)
			})
	}

	const itemToInput = (input, item) => {
		dispatch(changeRow(null, id, input, item))
	}

	const _changeRow = (event: any, newName: string, newValue: any) => {
		const name = newName !== undefined ? newName : event.target.name
		const value = newValue !== undefined ? newValue : event.target.value

		if (event !== null) event.persist()

		dispatch(changeRow(event, id, name, value))
	}

	const _onDelete = () => {
		onDelete(
			"",
			"Etes-vous sûr de vouloir supprimer cette ligne ?",
			async () => {
				if (article !== null) {
					try {
						await dispatch(
							deleteResource("articles", article.id, rawToken)
						)
						dispatch(deleteRow(id))
					} catch (e) {
						throw new Error(e)
					}
				}
			}
		)
	}

	const changeCode = (event: any, newName: string, newValue: any) => {
		const article = articles.find((article) => article.code === newValue)
		changeWithArticle(article)
	}

	const changeWithArticle = (article: any) => {
		if (typeof article !== "undefined" && article !== null) {
			itemToInput("code", article.code !== null ? article.code : "")
			itemToInput("text", article.text !== null ? article.text : "")
			itemToInput(
				"quantity",
				article.quantity !== null && !isNaN(article.unitPrice)
					? article.quantity
					: ""
			)
			_changeRow(
				null,
				"unitPrice",
				article.unitPrice && !isNaN(article.unitPrice)
					? parseFloat(article.unitPrice).toFixed(2)
					: ""
			)
		}
	}

	const handleOpenCloseModal = () => {
		setModalIsOpen((prev) => !prev)
	}

	const handleAdd = async (id: number) => {
		const data: any = await dispatch(getResource("articles", id, rawToken))

		changeWithArticle(data)

		handleOpenCloseModal()
	}

	const _onDrag = (up = true) => onDrag(id, up)

	return (
		<WriteRowView
			modalIsOpen={modalIsOpen}
			handleOpenCloseModal={handleOpenCloseModal}
			handleAdd={handleAdd}
			onDrag={_onDrag}
			numbers={numbers}
			row={row}
			changeCode={changeCode}
			changeRow={_changeRow}
			onDelete={_onDelete}
		/>
	)
}
