import React, { Component } from "react"
import { connect } from "react-redux"

import {
	checkUniqNumber,
	getNewNum,
	getNewNumModel,
	getNewNumWrited,
	getResource,
	getResourceByProperty,
	getResources,
	getResourcesByProperty,
} from "../../redux/action"
import { MyAutosuggest } from "../../components/MyAutosuggest"
import {
	changeQuotation,
	changeValidityOfProperty,
	resetWhenOnChangeCustomer,
	resetWhenOnChangeProject,
} from "../../redux/QuotationFormAction"
import { isDefined } from "../../utils/Data"
import { Settlement } from "./MainForm/Settlement"
import { DeliveryDelay } from "./MainForm/DeliveryDelay"
import { RedirectBtn } from "./Buttons/redirect-btn"

class MainForm extends Component {
	name = this.props.name === "quotation" ? "quotations" : "bills"

	componentDidMount() {
		const dispatch = this.props.dispatch
		dispatch(
			getResourcesByProperty("projects", "number", this.props.rawToken)
		)
		dispatch(
			getResourcesByProperty("customers", "code", this.props.rawToken)
		)
		dispatch(getResources("deliveryDelays", this.props.rawToken))
		dispatch(getResources("settlements", this.props.rawToken))
	}

	changeModel = () => {
		const value = this.props.data.model.value === false
		this.props.dispatch(changeQuotation(null, "model", value))

		if (value === true)
			this.props
				.dispatch(getNewNumModel(this.name, this.props.rawToken))
				.then(() =>
					this.props.dispatch(
						changeQuotation(null, "number", this.props.newNum)
					)
				)
		else if (this.props.data.drafted.value === null)
			this.props
				.dispatch(getNewNum(this.name, this.props.rawToken))
				.then(() =>
					this.props.dispatch(
						changeQuotation(null, "number", this.props.newNum)
					)
				)
		else
			this.props
				.dispatch(getNewNumWrited(this.name, this.props.rawToken))
				.then(() =>
					this.props.dispatch(
						changeQuotation(null, "number", this.props.newNum)
					)
				)
	}

	changeHandler = (e, name, value, callback) =>
		this.props.changeHandler(e, name, value, callback)

	onChangeProject = (e, newName, newValue) => {
		getResourceByProperty(
			"projects",
			"number",
			newValue,
			this.props.rawToken
		).then((id) => {
			if (isDefined(id))
				this.props
					.dispatch(getResource("projects", id, this.props.rawToken))
					.then(() => {
						const project = this.props.item
						if (project !== undefined && project !== null) {
							this.props.dispatch(
								changeQuotation(null, "projectId", project.id)
							)
							this.props.dispatch(
								changeQuotation(null, "project", project.number)
							)
							this.props.dispatch(
								changeQuotation(null, "name", project.name)
							)
							this.props.dispatch(
								changeQuotation(
									null,
									"customerId",
									project.customer.id
								)
							)
							this.props.dispatch(
								changeQuotation(
									null,
									"customer",
									project.customer.code
								)
							)
							this.props.dispatch(
								changeQuotation(
									null,
									"customerName",
									project.customer.name
								)
							)
						}
					})
			else this.resetWhenOnChangeProject()
		})
	}

	resetWhenOnChangeProject = () => {
		this.props.dispatch(resetWhenOnChangeProject())
	}

	onChangeCustomer = (e, newName, newValue) => {
		getResourceByProperty(
			"customers",
			"code",
			newValue,
			this.props.rawToken
		).then((id) => {
			if (isDefined(id))
				this.props
					.dispatch(getResource("customers", id, this.props.rawToken))
					.then(() => {
						const customer = this.props.item
						if (customer !== undefined && customer !== null) {
							this.props.dispatch(
								changeQuotation(null, "customerId", customer.id)
							)
							this.props.dispatch(
								changeQuotation(null, "customer", customer.code)
							)
							this.props.dispatch(
								changeQuotation(
									null,
									"customerName",
									customer.name
								)
							)
						}
					})
			else this.resetWhenOnChangeCustomer()
		})
	}

	resetWhenOnChangeCustomer = () => {
		this.props.dispatch(resetWhenOnChangeCustomer())
	}

	onChangeDiscount = (e) => {
		const name = e.target.name
		const value = e.target.value

		this.changeHandler(e, name, value)
	}

	onChangeNumber = (e) => {
		e.persist()
		this.props.changeHandler(e)

		//drafted POUR DEVIS
		this.props
			.dispatch(
				checkUniqNumber(
					this.name,
					e.target.value,
					this.props.rawToken,
					this.props.data.model.value,
					this.props.data.drafted.value
				)
			)
			.then((res) => {
				this.props.dispatch(changeValidityOfProperty("number", res))
			})
	}

	render() {
		const data = this.props.data
		const projectsNumber = this.props.numbers.projects
		const customersCode = this.props.numbers.customers
		const loadingProjects =
			isDefined(projectsNumber) && projectsNumber.length > 0
		const loadingCustomers =
			isDefined(customersCode) && customersCode.length > 0

		return (
			<div
				className={"form-accounting form-block"}
				style={
					this.props.show === true
						? { display: "block" }
						: { display: "none" }
				}
			>
				<div className={"form-group row"}>
					<div className={"col-12 col-lg-1"}>
						<label>
							{this.props.name === "quotation"
								? "N° devis"
								: "N° facture"}
						</label>
						<input
							type={"text"}
							name={"number"}
							placeholder={
								this.props.name === "quotation"
									? "N° devis"
									: "N° facture"
							}
							value={data.number.value}
							onChange={this.onChangeNumber}
							required={true}
							className={
								"form-control " +
								(data.number.valid ? "is-valid" : "is-invalid")
							}
						/>
					</div>
					<div className={"col-12 col-lg-1"}>
						<label>N° Dossier</label>
						<MyAutosuggest
							disabled={!loadingProjects > 0}
							searchWithoutName={true}
							items={projectsNumber}
							name={"project"}
							placeholder={
								loadingProjects ? "Dossier" : "Chargement ..."
							}
							value={data.project.value}
							onChange={this.onChangeProject}
							required={true}
							className={
								"form-control " +
								(data.project.valid ? "is-valid" : "is-invalid")
							}
						/>
						<RedirectBtn
							title={"Fiche du projet"}
							link={"/projets/" + data.projectId.value}
							disabled={data.projectId.value === null}
						/>
					</div>
					<div className={"col-12 col-lg-1"}>
						<label>N° Client</label>
						<MyAutosuggest
							disabled={!loadingCustomers}
							searchWithoutName={true}
							items={customersCode}
							name={"customer"}
							placeholder={
								loadingCustomers
									? "N° Client"
									: "Chargement ..."
							}
							value={data.customer.value}
							onChange={this.onChangeCustomer}
							required={true}
							className={
								"form-control " +
								(data.customer.valid
									? "is-valid"
									: "is-invalid")
							}
						/>
						<RedirectBtn
							title={"Fiche du client"}
							link={
								"/clients/" +
								data.customerId.value +
								"/modifier"
							}
							disabled={data.customerId.value === null}
						/>
					</div>
					<div className={"col-12 col-lg-4"}>
						<label>Client</label>
						<input
							type={"text"}
							disabled={!loadingCustomers}
							placeholder={
								loadingCustomers ? "Client" : "Chargement ..."
							}
							name={"customerName"}
							value={data.customerName.value}
							onChange={this.changeHandler}
							required={true}
							className={
								"form-control " +
								(data.customerName.valid
									? "is-valid"
									: "is-invalid")
							}
						/>
					</div>
					<div className={"input-name col-12 col-lg-5"}>
						<label>Intitulé</label>
						<input
							type={"text"}
							name={"name"}
							placeholder={"Intitulé"}
							value={data.name.value}
							onChange={this.changeHandler}
							required={true}
							className={
								"form-control " +
								(data.name.valid ? "is-valid" : "is-invalid")
							}
						/>
					</div>
				</div>
				<div className={"form-group row"}>
					<div className={"col-12 col-lg-2"}>
						<DeliveryDelay />
					</div>
					<div className={"col-12 col-lg-6"}>
						<Settlement />
					</div>
					<div className={"col-12 col-lg-2"}>
						<label htmlFor={"created"}>
							Date{" "}
							{this.props.name === "quotation"
								? "d'édition"
								: "de facturation"}
						</label>
						<input
							id="created"
							type={"date"}
							name={"created"}
							value={data.created.value}
							onChange={this.props.changeHandler}
						/>
					</div>
					<div className={"col-12 col-lg-2"}>
						<label htmlFor={"sent"}>Date d'envoi</label>
						<input
							id="sent"
							type={"date"}
							name={"sent"}
							value={data.sent.value}
							onChange={this.props.changeHandler}
						/>
					</div>
				</div>
				<div className={"form-group row"}>
					<div className={"col-12 col-lg-2"}>
						<label>Montant total avant remise</label>
						<input
							type={"text"}
							name={"totalPrice"}
							placeholder={"Montant total avant remise"}
							value={data.totalPrice.value}
							disabled={true}
						/>
					</div>
					<div className={"col-12 col-lg-2"}>
						<label>Acompte</label>
						<input
							type={"text"}
							name={"advance"}
							placeholder={"Acompte"}
							value={data.advance.value}
							onChange={this.changeHandler}
						/>
					</div>
					<div className={"col-12 col-lg-2"}>
						<label>Remise en €</label>
						<input
							type={"text"}
							name={"discountEuros"}
							placeholder={"Remise en €"}
							value={data.discountEuros.value}
							onChange={this.onChangeDiscount}
						/>
					</div>
					<div className={"col-12 col-lg-2"}>
						<label>Remise en %</label>
						<input
							type={"text"}
							name={"discountPerCentage"}
							placeholder={"Remise en %"}
							value={data.discountPerCentage.value}
							onChange={this.onChangeDiscount}
						/>
					</div>
					<div className={"col-12 col-lg-2"}>
						<label>Montant total après remise</label>
						<input
							type={"text"}
							name={"totalPriceDiscount"}
							placeholder={"Montant total après remise"}
							value={data.totalPriceDiscount.value}
							disabled={true}
						/>
					</div>
					<div className={"col-12 col-lg-2"}>
						<label>TVA</label>
						<select
							name={"vat"}
							value={data.vat.value}
							defaultValue={0.2}
							onChange={this.changeHandler}
						>
							<option value={0.2}>20%</option>
							<option value={0.1}>10%</option>
							<option value={0.055}>5,5%</option>
							<option value={0.021}>2,1%</option>
							<option value={0}>0%</option>
						</select>
					</div>
				</div>
				<div className={"form-group row"}>
					<div className={"col-12"}>
						<label className={"saveAsModel"} htmlFor={"model"}>
							Enregistrer comme modèle ?
						</label>
						<input
							type={"checkbox"}
							name={"model"}
							id={"model"}
							value={data.model.value}
							onChange={this.changeModel}
							checked={data.model.value}
						/>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ apiReducer }, props) => {
	let models = null

	switch (props.name) {
		case "quotation":
			models = apiReducer.quotations
			break
		case "bills":
			models = apiReducer.bills
			break
		default:
			break
	}

	return {
		rawToken: apiReducer.rawToken,
		modeles: models,
		newNum: apiReducer.newNum,
		numbers: apiReducer.numbers,
		item: apiReducer.item,
		deliveryDelays: apiReducer.deliveryDelays,
		settlements: apiReducer.settlements,
	}
}

export default connect(mapStateToProps)(MainForm)
