import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getResource } from "../../../redux/action"
import Header from "./Header"

const HeaderContainer = () => {
	const dispatch = useDispatch()
	const { rawToken } = useSelector(({ apiReducer }) => apiReducer)
	const reducer = useSelector(({ orderFormReducer }) => orderFormReducer)

	const { data } = reducer
	const [total, setTotal] = useState(0)
	const [provider, setProvider] = useState(null)

	useEffect(() => {
		setTotal(() => {
			let total = 0
			data.productsOrders.forEach((product) => {
				const totalSurface =
					(parseFloat(product.surface) || 0) *
					(parseFloat(product.quantity) || 0)
				total =
					total +
					(parseFloat(totalSurface) || 0) *
						(parseFloat(product.unitPrice) || 0)
			})
			return total
		})
	}, [data.productsOrders])

	useEffect(() => {
		if (reducer.provider !== null)
			dispatch(getResource("providers", reducer.provider, rawToken)).then(
				(item) => setProvider(item)
			)
	}, [reducer.provider])

	return (
		<Header
			reducer={reducer}
			data={data}
			total={total}
			provider={provider}
		/>
	)
}

export default HeaderContainer
