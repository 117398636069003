import React from "react"

const Row = ({ product, total, totalSurface }) => (
	<tr key={product.id} className={"article"}>
		<td className={"cell-input small-cell"}>{product.reference}</td>
		<td className={"cell-input"}>
			<div dangerouslySetInnerHTML={{ __html: product.designation }} />
			{product.project?.customer?.name && (
				<p>{`Client : ${product.project.customer.name}`}</p>
			)}
		</td>
		<td className={"small-cell"}>{product.project?.number}</td>
		<td className={"align-right"}>{product.format}</td>
		<td className={"text-right small-cell"}>{product.surface}</td>
		<td className={"text-right small-cell quantity-cell"}>
			{product.quantity}
		</td>
		<td className={"text-right small-cell total-surface-cell"}>
			{totalSurface}
		</td>
		<td className={"text-right medium-cell"}>
			{parseFloat(product.unitPrice).toFixed(2) + " €"}
		</td>
		<td className={"text-right medium-cell"}>{total + " €"}</td>
	</tr>
)

export default Row
