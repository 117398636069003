import {
	ADD_PRODUCT_ORDERS_FORM,
	ADD_PRODUCT_ORDERS_TO_ROW,
	CHANGE_PRODUCT_ORDERS_FORM,
	CHANGE_PROPERTY,
	CHANGE_PROVIDER,
	CHANGE_USER,
	DELETE_PRODUCT_ORDERS_FORM,
	RESET,
	RESOURCE_TO_FORM,
} from "../../actions/forms/orderFormAction"
import { getDateFormatedEN } from "../../../utils/Date"
import { isDefined } from "../../../utils/Data"

const initialState = {
	currentId: 0,
	orderUpdated: null,
	data: {
		number: "",
		ordersDate: getDateFormatedEN(new Date()),
		provider: "",
		title: "",
		delivery: false,
		productsOrders: [],
	},
	provider: null,
	user: null,
	lastPosition: 0,
}

const orderFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_PROPERTY:
			return {
				...state,
				data: {
					...state.data,
					[action.name]: action.value,
				},
			}
		case CHANGE_PROVIDER:
			return {
				...state,
				provider: action.id,
			}
		case RESET:
			return initialState
		case ADD_PRODUCT_ORDERS_FORM: {
			let newCurrentId = state.currentId
			state.data.productsOrders.forEach((product) => {
				if (product.id >= newCurrentId) newCurrentId = product.id + 1
			})

			const product = {
				id: newCurrentId,
				reference: "",
				designation: "",
				project: null,
				format: "",
				surface: 0,
				unitPrice: 0,
				quantity: 0,
				total: 0,
				product: null,
				position: state.lastPosition,
			}

			return {
				...state,
				currentId: newCurrentId + 1,
				data: {
					...state.data,
					productsOrders: [
						...state.data.productsOrders.concat(product),
					],
				},
				lastPosition: state.lastPosition + 1,
			}
		}
		case ADD_PRODUCT_ORDERS_TO_ROW: {
			const newProduct = {
				id: state.currentId,
				reference: isDefined(action.product)
					? action.product.reference
					: "",
				designation: isDefined(action.product)
					? action.product.designation
					: "",
				unitPrice: isDefined(action.product)
					? getUnitPrice(action.product)
					: 0,
				format: isDefined(action.product) ? action.product.format : "",
				surface: isDefined(action.product) ? action.product.surface : 0,
				quantity: 0,
				total: 0,
				product: isDefined(action.product) ? action.product.id : null,
			}

			return {
				...state,
				currentId: state.currentId + 1,
				data: {
					...state.data,
					productsOrders: [
						...state.data.productsOrders.map((product) =>
							action.row === product.id ? newProduct : product
						),
					],
				},
			}
		}
		case DELETE_PRODUCT_ORDERS_FORM:
			return {
				...state,
				data: {
					...state.data,
					productsOrders: [
						...state.data.productsOrders.filter(
							(product) => product.id !== action.payload
						),
					],
				},
			}
		case CHANGE_PRODUCT_ORDERS_FORM: {
			return {
				...state,
				data: {
					...state.data,
					productsOrders: [
						...state.data.productsOrders.map((product) => {
							if (action.id === product.id) {
								return {
									...product,
									[action.name]: action.value,
								}
							} else return product
						}),
					],
				},
			}
		}
		case RESOURCE_TO_FORM: {
			let higherPosition = 0

			action.resource.productsOrders.forEach((productsOrders) => {
				if (productsOrders.position > higherPosition) {
					higherPosition = productsOrders.position
				}
			})

			return {
				...state,
				orderUpdated: action.resource,
				lastPosition: higherPosition + 1,
			}
		}
		case CHANGE_USER:
			return {
				...state,
				user: action.user,
			}
		default:
			return state
	}
}

const getUnitPrice = (product) => {
	const unitPrice = parseFloat(product.unitPrice) || 0
	const mlPrice = parseFloat(product.mlPrice) || 0
	const m2Price = parseFloat(product.squarePrice) || 0
	let price = 0

	if (unitPrice > 0) price = unitPrice
	else if (mlPrice > 0) price = mlPrice
	else if (m2Price > 0) price = m2Price

	return price
}

export default orderFormReducer
