import React, { useEffect, useState } from "react"

import Row from "./Row"

const RowContainer = ({ product }) => {
	const [totalSurface, setTotalSurface] = useState("")
	const [total, setTotal] = useState(0)

	useEffect(() => {
		setTotalSurface(
			(
				(parseFloat(product.surface) || 0) *
				(parseFloat(product.quantity) || 0)
			).toFixed(2)
		)
	}, [product.surface, product.quantity])

	useEffect(() => {
		setTotal(
			(parseFloat(totalSurface) || 0) *
				(parseFloat(product.unitPrice) || 0)
		)
	}, [totalSurface, product.unitPrice])

	return (
		<Row
			product={product}
			total={total.toFixed(2)}
			totalSurface={totalSurface}
		/>
	)
}

export default RowContainer
