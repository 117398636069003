import React, { Component } from "react"
import { connect } from "react-redux"

import "../../../css/pages/products/form.scss"
import { changeMeasure } from "../../../redux/QuotationFormAction"
import { onDelete } from "../../../utils/Alert"

class QuotationMeasuresForm extends Component {
	componentDidMount() {
		this.measureToInput()
	}

	measureToInput = () => {
		const measure = this.props.measure
		if (measure !== null) {
			Object.keys(measure).forEach((input) =>
				this.itemToInput(
					input,
					measure[input] !== null ? measure[input] : 0
				)
			)
			const data = this.getData()
			this.getTotalSurfaceAndPerimeter(
				data.surface,
				data.quantity,
				data.pubCoefficient,
				data.width,
				data.height
			)
			Object.keys(measure).forEach((input) =>
				this.changeTotalMeasures(input)
			)
			this.changeTotalMeasures("totalSurface")
		}
	}

	itemToInput = (input, item) => {
		this.props.dispatch(changeMeasure(null, this.props.id, input, item))
	}

	getTotalSurfaceAndPerimeter = (
		surface,
		quantity,
		pubCoefficient,
		width,
		height
	) => {
		const totalSurface = parseFloat(surface * quantity) || 0
		const surfCoefficient =
			parseFloat(totalSurface * pubCoefficient).toFixed(2) || 0
		const perimeter =
			parseFloat((width + height) * 2 * quantity).toFixed(2) || 0
		this.props.dispatch(
			changeMeasure(
				null,
				this.props.id,
				"totalSurface",
				totalSurface.toFixed(2)
			)
		)
		this.props.dispatch(
			changeMeasure(
				null,
				this.props.id,
				"surfCoefficient",
				surfCoefficient
			)
		)
		this.props.dispatch(
			changeMeasure(null, this.props.id, "perimeter", perimeter)
		)
	}

	onDelete = () => {
		onDelete(
			"",
			"Etes-vous sûr de vouloir supprimer cette mesure ?",
			() => {
				this.props.onDelete(this.props.id)
				this.props.changeTotalMeasures()
			}
		)
	}

	changeTotalMeasures = (name) => this.props.changeTotalMeasures(name)

	getData = () =>
		this.props.form.measures.value.find(
			(measure) => measure.id === this.props.id
		)

	changeMeasure = (e, newName, newValue) => {
		if (e !== null) e.persist()

		this.props
			.dispatch(changeMeasure(e, this.props.id, newName, newValue))
			.then(() => {
				const data = this.getData()
				let width = parseFloat(data.width) || 0
				let height = parseFloat(data.height) || 0
				let quantity = parseFloat(data.quantity) || 0
				let pubCoefficient = parseFloat(data.pubCoefficient) || 0

				let surface = parseFloat(width * height) || 0
				let totalSurface = parseFloat(surface * quantity) || 0
				let surfCoefficient =
					parseFloat(totalSurface * pubCoefficient).toFixed(2) || 0
				let perimeter =
					parseFloat((width + height) * 2 * quantity).toFixed(2) || 0

				this.props
					.dispatch(
						changeMeasure(
							e,
							this.props.id,
							"surface",
							surface.toFixed(3)
						)
					)
					.then(() => this.changeTotalMeasures("surface"))
				this.props
					.dispatch(
						changeMeasure(
							e,
							this.props.id,
							"totalSurface",
							totalSurface.toFixed(3)
						)
					)
					.then(() => this.changeTotalMeasures("totalSurface"))
				this.props
					.dispatch(
						changeMeasure(
							e,
							this.props.id,
							"surfCoefficient",
							surfCoefficient
						)
					)
					.then(() => this.changeTotalMeasures("surfCoefficient"))
				this.props
					.dispatch(
						changeMeasure(e, this.props.id, "perimeter", perimeter)
					)
					.then(() => this.changeTotalMeasures("perimeter"))

				if (e !== null) this.changeTotalMeasures(e.target.name)
				else {
					Object.entries(data).map((splitedMeasure) =>
						this.changeTotalMeasures(splitedMeasure[0])
					)
				}
			})
	}

	render() {
		const data = this.getData()
		return data !== undefined ? (
			<tr className={"measure-form-row"}>
				<td>
					<input
						type={"number"}
						step={"any"}
						min={0}
						name={"width"}
						placeholder={"Largeur"}
						value={data.width}
						onChange={this.changeMeasure}
					/>
				</td>
				<td>
					<input
						type={"number"}
						step={"any"}
						min={0}
						name={"height"}
						placeholder={"Hauteur"}
						value={data.height}
						onChange={this.changeMeasure}
					/>
				</td>
				<td>
					<input
						type={"number"}
						step={"any"}
						min={0}
						name={"surface"}
						placeholder={"Surface"}
						value={data.surface}
						onChange={this.changeMeasure}
					/>
				</td>
				<td>
					<input
						type={"number"}
						step={"any"}
						min={0}
						name={"quantity"}
						placeholder={"Quantité"}
						value={data.quantity}
						onChange={this.changeMeasure}
					/>
				</td>
				<td>
					<input
						type={"number"}
						step={"any"}
						min={0}
						name={"totalSurface"}
						placeholder={"Surface totale"}
						value={data.totalSurface}
						onChange={this.changeMeasure}
					/>
				</td>
				<td>
					<input
						type={"number"}
						step={"any"}
						min={0}
						name={"pubCoefficient"}
						placeholder={"Coeff. pub"}
						value={data.pubCoefficient}
						onChange={this.changeMeasure}
					/>
				</td>
				<td>
					<input
						type={"number"}
						step={"any"}
						min={0}
						name={"surfCoefficient"}
						placeholder={"Surf. pub"}
						value={data.surfCoefficient}
						onChange={this.changeMeasure}
					/>
				</td>
				<td>
					<input
						type={"number"}
						step={"any"}
						min={0}
						name={"perimeter"}
						placeholder={"Périmètre"}
						value={data.perimeter}
						onChange={this.changeMeasure}
					/>
				</td>
				<td>
					<div
						className={"btn-circle btn-danger"}
						title={"Supprimer cette ligne"}
						onClick={this.onDelete}
					>
						<i className="fas fa-trash-alt" />
					</div>
				</td>
			</tr>
		) : (
			""
		)
	}
}

const mapStateToProps = ({ apiReducer, quotationFormReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		measures: apiReducer.quotationMeasures,
		form: quotationFormReducer.form,
	}
}

export default connect(mapStateToProps)(QuotationMeasuresForm)
