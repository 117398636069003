import { CheckAppVersionOutput } from "./check-app-version.output"

export class CheckAppVersion {
	private _checkAppVersionRepository: CheckAppVersionOutput

	constructor(checkAppVersionRepository: CheckAppVersionOutput) {
		this._checkAppVersionRepository = checkAppVersionRepository
	}

	async execute(): Promise<boolean> {
		const currentVersion: string =
			await this._checkAppVersionRepository.checkActualAppVersion()
		const version: string =
			await this._checkAppVersionRepository.checkNewAppVersion()

		return currentVersion === version
	}
}
