import React from "react"
import { AccountingAction } from "../../types/accounting"

interface Props {
	id: number | string
	data: any
	action: string
	handleSubmit: (event: any, write?: boolean, redirect?: boolean) => void
}

export const SavedButtons = ({ id, data, action, handleSubmit }: Props) => {
	const canSave = () => {
		if (id === "creer") return true
		else if (
			action === AccountingAction.UPDATE ||
			action === AccountingAction.DUPLICATE ||
			action === AccountingAction.QUOTATION
		)
			return data.projectId.value !== null
		else return false
	}

	const getMessage = () => {
		if (data.number.value === "")
			return "Veuillez entrer un n° de devis ou de facture"
		else if (data.project.value === "") return "Veuillez choisir un projet"
		else if (data.customer.value === "") return "Veuillez choisir un client"
	}

	return (
		<div className={"saved-buttons"}>
			{canSave() === true ? (
				<>
					{id !== "creer" ? (
						action === AccountingAction.DUPLICATE ? (
							<button title={"Modifier"}>Dupliquer</button>
						) : (
							<button title={"Modifier"}>Modifier</button>
						)
					) : (
						<button title={"Créer"}>Créer</button>
					)}
					<button
						title={"Sauvegarder"}
						onClick={(e) => handleSubmit(e, false, false)}
					>
						Sauvegarder
					</button>
					<button
						title={"Rédiger"}
						onClick={(e) => handleSubmit(e, true)}
					>
						Rédiger
					</button>
				</>
			) : (
				<>
					<button title={getMessage()} disabled={true}>
						Modifier
					</button>
					<button title={getMessage()} disabled={true}>
						Sauvegarder
					</button>
					<button title={getMessage()} disabled={true}>
						Rédiger
					</button>
				</>
			)}
		</div>
	)
}
