import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { deleteResource } from "../../redux/action"
import Table from "../../components/Table"
import { getColumnsWidth, getDefaultFiltered } from "../../utils/List"
import { onDelete } from "../../utils/Alert"
import { TextFilter } from "../../components/List/index"
import Page from "../../components/Page/Page"

const frenchName = "catégories d'articles"
const localName = "articleCategories"
const apiName = "articleCategories"

let localNames = ["name"]
localNames = localNames.map((name) => localName + "-" + name)

class ArticleCategories extends Component {
	constructor(props) {
		super(props)
		this.state = {
			columns: [
				{
					Header: "Désignation",
					accessor: "name",
					width: getColumnsWidth(localNames[0], 1700),
				},
				{
					Header: "Actions",
					accessor: "actions",
					sortable: false,
					resizable: false,
					width: 100,
					Cell: ({ row }) => (
						<div className={"btn-flex"}>
							<Link
								to={
									"/categories-articles/" +
									row._original.id +
									"/modifier"
								}
							>
								<div
									className="btn-circle btn-warning"
									title={"Modifier"}
								>
									<i className="fa fa-edit" />
								</div>
							</Link>
							<div
								className="btn-circle btn-danger"
								title={"Supprimer"}
								onClick={() =>
									this.handleDelete(row._original.id)
								}
							>
								<i className="fa fa-trash" />
							</div>
						</div>
					),
				},
			],
		}
	}

	componentDidMount() {
		this.state.columns.forEach((column, i) => {
			if (i < this.state.columns.length)
				column.Filter = ({ filter, onChange }) => (
					<TextFilter
						localName={localNames[i]}
						filter={filter}
						onChange={onChange}
					/>
				)
		})
	}

	handleDelete = (id) =>
		onDelete(
			"",
			"Etes-vous sûr de vouloir supprimer cette catégorie ?",
			() =>
				this.props.dispatch(
					deleteResource(apiName, id, this.props.rawToken)
				)
		)

	buttonsRight = () => (
		<Link to={"/categories-articles/creer"}>
			<button className={"btn-circle add-resource"} title={"Ajouter"}>
				<i className={"fas fa-plus"} />
			</button>
		</Link>
	)

	render() {
		return (
			<Page
				siteTitle={"Catégories d'articles"}
				title={"Gestion des catégories d'articles"}
				headerButtons={this.buttonsRight()}
			>
				<Table
					name={localName + "-list"}
					data={this.props[apiName]}
					columns={this.state.columns}
					frenchName={frenchName}
					localName={localName}
					apiName={apiName}
					defaultFiltered={getDefaultFiltered(
						localName,
						this.state.columns
					)}
					defaultSorted={[
						{
							id: "name",
							desc: false,
						},
					]}
				/>
			</Page>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		[apiName]: apiReducer[apiName],
	}
}

export default connect(mapStateToProps)(ArticleCategories)
