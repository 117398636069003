import React from "react"
import { withRouter } from "react-router"
import { useSelector } from "react-redux"

import TopBar from "../components/Layout/TopBar"
import Sidenav from "../components/Layout/Sidenav"
import MessagesAlert from "../components/Layout/MessagesAlert"
import { CheckAppVersionContainer } from "../features/version-control/check-app-version/application/check-app-version.container"

const PrivateComponent = ({ isCalendar, component }) => {
	const { sidenavOpened } = useSelector(({ designReducer }) => designReducer)

	return isCalendar === true ? (
		component
	) : (
		<>
			<TopBar />
			<Sidenav />
			<MessagesAlert />
			<CheckAppVersionContainer />
			<div
				className={
					sidenavOpened === false ? "page sidenav-hide" : "page"
				}
			>
				{component}
			</div>
		</>
	)
}

export default withRouter(PrivateComponent)
