import React from "react"
import { Link } from "react-router-dom"

import Page from "../../components/Page/Page"
import OrdersList from "./OrdersList"

const Orders = () => {
	const buttonsRight = () => (
		<Link to={"/commandes/creer"}>
			<button className={"btn-circle add-resource"} title={"Ajouter"}>
				<i className={"fas fa-plus"} />
			</button>
		</Link>
	)

	return (
		<Page
			siteTitle={"Commandes"}
			title={"Gestion des commandes"}
			headerButtons={buttonsRight()}
		>
			<OrdersList />
		</Page>
	)
}

export default Orders
