export enum AccountingAction {
	UPDATE = "modifier",
	DUPLICATE = "dupliquer",
	QUOTATION = "devis",
}

export enum AccountingElementType {
	QUOTATIONS = "quotations",
	BILLS = "bills",
}

export class Quotation {
	advance: boolean
	color: any
	created: string
	customerName: string
	discountEuros?: number
	discountPerCentage?: number
	id: number
	model: boolean
	name: string
	number: string
	project: any
	sent: string
	totalPrice: number
	vat?: 0 | 0.021 | 0.055 | 0.1 | 0.2
	settlement?: any
	deliveryDelay?: any
	products?: any[]
	measures?: any[]
	city?: string
}

export class Bill extends Quotation {
	quotation?: Quotation
	paid?: string
}
