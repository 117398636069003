import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { deleteResource } from "../../redux/action"
import Table from "../../components/Table"
import { getColumnsWidth, getDefaultFiltered } from "../../utils/List"
import { TextFilter } from "../../components/List/index"
import Page from "../../components/Page/Page"
import { isDefined } from "../../utils/Data"
import { onDelete } from "../../utils/Alert"

const frenchName = "clients"
const localName = "customers"
const apiName = "customers"

let localNames = [
	"code",
	"name",
	"address",
	"phone",
	"homePhone",
	"email",
	"contacts",
]
localNames = localNames.map((name) => localName + "-" + name)

class Customers extends Component {
	constructor(props) {
		super(props)
		this.state = {
			columns: [
				{
					Header: "Code",
					accessor: "code",
					width: getColumnsWidth(localNames[0], 60),
				},
				{
					Header: "Nom",
					accessor: "name",
					width: getColumnsWidth(localNames[1], 400),
				},
				{
					Header: "Adresse",
					accessor: "address",
					width: getColumnsWidth(localNames[2], 370),
					filterable: false,
					sortable: false,
					Cell: ({ row }) => {
						const address = row._original.address
						return address !== null ? (
							<div>
								{address.number +
									" " +
									address.street +
									(address.postalCode !== "" ||
									address.city !== ""
										? ", " +
										  address.postalCode +
										  " " +
										  address.city
										: "")}
							</div>
						) : (
							""
						)
					},
				},
				{
					Header: "Mobile",
					accessor: "phone",
					width: getColumnsWidth(localNames[3], 120),
				},
				{
					Header: "Fixe",
					accessor: "homePhone",
					width: getColumnsWidth(localNames[4], 120),
				},
				{
					Header: "E-mail",
					accessor: "email",
					width: getColumnsWidth(localNames[5], 250),
				},
				{
					Header: "Contacts",
					accessor: "contacts",
					width: getColumnsWidth(localNames[6], 300),
					sortable: false,
					Cell: ({ row }) =>
						row._original.contacts.map((contact, i) => (
							<div key={i}>
								{isDefined(contact.lastname)
									? contact.lastname
									: ""}
							</div>
						)),
				},
				{
					Header: "Actions",
					accessor: "id",
					sortable: false,
					resizable: false,
					width: 150,
					Cell: ({ row }) => {
						return (
							<div className={"btn-flex"}>
								<Link to={"/clients/" + row._original.id}>
									<div
										className="btn-circle btn-primary"
										title={"Voir"}
									>
										<i className="fa fa-eye" />
									</div>
								</Link>
								<Link
									to={
										"/clients/" +
										row._original.id +
										"/modifier"
									}
								>
									<div
										className="btn-circle btn-warning"
										title={"Modifier"}
									>
										<i className="fa fa-edit" />
									</div>
								</Link>
								<div
									className="btn-circle btn-danger"
									title={"Supprimer"}
									onClick={() =>
										this.handleDelete(row._original.id)
									}
								>
									<i className="fa fa-trash" />
								</div>
							</div>
						)
					},
				},
			],
		}
	}

	componentDidMount() {
		this.state.columns.forEach((column, i) => {
			if (i < this.state.columns.length)
				column.Filter = ({ filter, onChange }) => (
					<TextFilter
						localName={localNames[i]}
						filter={filter}
						onChange={onChange}
					/>
				)
		})
	}

	handleDelete = (id) =>
		onDelete("", "Etes-vous sûr de vouloir supprimer ce client ?", () =>
			this.props.dispatch(
				deleteResource(apiName, id, this.props.rawToken)
			)
		)

	buttonsRight = () => (
		<Link to={"/clients/creer"}>
			<button className={"btn-circle add-resource"} title={"Ajouter"}>
				<i className={"fas fa-plus"} />
			</button>
		</Link>
	)

	render() {
		return (
			<Page
				siteTitle={"Clients"}
				title={"Gestion des clients"}
				headerButtons={this.buttonsRight()}
				className={"customers"}
			>
				<Table
					name={localName + "-list"}
					data={this.props[apiName]}
					columns={this.state.columns}
					frenchName={frenchName}
					localName={localName}
					apiName={apiName}
					defaultFiltered={getDefaultFiltered(
						localName,
						this.state.columns
					)}
					defaultSorted={[
						{
							id: "code",
							desc: true,
						},
					]}
				/>
			</Page>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		[apiName]: apiReducer[apiName],
	}
}

export default connect(mapStateToProps)(Customers)
