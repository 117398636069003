import React from "react"
import "./cgv.scss"

export const CGV = () => {
	return (
		<div className="cgv">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className={"cgv-title"}>
							CONDITIONS GENERALES DE VENTE
						</h2>

						<div className={"cgv-row"}>
							<div className={"cgv-col"}>
								<div>
									<h3>Article 1 - Généralités</h3>
									<p>
										Toute commande implique de la part du
										client l’acceptation sans réserve des
										conditions de vente sauf dérogation
										écrite de notre part. Ces conditions
										l’emportent sur les documents et la
										correspondance du client.
									</p>

									<p>
										Toute commande est considérée comme
										définitive après acceptation par Marne
										Enseignes et après que l’ensemble des
										éléments à fournir par le client soit
										réceptionné et réputé conforme par Marne
										Enseignes. Toute annulation partielle ou
										totale par le client sans consentement
										préalable sera facturée quelle que soit
										la cause de l’annulation.
									</p>
								</div>

								<div>
									<h3>Article 2 - Maquettes</h3>
									<p>
										À moins de conventions contraires, les
										maquettes sont fournies par le client.
										Celles-ci doivent être remises à Marne
										Enseignes une semaine au moins avant la
										date de livraison de l’ordre. La
										reproduction des maquettes spéciales, la
										fourniture de dispositifs spéciaux,
										l’éclairage peuvent donner lieu à des
										suppléments fixés par les conditions
										particulières. Toute demande ultérieure
										de modification fera l’objet d’un devis
										préalable. En cas d’exécution des
										maquettes par Marne Enseignes, de même
										que de tous croquis, projets, dessins,
										gravure, compositions, fichiers, clichés
										photographiques ou autres, fournis par
										Marne Enseignes, celle-ci se réserve la
										propriété artistique et reste sa
										propriété exclusive, ils ne peuvent donc
										pas être communiqués à un tiers, même
										s’ils sont facturés et la reproduction
										en est formellement interdite.
									</p>
								</div>

								<div>
									<h3>Article 3 - Matériel</h3>
									<p>
										Lorsque le client fournit un matériel
										déterminé, celui-ci doit être remis au
										moins une semaine avant la date de
										livraison. En cas de retard du client
										dans la fourniture qui lui incombe, la
										livraison est assurée aux mieux des
										possibilités de Marne Enseignes.
									</p>
								</div>

								<div>
									<h3>
										Article 4 - Prix et conditions de
										paiement
									</h3>
									<p>
										Les prix s’entendent hors taxes. Ils
										sont donnés à titre indicatif et sont
										révisables sans préavis. Sauf conditions
										particulières mentionnées sur la
										facture, le paiement doit intervenir à
										la réception de la facture. Selon le
										montant de la commande un acompte peut
										vous être demandé. Aucun escompte ne
										sera pratiqué par Marne Enseignes pour
										paiement comptant ou anticipé. Des frais
										administratifs de 5€ HT sont appliqués
										pour toute facture dont le montant HT
										est inférieur à 40€ HT.
									</p>
								</div>

								<div>
									<h3>
										Article 5 - Responsabilité et
										autorisation
									</h3>

									<h4>5.1 Textes et logos</h4>
									<p>
										Marne Enseignes ne prend aucune
										responsabilité au sujet des droits de
										reproduction des originaux qui lui sont
										confiés. Marne Enseignes considère que
										tous les clients possèdent ces droits de
										reproduction pour les ordres qui lui
										sont confiés et le fait même de la
										remise des documents à reproduire
										garantit Marne Enseignes contre toutes
										actions qui pourraient être intentées au
										sujet de ces droits.
									</p>

									<h4>5.2 Installation</h4>
									<p>
										Le client est seul responsable de
										l’implantation de ses travaux
										publicitaires quant à leur conformité
										avec les règlements en vigueur (décret
										n°2012-118 du 30 janvier 2012). Pour
										toutes demandes de fourniture et/ou pose
										d’enseignes, pré-enseignes ou publicités
										soumises à autorisation préalable, Marne
										Enseignes estime que le client doit
										faire son affaire de l’obtention de
										toutes autorisations administratives
										et/ou privées nécessaires (ABF,
										préfectorales, départementales,
										communales, des propriétaires, des
										gérants ou syndics d’immeuble…). Il
										assume vis-à-vis de Marne Enseignes et
										des tiers l’entière responsabilité quant
										au respect des obligations légales et/ou
										contractuelles auquel il est soumis à
										cet égard et veille à respecter les
										conditions et formalités d’obtention. Le
										client s’engage à réaliser l’ensemble
										des travaux préparatoires nécessaires à
										l’installation du matériel et notamment
										à s’assurer que le chantier soit
										accessible par Marne Enseignes. En cas
										de non- respect par le client de l’une
										de ses obligations provoquant un retard
										dans l’exécution des prestations et/ou
										annulation de l’opération, Marne
										Enseignes est en droit de réclamer des
										indemnités forfaitaires ou à défaut le
										remboursement des frais de personnel,
										déplacement, location de matériel majoré
										à 50%.
									</p>

									<p>
										Les produits Marne Enseignes seront
										confiés au client, assortis de conseils
										d’utilisation. De ce fait, la
										responsabilité de Marne Enseignes ne
										pourra donc en aucun cas être engagée
										quant aux anomalies pouvant survenir.
									</p>
								</div>

								<div>
									<h3>Article 6 - Livraison</h3>
									<p>
										Les délais de livraison ne sont donnés
										qu’a titre indicatif sauf stipulation
										contraire. Les retards éventuels dus à
										un cas de force majeur tel que défini à
										l’article 11 des présentes conditions ou
										justifiés par un motif sérieux ne
										pourront pas entraîner l’annulation de
										la vente, un refus de marchandises ou
										une demande de dommages et intérêts, ni
										même un report d’échéance.
									</p>

									<p>
										Les marchandises voyagent aux risques et
										périls du destinataire (conformément aux
										dispositions de l’article L.132-7 du
										Code du Commerce). En cas d’enlèvement à
										la société, le client à l’entière
										responsabilité du chargement.
									</p>
								</div>

								<div>
									<h3>Article 7 - Réception</h3>
									<p>
										Les réclamations sur les vices apparents
										ou la non-conformité du produit doivent
										être formulées dans les 5 jours à la
										réception ou enlèvement des
										marchandises. Passé ce délai, les
										marchandises sont réputées acceptées.
									</p>
								</div>
							</div>
							<div className={"cgv-col"}>
								<div>
									<h3>Article 8 - Réclamation</h3>
									<p>
										Toute réclamation doit être faite par
										lettre recommandée avec accusé de
										réception dans les 5 jours qui suivent
										la réception. Passé ce délai la commande
										sera considérée comme étant conforme et
										les vices apparents qui pourraient
										éventuellement l’affecter, seront
										considérés comme ayant été acceptés par
										l’acheteur et ne pourront plus donner
										lieu à une quelconque réclamation.
									</p>
								</div>

								<div>
									<h3>Article 9 – Garantie</h3>
									<p>
										Une garantie d’1 an, pièces et main
										d’œuvre, s’appliquera de fait sur la
										totalité de la prestation réalisée par
										Marne Enseignes. Cette garantie est
										exclue en cas de mauvaise conservation
										ou d’utilisation anormale des produits,
										de détérioration provenant d’accident,
										d’évènement extérieur, de conditions
										climatiques exceptionnelles, cas de
										force majeure, de négligence ou de
										malveillance. Le client est responsable
										du strict respect des conditions de mise
										en œuvre de transformation et
										d’utilisation des produits.
									</p>
								</div>

								<div>
									<h3>Article 10 – Retard de paiement</h3>
									<p>
										En cas de retard de paiement, une
										pénalité égale à 3 fois le taux
										d’intérêt légal sera exigible (article
										L.441-6 du code de commerce). Pour tout
										professionnel, en sus des indemnités de
										retard, toute somme, y compris acompte,
										non payée à sa date d’éligibilité
										produira de plein droit l’application
										d’une indemnité pour frais de
										recouvrement dont le montant est fixé à
										40€ (article L.441-6, I al. 12 du code
										de commerce et D. 441-5 ibidem).
									</p>

									<p>
										À défaut d’exécution de ses obligations,
										dûment constatée par mise en demeure, le
										client sera tenu de supporter, outre les
										frais de justice y compris les faux
										frais et les débours de recouvrement,
										une clause pénale de 15% de la valeur de
										la facture, conformément aux
										dispositions de l’article 1226 du code
										civil.
									</p>
								</div>

								<div>
									<h3>Article 11 – Force majeure</h3>
									<p>
										Sont considérés comme cas de force
										majeure les évènements indépendants de
										la volonté des parties, qu’elles ne
										pouvaient ni prévoir, ni éviter ou
										surmonter, et dont la survenance rend
										impossible l’exécution des obligations.
										Sont notamment expressément assimilés à
										des cas de force majeure déchargeant
										Marne Enseignes de son obligation de
										produire ou livrer dans les délais
										initialement prévus : l’incendie,
										l’inondation, les grèves de production
										ou de transport, y compris de toute
										partie de son personnel, les pénuries de
										matière première, d’énergie et de moyens
										de transport.
									</p>

									<p>
										Dans de telles circonstances,
										l’exécution de toute commande sera
										suspendue de plein droit sans indemnité,
										à compter de sa date de survenance de
										l’évènement. Si l’évènement venait à
										durer plus de 30 jours, le contrat de
										vente conclu entre Marne Enseignes et le
										client pourra être résilié par la partie
										la plus diligente, sans qu’aucune des
										parties puisse prétendre à l’octroi des
										dommages et intérêts et Marne Enseignes
										restituera au client les acomptes versés
										au titre de ce contrat de vente.
									</p>
								</div>
								<div>
									<h3>Article 12 – Réserve de propriété</h3>
									<p>
										Toutes les livraisons sont effectuées
										sous réserve de propriété jusqu’au
										paiement intégral du prix en principal
										et accessoires.
									</p>

									<p>
										Lorsque Marne Enseignes exécute un
										travail impliquant une activité créative
										au sens du code de la Propriété
										Intellectuelle, les droits d’auteur en
										découlant et notamment le droit de
										reproduction lui restent acquis, sauf
										convention contraire expresse. La
										livraison et/ou remise de ses
										prestations au client n’entraîne en
										aucun cas cession des droits susvisés au
										profit de ce dernier, sauf stipulation
										contraire expresse et écrite que fera
										l’objet d’une facturation
										complémentaire.
									</p>
								</div>

								<div>
									<h3>Article 13 – Référencement</h3>
									<p>
										Le client autorise Marne Enseignes à
										citer son nom, son enseigne, son logo et
										à présenter les travaux réalisés pour ce
										dernier dans sa documentation
										commerciale, sa communication
										institutionnelle, son site Internet, son
										showroom et à l’occasion d’éventuels
										salons et manifestations diverses en
										relation directe avec Marne Enseignes.
									</p>
								</div>

								<div>
									<h3>Article 14 – Litiges</h3>
									<p>
										Tout litige relatif à la conclusion,
										l’interprétation, l’exécution ou la
										cession du présent contrat sera soumis,
										à défaut de résolution amiable, au
										Tribunal de Commerce de REIMS
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
