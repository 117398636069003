import React from "react"
import { stepPriorities, StepPriority } from "../../types/step"

export const SelectStepPriority = ({ name, onChange, value }) => (
	<select name={name} onChange={onChange} value={value}>
		<option value={"default"}>Priorité</option>

		{stepPriorities.map((stepPriority: StepPriority) => (
			<option key={stepPriority.key} value={stepPriority.priority}>
				{stepPriority.name}
			</option>
		))}
	</select>
)
