export const isDefined = (data) =>
	typeof data !== "undefined" ? data !== null : false
export const renderIfDefined = (data) => (isDefined(data) === true ? data : "")
export const renderIfDefinedParams = (data, param) =>
	isDefined(data) === true
		? isDefined(data[param]) === true
			? data[param]
			: ""
		: ""

export const getParams = () => {
	const pathname = window.location.pathname
	const split = pathname.split("/")

	return {
		path: split[1],
		id: split[2],
		action: split[3],
	}
}

export const transpose = (m) => {
	let max = -Infinity
	let index = -1
	m.forEach((a, i) => {
		if (a.length > max) {
			max = a.length
			index = i
		}
	})

	return m[index].map((x, i) => m.map((x) => x[i]))
}

export const arraysEqual = (a, b) => {
	if (a === b) return true
	if (a == null || b == null) return false
	if (a.length !== b.length) return false

	// If you don't care about the order of the elements inside
	// the array, you should sort both arrays here.
	// Please note that calling sort on an array will modify that array.
	// you might want to clone your array first.

	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false
	}
	return true
}

export const getHigherLength = (array) => {
	let length = 0

	array.forEach((el) => {
		length = el.length > length ? el.length : length
	})

	return length
}

export const sortByProperty = (array, property) => {
	return array.sort((a, b) => {
		if (a[property] < b[property]) {
			return -1
		}
		if (a[property] > b[property]) {
			return 1
		}
		return 0
	})
}

export const sortGroupProduct = (groups) => {
	const sorting = [
		"Produits",
		"Adhésif",
		"Frais techniques",
		"Sous traitance",
		"Fournitures",
		"Prestation",
		"Façonnage",
		"Pose",
		"Déplacement",
	]
	let result = []

	groups.forEach((group) => {
		result[sorting.indexOf(group.name)] = group
	})

	return result
}
