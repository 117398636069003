import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import "../css/security/notfound.scss"

class NoMatch extends Component {
	handleBack = () => {
		this.props.history.goBack()
	}

	render() {
		return (
			<div className={"notfound"}>
				<h1>404</h1>
				<p>La page que vous recherchez n'existe pas.</p>
				<div>
					<span className={"back"} onClick={this.handleBack}>
						Cliquez-ici
					</span>{" "}
					pour retourner sur l'application.
				</div>
			</div>
		)
	}
}

export default withRouter(NoMatch)
