import React, { useState } from "react"
import PropTypes from "prop-types"
import Autosuggest from "react-autosuggest"

import "../../css/components/autosuggest.scss"

export const AutoSuggestFilter = (props) => {
	const key = props.localName + "-filter"
	const [suggestions, setSuggestions] = useState([])

	const getSuggestions = (value) => {
		const inputValue = value.trim().toLowerCase()
		const inputLength = inputValue.length

		return inputLength === 0
			? []
			: props.items.filter((item) =>
					item !== null
						? item.toLowerCase().slice(0, inputLength) ===
						  inputValue
						: false
			  )
	}

	const getSuggestionValue = (suggestion) => suggestion

	const renderSuggestion = (suggestion, { query, isHighlighted }) => (
		<div style={{ background: isHighlighted ? "lightgray" : "white" }}>
			{suggestion}
		</div>
	)

	const onChangeFilter = (event, { newValue }) => {
		localStorage.setItem(key, newValue)
		props.onChange(newValue)
	}

	const onSuggestionsFetchRequested = ({ value }) =>
		setSuggestions(getSuggestions(value))

	const onSuggestionsClearRequested = () => setSuggestions([])

	const inputProps = {
		...props.inputProps,
		placeholder: props.placeholder,
		value: props.filter ? props.filter.value : "",
		onChange: onChangeFilter,
	}

	return (
		<Autosuggest
			suggestions={suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			getSuggestionValue={getSuggestionValue}
			renderSuggestion={renderSuggestion}
			inputProps={inputProps}
		/>
	)
}

AutoSuggestFilter.propTypes = {
	localName: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	items: PropTypes.array.isRequired,
	filter: PropTypes.object,
	placeholder: PropTypes.string,
}
