import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import ProductRowContainer from "./ProductRow.container"
import { isDefined } from "../../../utils/Data"
import ColorPickerList from "../../../components/ColorPickerList"
import { changeProduct } from "../../../redux/actions/forms/orderFormAction"

const OrdersFormList = ({ loadingProjects }) => {
	const dispatch = useDispatch()
	const productsOrders = useSelector(
		({ orderFormReducer }) => orderFormReducer.data.productsOrders
	)
	const { colors } = useSelector(({ apiReducer }) => apiReducer)

	const [articleOpenedColorPicker, setArticleOpenedColorPicker] =
		useState(null)
	const [showColorPicker, setShowColorPicker] = useState(false)

	const onChangeColor = (color) => {
		if (articleOpenedColorPicker)
			dispatch(
				changeProduct(articleOpenedColorPicker.id, "color", color.hex)
			)

		setShowColorPicker(false)
	}

	return (
		isDefined(productsOrders) &&
		productsOrders.length > 0 && (
			<>
				<ColorPickerList
					display={showColorPicker}
					handleClose={() => setShowColorPicker(false)}
					colors={colors.map((color) => color.hexa)}
					handleChange={(color) => onChangeColor(color)}
				/>

				<table className={"articles-list"}>
					<thead>
						<tr>
							<th>Référence</th>
							<th>Désignation</th>
							<th>Stock</th>
							<th>Format</th>
							<th>Surface</th>
							<th>Quantité</th>
							<th>Surface totale</th>
							<th>Prix unitaire</th>
							<th>Prix total</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{productsOrders
							.sort(
								(article, article2) =>
									article.position - article2.position
							)
							.map((article) => (
								<ProductRowContainer
									key={article?.id}
									article={article}
									loadingProjects={loadingProjects}
									setShowColorPicker={setShowColorPicker}
									setArticleOpenedColorPicker={
										setArticleOpenedColorPicker
									}
								/>
							))}
					</tbody>
				</table>
			</>
		)
	)
}

export default OrdersFormList
