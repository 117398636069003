import Swal from "sweetalert2"

export const onSaved = (
	redirection = null,
	title = null,
	message = "Votre travail a bien été sauvegardé !"
) => {
	Swal.fire({
		title: title,
		icon: "success",
		text: message,
		onAfterClose: () => {
			if (redirection !== null) window.location.href = redirection
		},
	})
}

export const onDelete = (
	title = null,
	message = "Etes-vous sur de vouloir supprimer cet élément ?",
	action
) => {
	Swal.fire({
		title: title,
		icon: "error",
		text: message,
		confirmButtonText: "Oui, supprimer",
		cancelButtonText: "Annuler",
		showCancelButton: true,
	}).then(async (result) => {
		if (result.value) {
			try {
				await action()
				Swal.fire({
					title: "Suppression réussie !",
					icon: "success",
				})
			} catch (e) {
				Swal.fire({
					title: "Suppression échouée !",
					icon: "error",
				})
			}
		} else {
			Swal.fire({
				title: "Suppression annulée",
			})
		}
	})
}

export const onConfirmation = (
	title = "Confirmation",
	message = "Etes-vous sur de vouloir effectuer cette action ?",
	action,
	titleSuccess = "Action effectuée !",
	titleCancel = "Action annulée"
) => {
	Swal.fire({
		title: title,
		icon: "question",
		text: message,
		confirmButtonText: "Oui",
		cancelButtonText: "Annuler",
		showCancelButton: true,
	}).then((result) => {
		if (result.value) {
			action()
			Swal.fire({
				title: titleSuccess,
				icon: "success",
			})
		} else {
			Swal.fire({
				title: titleCancel,
			})
		}
	})
}

export const onSuccessAlert = (
	message = "Succès !",
	title = null,
	redirection = null
) => {
	Swal.fire({
		title: title,
		icon: "success",
		text: message,
		onAfterClose: () => {
			if (redirection !== null) window.location.href = redirection
		},
	})
}

export const onErrorAlert = (
	message = "Erreur",
	title = null,
	redirection = null
) => {
	Swal.fire({
		title: title,
		icon: "error",
		text: message,
		onAfterClose: () => {
			if (redirection !== null) window.location.href = redirection
		},
	})
}

export const onDisconnected = () => {
	Swal.fire({
		title: "Vous êtes déconnecté",
		icon: "error",
		text: "Vous êtes resté trop longtemps connecté, par souci de sécurité, veuillez vous identifier de nouveau.",
		onAfterClose: () => {
			window.location.href = "/connexion"
		},
	})
}

export const onAlert = (option, callback) => {
	Swal.fire(option).then(callback)
}
