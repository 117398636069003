import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

Sentry.init({
	dsn: "https://123c9a461b834a7d9356dceb935d4b19@o576403.ingest.sentry.io/5918355",
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
})
