import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import { connect } from "react-redux"

import Login from "./Login"
import Projects from "../pages/projects/Projects"
import PrivateRoute from "./PrivateRoute"
import Dashboard from "../pages/Dashboard"
import NoMatch from "./NoMatch"
import Customers from "../pages/customers/Customers"
import Providers from "../pages/providers/Providers"
import Products from "../pages/products/Products"
import ProductTypes from "../pages/productTypes/ProductTypes"
import Users from "../pages/users/Users"
import Project from "../pages/projects/Project"
import Colors from "../pages/colors/Colors"
import ColorForm from "../pages/colors/ColorForm"
import Quotations from "../pages/quotations/Quotations"
import QuotationsForm from "../pages/quotations/QuotationsForm"
import UserForm from "../pages/users/UserForm"
import ProviderForm from "../pages/providers/ProviderForm"
import ProductTypesForm from "../pages/productTypes/ProductTypesForm"
import ProductForm from "../pages/products/ProductForm"
import CustomerForm from "../pages/customers/CustomerForm"
import ProjectForm from "../pages/projects/ProjectForm"
import Settings from "../pages/settings/Settings"
import WriteQuotation from "../pages/accounting/Writing/WriteQuotation"
import ArticleCategories from "../pages/articlesCategories/ArticleCategories"
import ArticleCategoriesForm from "../pages/articlesCategories/ArticleCategoriesForm"
import Articles from "../pages/articles/Articles"
import ArticlesForm from "../pages/articles/ArticlesForm"
import Bills from "../pages/bills/Bills"
import BillsForm from "../pages/bills/BillsForm"
import BillsNotes from "../pages/bills/BillsNotes"
import Messages from "../pages/messages/Messages"
import MessageForm from "../pages/messages/MessageForm"
import MessageView from "../pages/messages/MessageView"
import ActivityMonitoring from "../pages/activityMonitoring/ActivityMonitoring"
import Customer from "../pages/customers/Customer"
import Permissions from "../pages/permissions/Permissions"
import Role from "../pages/roles/Role"
import PermissionForm from "../pages/permissions/PermissionForm"
import BillsPrint from "../pages/bills/BillsPrint"
import Repertoire from "../pages/repertoire/Repertoire"
import RepertoireForm from "../pages/repertoire/RepertoireForm"
import Calendar from "../pages/calendar/Calendar"
import Orders from "../pages/orders/Orders"
import OrdersFormContainer from "../pages/orders/OrdersFormContainer"

class Router extends Component {
	render() {
		return (
			<Switch>
				<PrivateRoute
					exact
					path={"/projets"}
					component={<Projects />}
				/>
				<PrivateRoute
					exact
					path={"/projets/:id/modifier"}
					component={<ProjectForm />}
				/>
				<PrivateRoute
					exact
					path={"/projets/creer"}
					component={<ProjectForm />}
				/>
				<PrivateRoute
					exact
					path={"/projets/:id"}
					component={<Project />}
				/>

				<PrivateRoute
					exact
					path={"/devis"}
					component={<Quotations />}
				/>
				<PrivateRoute
					exact
					path={"/devis/creer"}
					component={<QuotationsForm />}
				/>
				<PrivateRoute
					exact
					path={"/devis/:id/modifier"}
					component={<QuotationsForm />}
				/>
				<PrivateRoute
					exact
					path={"/devis/:id/dupliquer"}
					component={<QuotationsForm />}
				/>
				<PrivateRoute
					exact
					path={"/devis/:id/rediger"}
					component={<WriteQuotation />}
				/>

				<PrivateRoute exact path={"/factures"} component={<Bills />} />
				<PrivateRoute
					exact
					path={"/factures/creer"}
					component={<BillsForm />}
				/>
				<PrivateRoute
					exact
					path={"/factures/:id/modifier"}
					component={<BillsForm />}
				/>
				<PrivateRoute
					exact
					path={"/factures/:id/dupliquer"}
					component={<BillsForm />}
				/>
				<PrivateRoute
					exact
					path={"/factures/:id/rediger"}
					component={<WriteQuotation />}
				/>
				<PrivateRoute
					exact
					path={"/factures/:id/devis"}
					component={<BillsForm />}
				/>
				<PrivateRoute
					exact
					path={"/factures/:id/notes"}
					component={<BillsNotes />}
				/>
				<PrivateRoute
					exact
					path={"/factures/imprimer"}
					component={<BillsPrint />}
				/>

				<PrivateRoute
					exact
					path={"/categories-articles"}
					component={<ArticleCategories />}
				/>
				<PrivateRoute
					exact
					path={"/categories-articles/creer"}
					component={<ArticleCategoriesForm />}
				/>
				<PrivateRoute
					exact
					path={"/categories-articles/:id/modifier"}
					component={<ArticleCategoriesForm />}
				/>

				<PrivateRoute
					exact
					path={"/articles"}
					component={<Articles />}
				/>
				<PrivateRoute
					exact
					path={"/articles/creer"}
					component={<ArticlesForm />}
				/>
				<PrivateRoute
					exact
					path={"/articles/:id/modifier"}
					component={<ArticlesForm />}
				/>

				<PrivateRoute
					exact
					path={"/fournisseurs"}
					component={<Providers />}
				/>
				<PrivateRoute
					exact
					path={"/fournisseurs/creer"}
					component={<ProviderForm />}
				/>
				<PrivateRoute
					exact
					path={"/fournisseurs/:id/modifier"}
					component={<ProviderForm />}
				/>

				<PrivateRoute
					exact
					path={"/commandes"}
					component={<Orders />}
				/>
				<PrivateRoute
					exact
					path={"/commandes/creer"}
					component={<OrdersFormContainer />}
				/>
				<PrivateRoute
					exact
					path={"/commandes/:id/modifier"}
					component={<OrdersFormContainer />}
				/>
				<PrivateRoute
					exact
					path={"/commandes/:id/dupliquer"}
					component={<OrdersFormContainer />}
				/>

				<PrivateRoute
					exact
					path={"/types-produits"}
					component={<ProductTypes />}
				/>
				<PrivateRoute
					exact
					path={"/types-produits/creer"}
					component={<ProductTypesForm />}
				/>
				<PrivateRoute
					exact
					path={"/types-produits/:id/modifier"}
					component={<ProductTypesForm />}
				/>

				<PrivateRoute
					exact
					path={"/produits"}
					component={<Products />}
				/>
				<PrivateRoute
					exact
					path={"/produits/creer"}
					component={<ProductForm />}
				/>
				<PrivateRoute
					exact
					path={"/produits/:id/modifier"}
					component={<ProductForm />}
				/>

				<PrivateRoute
					exact
					path={"/clients"}
					component={<Customers />}
				/>
				<PrivateRoute
					exact
					path={"/clients/creer"}
					component={<CustomerForm />}
				/>
				<PrivateRoute
					exact
					path={"/clients/:id/modifier"}
					component={<CustomerForm />}
				/>
				<PrivateRoute
					exact
					path={"/clients/:id"}
					component={<Customer />}
				/>

				<PrivateRoute
					exact
					path={"/utilisateurs"}
					component={<Users />}
				/>
				<PrivateRoute
					exact
					path={"/utilisateurs/:id/modifier"}
					component={<UserForm />}
				/>
				<PrivateRoute
					exact
					path={"/utilisateurs/creer"}
					component={<UserForm />}
				/>

				<PrivateRoute exact path={"/couleurs"} component={<Colors />} />
				<PrivateRoute
					exact
					path={"/couleurs/creer"}
					component={<ColorForm />}
				/>
				<PrivateRoute
					exact
					path={"/couleurs/:id/modifier"}
					component={<ColorForm />}
				/>

				<PrivateRoute
					exact
					path={"/parametres"}
					component={<Settings />}
				/>

				<PrivateRoute
					exact
					path={"/messages"}
					component={<Messages />}
				/>
				<PrivateRoute
					exact
					path={"/messages/creer"}
					component={<MessageForm />}
				/>
				<PrivateRoute
					exact
					path={"/messages/:id/modifier"}
					component={<MessageForm />}
				/>
				<PrivateRoute
					exact
					path={"/messages/:id/repondre"}
					component={<MessageForm />}
				/>
				<PrivateRoute
					exact
					path={"/messages/:id"}
					component={<MessageView />}
				/>
				<PrivateRoute
					exact
					path={"/projet/:id/message"}
					component={<MessageForm />}
				/>

				<PrivateRoute
					exact
					path={"/suivi-activite"}
					component={<ActivityMonitoring />}
				/>

				<PrivateRoute
					exact
					path={"/permissions"}
					component={<Permissions />}
				/>
				<PrivateRoute
					exact
					path={"/permissions/creer"}
					component={<PermissionForm />}
				/>
				<PrivateRoute
					exact
					path={"/permissions/:id/modifier"}
					component={<PermissionForm />}
				/>

				<PrivateRoute exact path={"/role/:id"} component={<Role />} />

				<PrivateRoute
					exact
					path={"/repertoire"}
					component={<Repertoire />}
				/>
				<PrivateRoute
					exact
					path={"/repertoire/creer"}
					component={<RepertoireForm />}
				/>
				<PrivateRoute
					exact
					path={"/repertoire/:id/modifier"}
					component={<RepertoireForm />}
				/>

				<PrivateRoute
					exact
					path={"/calendrier"}
					component={<Calendar />}
					isCalendar={true}
				/>

				<PrivateRoute exact path={"/"} component={<Dashboard />} />

				<Route exact path="/connexion" render={() => <Login />} />
				<Route component={NoMatch} />
			</Switch>
		)
	}
}

const mapStateToProps = (state) => {
	return { rawToken: state.rawToken }
}

export default connect(mapStateToProps)(Router)
