import React, { Component } from "react"
import { connect } from "react-redux"
import Settlement from "./card/Settlement"
import { withRouter } from "react-router-dom"
import DeliveryDelay from "./card/DeliveryDelay"
import Page from "../../components/Page/Page"
import StepSetting from "./card/StepSetting"

class Settings extends Component {
	resetSettings = () => {
		const jwt = localStorage.getItem("jwt")
		localStorage.clear()
		localStorage.setItem("jwt", jwt)
	}

	render() {
		return (
			<Page title={"Paramètres"} className={"project settings"}>
				<div className={"block"}>
					<button
						className={"block-button"}
						onClick={this.resetSettings}
					>
						Réinitiliser les paramètres
					</button>
				</div>

				<Settlement />
				<DeliveryDelay />
				<StepSetting />
			</Page>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return { rawToken: apiReducer.rawToken }
}

export default withRouter(connect(mapStateToProps)(Settings))
