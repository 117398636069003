import React from "react"
import { useSelector } from "react-redux"

import "../../css/form.scss"
import "../../css/pages/orders/form.scss"
import { isDefined } from "../../utils/Data"
import Page from "../../components/Page/Page"
import { MyAutosuggest } from "../../components/MyAutosuggest"
import OrdersFormList from "./form/OrdersFormList"
import Buttons from "./form/Buttons"

const OrdersForm = ({
	handleSubmit,
	onChange,
	onChangeProvider,
	loadingProviders,
	loadingProjects,
	total,
	addProduct,
}) => {
	const { providers } = useSelector(({ apiReducer }) => apiReducer)
	const reducer = useSelector(({ orderFormReducer }) => orderFormReducer)
	const { data } = reducer

	return (
		<Page
			title={"COMMANDE_" + data.number + "_" + data.ordersDate}
			className={"orders-form"}
		>
			<form onSubmit={handleSubmit}>
				<div className={"form-block"}>
					<h2 className={"form-block-title"}>
						Informations de la commande
					</h2>

					<div className={"form-group row"}>
						<div className={"col-12 col-md-6 col-xl-1"}>
							<label>N° commande</label>
							<input
								type={"text"}
								placeholder={"N° commande"}
								className={"form-control"}
								name={"number"}
								value={data.number}
								onChange={onChange}
							/>
						</div>
						<div className={"col-12 col-md-6 col-xl-2"}>
							<label>Date de la commande</label>
							<input
								type={"date"}
								placeholder={"Date de la commande"}
								className={"form-control"}
								name={"ordersDate"}
								value={data.ordersDate}
								onChange={onChange}
							/>
						</div>
						<div className={"col-12 col-md-6 col-xl-2"}>
							<label>Fournisseur</label>
							<MyAutosuggest
								disabled={loadingProviders}
								searchWithoutName={true}
								items={providers.map((item) => item.name)}
								name={"provider"}
								placeholder={
									loadingProviders
										? "Chargement ..."
										: "Fournisseur"
								}
								value={data.provider}
								onChange={onChangeProvider}
								required={true}
								className={
									"form-control " +
									(isDefined(data.provider)
										? "is-valid"
										: "is-invalid")
								}
							/>
						</div>
						<div className={"col-12 col-md-6 col-xl-2"}>
							<label>Adresse de livraison</label>
							<select
								name={"delivery"}
								value={data.delivery}
								onChange={onChange}
								className={"form-control"}
							>
								<option value={false}>Reims</option>
								<option value={true}>
									Saint Martin sur le Pré
								</option>
							</select>
						</div>
						<div className={"col-12 col-md-6 col-xl-2"}>
							<label>Utilisateur</label>
							<input
								type={"text"}
								className={"form-control"}
								name={"user"}
								value={
									isDefined(reducer.user)
										? reducer.user.firstname
										: ""
								}
								disabled={true}
							/>
						</div>
						<div className={"col-12 col-md-6 col-xl-1"}>
							<label>Montant total</label>
							<input
								type={"text"}
								placeholder={"Montant total"}
								className={"form-control"}
								name={"amount"}
								value={parseFloat(total).toFixed(2)}
								disabled={true}
							/>
						</div>
					</div>
					<div className={"form-group row"}>
						<div className={"col-md-12 col-xl-6"}>
							<label>Intitulé</label>
							<input
								type={"text"}
								placeholder={"Intitulé de la commande"}
								className={"form-control"}
								name={"title"}
								maxLength={250}
								value={data.title}
								onChange={onChange}
							/>
						</div>
					</div>
				</div>

				<div className={"form-block"}>
					<h2 className={"form-block-title"}>Articles à commander</h2>

					<button onClick={() => addProduct()} type={"button"}>
						Ajouter un article
					</button>

					<OrdersFormList loadingProjects={loadingProjects} />
				</div>

				<Buttons />
			</form>
		</Page>
	)
}

export default OrdersForm
