import { createResource, resetResource, updateResource } from "../../action"
import { ActionTypes } from "../../action.types"

const { ERROR } = ActionTypes

export const CHANGE_PROPERTY = "CHANGE_PROPERTY",
	RESET = "RESET"

export const changeProperty = (name, value) => (dispatch) => {
	dispatch({
		type: CHANGE_PROPERTY,
		name: name,
		value: value,
	})
}

export const resourceToForm = (resource) => (dispatch) =>
	Object.keys(resource).forEach((property) =>
		dispatch(changeProperty(property, resource[property]))
	)

export const submitAddress =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		if (data.address.id !== undefined && data.address.id !== null) {
			try {
				await dispatch(
					updateResource(
						"adresses",
						data.address.id,
						data.address,
						rawToken
					)
				).then((address) => callback(address))
			} catch (e) {}
		} else {
			try {
				await dispatch(
					createResource("adresses", data.address, rawToken)
				).then((address) => callback(address))
			} catch (e) {}
		}
	}

export const submit =
	(id, data, rawToken, callback = null) =>
	(dispatch) => {
		try {
			if (id === "creer") {
				dispatch(
					submitAddress(id, data, rawToken, (address) => {
						data.address =
							address !== undefined
								? (data.address = address.id)
								: null
						dispatch(submitCreate(id, data, rawToken, callback))
					})
				)
			} else {
				dispatch(
					submitAddress(id, data, rawToken, (address) => {
						data.address =
							address !== undefined
								? (data.address = address.id)
								: null
						dispatch(submitUpdate(id, data, rawToken, callback))
					})
				)
			}
		} catch (e) {
			dispatch({
				type: ERROR,
				e,
			})
		}
	}

export const submitCreate =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		try {
			await dispatch(
				createResource("repertoireContacts", data, rawToken)
			).then(() => {
				if (callback !== null) callback()
			})
		} catch (e) {}
	}

export const submitUpdate =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		try {
			await dispatch(
				updateResource("repertoireContacts", id, data, rawToken)
			).then(() => {
				if (callback !== null) callback()
			})
		} catch (e) {}
	}

export const reset = () => (dispatch) => {
	dispatch({ type: RESET })
	dispatch(resetResource())
}
