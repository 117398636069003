import React, { Component } from "react"
import Autosuggest from "react-autosuggest"

import "../css/pages/products/form.scss"
import "../css/components/autosuggest.scss"

export class MyAutosuggest extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: this.props.value,
			suggestions: [],
		}
	}

	getSuggestions = (value) => {
		const inputValue = value.trim().toLowerCase()
		const inputLength = inputValue.length

		return inputLength === 0 || !this.props.items
			? []
			: this.props.items.filter((item) =>
					item !== null
						? this.props.searchWithoutName === true
							? item.toLowerCase().slice(0, inputLength) ===
							  inputValue
							: item.name.toLowerCase().slice(0, inputLength) ===
							  inputValue
						: false
			  )
	}

	getSuggestionValue = (suggestion) =>
		this.props.searchWithoutName === true ? suggestion : suggestion.name

	renderSuggestion = (suggestion, { query, isHighlighted }) => (
		<div style={{ background: isHighlighted ? "lightgray" : "white" }}>
			{this.props.searchWithoutName === true
				? suggestion
				: suggestion.name}
		</div>
	)

	onChange = (event, { newValue }) => {
		this.props.onChange(event, this.props.name, newValue)
		this.setState({
			value: newValue,
		})
	}

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			suggestions: this.getSuggestions(value),
		})
	}

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		})
	}

	render() {
		const inputProps = {
			...this.props.inputProps,
			placeholder: this.props.placeholder,
			name: this.props.name,
			required: this.props.required,
			disabled: this.props.disabled,
			value:
				(this.state.value === null || this.state.value === "") &&
				this.props.value !== "" &&
				this.props.value !== undefined
					? this.props.value
					: this.state.value,
			onChange: this.onChange,
			className: this.props.className,
		}

		return (
			<Autosuggest
				suggestions={this.state.suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
			/>
		)
	}
}
