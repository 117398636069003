import React from "react"
import CKEditor from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import MyModal from "../../../components/MyModal"
import ArticlesList from "../../articles/ArticlesList"
import { MyAutosuggest } from "../../../components/MyAutosuggest"

interface IProps {
	modalIsOpen: boolean
	handleOpenCloseModal: () => void
	handleAdd: (id: number) => void
	onDrag: (up?: boolean) => void
	numbers: any
	row: any
	changeCode: (event: any, newName: string, newValue: any) => void
	changeRow: any
	onDelete: () => void
}

export const WriteRowView = ({
	modalIsOpen,
	handleOpenCloseModal,
	handleAdd,
	onDrag,
	numbers,
	row,
	changeCode,
	changeRow,
	onDelete,
}: IProps) => {
	return (
		<tr className={"article"}>
			<td className={"code"}>
				<MyModal
					isOpen={modalIsOpen}
					handleClose={() => handleOpenCloseModal()}
					title={"Catalogue des articles"}
					content={
						<ArticlesList
							addButton={true}
							handleAdd={(id) => handleAdd(id)}
						/>
					}
					className={"products-modal"}
				/>
				<div
					className={"btn-circle btn-secondary btn-drag drag-up"}
					title={"Déplacer vers le haut"}
					onClick={() => onDrag()}
				>
					<i className="fas fa-arrow-up" />
				</div>
				<div
					className={"btn-circle btn-secondary btn-drag drag-down"}
					title={"Déplacer vers le bas"}
					onClick={() => onDrag(false)}
				>
					<i className="fas fa-arrow-down" />
				</div>
				<div
					className={"btn-circle btn-products btn-warning"}
					title={"Ouvrir le catalogue"}
					onClick={handleOpenCloseModal}
				>
					<i className="fas fa-book" />
				</div>
				<MyAutosuggest
					searchWithoutName={true}
					items={numbers}
					name={"code"}
					placeholder={"Code"}
					value={row.data.code}
					onChange={changeCode}
				/>
			</td>
			<td>
				{
					<CKEditor
						editor={ClassicEditor}
						data={row.data.text}
						onInit={(editor) => {
							editor.setData(row.data.text)
						}}
						onChange={(event, editor) => {
							const data = editor.getData()
							changeRow(null, "text", data)
						}}
					/>
				}
			</td>
			<td>
				<input
					type={"text"}
					name={"quantity"}
					className={"little-cell"}
					value={row.data.quantity}
					onChange={changeRow}
				/>
			</td>
			<td>
				<input
					type={"text"}
					name={"unitPrice"}
					className={"little-cell"}
					value={row.data.unitPrice}
					onChange={changeRow}
				/>
			</td>
			<td>
				<input
					type={"number"}
					name={"sellingPrice"}
					className={"little-cell"}
					value={row.data.sellingPrice}
					onChange={changeRow}
					disabled={true}
				/>
			</td>
			<td className={"actions"}>
				<div
					className={"deleteContact btn-circle btn-danger"}
					title={"Supprimer cette ligne"}
					onClick={onDelete}
				>
					<i className="fas fa-trash-alt" />
				</div>
			</td>
		</tr>
	)
}
