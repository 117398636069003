import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { usePrevious } from "../hooks/usePrevious"

export const TextFilter = ({ localName, onChange, filter }) => {
	const key = localName + "-filter"
	const localStorageValue = localStorage.getItem(key)
	const [inputValue, setInputValue] = useState(filter ? filter.value : "")
	const previousInputValue = usePrevious(inputValue)

	useEffect(() => {
		setInputValue(filter ? filter.value : "")
	}, [])

	useEffect(() => {
		if (
			typeof previousInputValue !== "undefined" &&
			typeof localStorageValue !== "undefined" &&
			localStorageValue !== null
		) {
			const timeout = setTimeout(() => onChange(inputValue), 0.5 * 1000)
			return () => clearTimeout(timeout)
		}
	}, [inputValue])

	useEffect(() => {
		if (
			typeof localStorageValue === "undefined" ||
			localStorageValue === null
		)
			setInputValue("")
	}, [localStorageValue])

	const onChangeFilter = (event) => {
		const value = event.target.value
		setInputValue(value)
		localStorage.setItem(key, value)
	}

	return (
		<input
			type={"text"}
			style={{ width: "100%" }}
			onChange={(e) => onChangeFilter(e)}
			value={inputValue}
		/>
	)
}

TextFilter.propTypes = {
	localName: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.object,
}
