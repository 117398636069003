import { ActionTypes } from "./action.types"

const { ERROR } = ActionTypes

export const TOGGLE_SIDENAV = "TOGGLE_SIDENAV"

export const toggleSidenav =
	(open = null) =>
	async (dispatch) => {
		try {
			dispatch({
				type: TOGGLE_SIDENAV,
				open: open,
			})
		} catch (e) {
			dispatch({
				type: ERROR,
				e,
			})
		}
	}
