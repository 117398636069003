import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import {
	addChildToParent,
	deleteResource,
	getResources,
} from "../../redux/action"
import Table from "../../components/Table"
import { getColumnsWidth, getDefaultFiltered } from "../../utils/List"
import { TextFilter } from "../../components/List/index"
import Page from "../../components/Page/Page"
import ColorPickerList from "../../components/ColorPickerList"
import { onDelete } from "../../utils/Alert"

class Users extends Component {
	constructor(props) {
		super(props)

		this.frenchName = "utilisateurs"
		this.localName = "users"
		this.apiName = "users"

		this.localNames = ["firstname", "lastname", "email", "phone", "role"]
		this.localNames = this.localNames.map(
			(name) => this.localName + "-" + name
		)

		this.state = {
			rowOpenColor: null,
			displayColorPicker: false,
			columns: [
				{
					Header: "Prénom",
					accessor: "firstname",
					width: getColumnsWidth(this.localNames[0], 350),
				},
				{
					Header: "Nom",
					accessor: "lastname",
					width: getColumnsWidth(this.localNames[1], 350),
				},
				{
					Header: "Adresse mail",
					accessor: "email",
					width: getColumnsWidth(this.localNames[2], 500),
				},
				{
					Header: "Téléphone",
					accessor: "phone",
					width: getColumnsWidth(this.localNames[3], 230),
				},
				{
					Header: "Rôle",
					accessor: "role.description",
					sortable: false,
					filterable: false,
					width: getColumnsWidth(this.localNames[4], 180),
				},
				{
					Header: "Actions",
					accessor: "actions",
					sortable: false,
					resizable: false,
					width: 150,
					Cell: ({ row }) => (
						<div className={"btn-flex"}>
							<div
								className="btn-circle btn-color"
								title={"Mettre en couleur"}
								onClick={() =>
									this.handleColor(row._original.id)
								}
							>
								<i className="fa fa-tint" />
							</div>
							<Link
								to={
									"/utilisateurs/" +
									row._original.id +
									"/modifier"
								}
							>
								<div
									className="btn-circle btn-warning"
									title={"Modifier"}
								>
									<i className="fa fa-edit" />
								</div>
							</Link>
							<div
								className="btn-circle btn-danger"
								title={"Supprimer"}
								onClick={() =>
									this.handleDelete(row._original.id)
								}
							>
								<i className="fa fa-trash" />
							</div>
						</div>
					),
				},
			],
		}
	}

	componentDidMount() {
		this.props.dispatch(getResources("colors", this.props.rawToken))
		this.state.columns.forEach((column, i) => {
			if (i < this.state.columns.length)
				column.Filter = ({ filter, onChange }) => (
					<TextFilter
						localName={this.localNames[i]}
						filter={filter}
						onChange={onChange}
					/>
				)
		})
	}

	handleDelete = (id) =>
		onDelete(
			"",
			"Etes-vous sûr de vouloir supprimer cet utilisateur ?",
			() =>
				this.props.dispatch(
					deleteResource("users", id, this.props.rawToken)
				)
		)

	handleColor = (id = null) => {
		this.setState((state) => {
			return {
				rowOpenColor: id,
				displayColorPicker: !state.displayColorPicker,
			}
		})
	}

	selectColor = (color = null) => {
		const colorApi = this.props.colors.find(
			(item) => item.hexa === color.hex
		)
		if (typeof colorApi !== "undefined")
			this.props.dispatch(
				addChildToParent(
					this.state.rowOpenColor,
					colorApi.id,
					"users",
					"colors",
					this.props.rawToken
				)
			)
		this.handleColor()
	}

	buttonsRight = () => (
		<Link to={"/utilisateurs/creer"}>
			<button className={"btn-circle add-resource"} title={"Ajouter"}>
				<i className={"fas fa-plus"} />
			</button>
		</Link>
	)

	sortColor = () =>
		this.props.colors.sort((c, c2) => {
			if (c.name > c2.name) return 1
			else if (c.name < c2.name) return -1
			else return 0
		})

	render() {
		return (
			<Page
				siteTitle={"Utilisateurs"}
				title={"Gestion des utilisateurs"}
				headerButtons={this.buttonsRight()}
				className={"users"}
			>
				<ColorPickerList
					display={this.state.displayColorPicker}
					handleClose={() => this.handleColor()}
					colors={this.sortColor().map((color) => color.hexa)}
					handleChange={(color) => this.selectColor(color)}
				/>
				<Table
					name={this.localName + "-list"}
					data={this.props.users}
					columns={this.state.columns}
					frenchName={this.frenchName}
					localName={this.localName}
					apiName={this.apiName}
					defaultFiltered={getDefaultFiltered(
						this.localName,
						this.state.columns
					)}
					defaultSorted={[
						{
							id: "firstname",
							desc: false,
						},
					]}
				/>
			</Page>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		users: apiReducer.users,
		colors: apiReducer.colors,
	}
}

export default connect(mapStateToProps)(Users)
