export enum ActionTypes {
	ERROR = "ERROR",
	GET_TOKEN = "GET_TOKEN",
	GET_USER = "GET_USER",
	LOGOUT = "LOGOUT",
	GET_MESSAGES_OF_USER = "GET_MESSAGES_OF_USER",

	GET_RESOURCES = "GET_RESOURCES",
	CREATE_RESOURCE = "CREATE_RESOURCE",
	GET_RESOURCE = "GET_RESOURCE",
	UPDATE_RESOURCE = "UPDATE_RESOURCE",
	DELETE_RESOURCE = "DELETE_RESOURCE",
	GET_USERS = "GET_USERS",
	GET_NEW_NUM = "GET_NEW_NUM",
	GET_NUMBERS = "GET_NUMBERS",
	GET_RESOURCE_BY_NUMBER = "GET_RESOURCE_BY_NUMBER",
	RESET_RESOURCE = "RESET_RESOURCE",
	CHECK_UNIQ_NUMBER = "CHECK_UNIQ_NUMBER",
}
