import { ActionTypes } from "./action.types"

const {
	ERROR,
	GET_TOKEN,
	GET_USER,
	LOGOUT,
	GET_MESSAGES_OF_USER,

	GET_RESOURCES,
	CREATE_RESOURCE,
	GET_RESOURCE,
	UPDATE_RESOURCE,
	DELETE_RESOURCE,
	GET_NEW_NUM,
	GET_NUMBERS,
	RESET_RESOURCE,
	CHECK_UNIQ_NUMBER,
} = ActionTypes

export const getToken = (username, password) => async (dispatch) => {
	try {
		const url = process.env.REACT_APP_API_URI + "/login_check"
		const res = await fetch(url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username: username,
				password: password,
			}),
		})

		const data = await res.json()

		if (res.ok) {
			const jwt = decodeJWT(data.token)
			localStorage.setItem("jwt", JSON.stringify(jwt))

			dispatch({
				type: GET_TOKEN,
				payload: jwt.payload,
				rawToken: jwt.rawToken,
			})

			return {
				ok: true,
			}
		} else {
			return {
				ok: false,
				message:
					data.message === "Bad credentials."
						? "Mauvais identifiants"
						: "Erreur lors de la connexion à la base de données",
			}
		}
	} catch (e) {
		dispatch({
			type: ERROR,
			message: e,
		})

		return {
			ok: false,
			message: e.message,
		}
	}
}

export const getUser = (id, rawToken) => async (dispatch) => {
	try {
		const res = await fetch(
			process.env.REACT_APP_API_URI + "/users/" + id,
			{
				headers: {
					Authorization: "Bearer " + rawToken,
				},
			}
		)

		const data = await res.json()

		if (res.ok) {
			dispatch({ type: GET_USER, user: data })
			return {
				ok: true,
			}
		} else {
			dispatch({ type: ERROR, error: res.status })
			return {
				ok: false,
				message: data.message,
			}
		}
	} catch (e) {
		dispatch({ type: ERROR, e })
	}
}

export const logout = () => async (dispatch) => {
	try {
		localStorage.removeItem("jwt")
		dispatch({ type: LOGOUT })
	} catch (e) {
		dispatch({ type: ERROR, e })
	}
}

export const getResourcesWithPages =
	(
		name,
		rawToken,
		page = 1,
		pageSize = "20",
		sorted = "",
		direction = "asc",
		filtered = ""
	) =>
	async (dispatch) => {
		try {
			const res = await fetch(
				process.env.REACT_APP_API_URI +
					"/search_" +
					name +
					"?page=" +
					page +
					"&limit=" +
					pageSize +
					"&order=" +
					sorted +
					"&direction=" +
					direction +
					"&search=" +
					filtered,
				{
					headers: {
						Authorization: "Bearer " + rawToken,
					},
				}
			)

			if (name === "book_products") name = "products"
			else if (name === "book_articles") name = "articles"

			const data = await res.json()

			if (res.ok) {
				const pageS = parseInt(pageSize) || 20

				dispatch({
					type: GET_RESOURCES,
					name: name,
					resources: data["hydra:member"],
					pages: Math.ceil(
						parseInt(data["hydra:totalItems"]) / pageS
					),
				})

				return {
					ok: true,
				}
			} else {
				dispatch({ type: ERROR, error: res.status })
				return {
					ok: false,
					message: data.message,
				}
			}
		} catch (e) {
			dispatch({ type: ERROR, e })
		}
	}

export const getResources =
	(name, rawToken, pagination = false) =>
	async (dispatch) => {
		try {
			const res = await fetch(
				process.env.REACT_APP_API_URI +
					"/" +
					name +
					"?pagination=" +
					pagination,
				{
					headers: {
						Authorization: "Bearer " + rawToken,
					},
				}
			)

			if (name === "stepsOfWeek") name = "steps"
			else if (name === "book_products") name = "products"
			else if (name === "book_articles") name = "articles"

			const data = await res.json()

			if (res.ok) {
				dispatch({
					type: GET_RESOURCES,
					name: name,
					resources: data["hydra:member"],
				})

				return {
					ok: true,
				}
			} else {
				dispatch({ type: ERROR, error: res.status })

				return {
					ok: false,
					message: data.message,
				}
			}
		} catch (e) {
			dispatch({ type: ERROR, e })
		}
	}

export const createResource = (name, body, rawToken) => async (dispatch) => {
	try {
		const url = process.env.REACT_APP_API_URI + "/" + name
		const res = await fetch(url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + rawToken,
			},
			body: JSON.stringify(body),
		})

		const data = await res.json()

		if (res.ok) {
			dispatch({
				type: CREATE_RESOURCE,
				name: name,
				data: data,
			})

			return data
		} else {
			return {
				ok: false,
				message: data.message,
			}
		}
	} catch (e) {
		dispatch({
			type: ERROR,
			e,
		})
		throw new Error(e)
	}
}

export const getResource = (name, id, rawToken) => async (dispatch) => {
	try {
		const url = process.env.REACT_APP_API_URI + "/" + name + "/" + id
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + rawToken,
			},
		})

		if (res.ok) {
			const data = await res.json()

			dispatch({
				type: GET_RESOURCE,
				name: name,
				data: data,
			})

			return data
		}
		return null
	} catch (e) {
		dispatch({
			type: ERROR,
			e,
		})
	}
}

export const updateResource =
	(name, id, body, rawToken) => async (dispatch) => {
		try {
			const url = process.env.REACT_APP_API_URI + "/" + name + "/" + id
			const res = await fetch(url, {
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + rawToken,
				},
				body: JSON.stringify(body),
			})

			if (res.ok) {
				const data = await res.json()

				dispatch({
					type: UPDATE_RESOURCE,
					name: name,
					data: data,
					id: id,
				})

				return data
			} else throw new Error()
		} catch (e) {
			dispatch({
				type: ERROR,
				e,
			})
			throw new Error(e)
		}
	}

export const deleteResource = (name, id, rawToken) => async (dispatch) => {
	try {
		const url = process.env.REACT_APP_API_URI + "/" + name + "/" + id
		const res = await fetch(url, {
			method: "DELETE",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + rawToken,
			},
		})

		if (res.ok) {
			dispatch({
				type: DELETE_RESOURCE,
				name: name,
				id: id,
			})

			return true
		} else {
			throw new Error()
		}
	} catch (e) {
		dispatch({
			type: ERROR,
			e,
		})
		throw new Error(e)
	}
}

export const specialPut = (id, rawToken, name, action) => async (dispatch) => {
	try {
		const res = await fetch(
			process.env.REACT_APP_API_URI +
				"/" +
				name +
				"/" +
				id +
				"/" +
				action,
			{
				method: "PUT",
				headers: {
					Authorization: "Bearer " + rawToken,
				},
			}
		)

		if (res.ok) {
			const data = await res.json()
			dispatch({ type: UPDATE_RESOURCE, name: name, id: id, data: data })
			return data
		} else {
			dispatch({ type: ERROR, error: res.status })
		}
	} catch (e) {
		dispatch({ type: ERROR, e })
	}
}

export const getNewNum = (name, rawToken) => async (dispatch) => {
	try {
		const res = await fetch(
			process.env.REACT_APP_API_URI + "/getNewNum/" + name,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + rawToken,
				},
			}
		)

		if (res.ok) {
			const data = await res.json()
			dispatch({ type: GET_NEW_NUM, number: data })
			return data
		} else {
			dispatch({ type: ERROR, error: res.status })
		}
	} catch (e) {
		dispatch({ type: ERROR, e })
	}
}

export const getNewNumWrited = (name, rawToken) => async (dispatch) => {
	try {
		const res = await fetch(
			process.env.REACT_APP_API_URI + "/getNewNumWrited/" + name,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + rawToken,
				},
			}
		)

		if (res.ok) {
			const data = await res.json()
			dispatch({ type: GET_NEW_NUM, number: data })
		} else {
			dispatch({ type: ERROR, error: res.status })
		}
	} catch (e) {
		dispatch({ type: ERROR, e })
	}
}

export const getNewNumModel = (name, rawToken) => async (dispatch) => {
	try {
		const res = await fetch(
			process.env.REACT_APP_API_URI + "/getNewNumModel/" + name,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + rawToken,
				},
			}
		)

		if (res.ok) {
			const data = await res.json()
			dispatch({ type: GET_NEW_NUM, number: data })
		} else {
			dispatch({ type: ERROR, error: res.status })
		}
	} catch (e) {
		dispatch({ type: ERROR, e })
	}
}

export const addContactToCustomer =
	(idCustomer, idContact, rawToken) => async (dispatch) => {
		try {
			const res = await fetch(
				process.env.REACT_APP_API_URI +
					"/customers/" +
					idCustomer +
					"/contacts/" +
					idContact,
				{
					method: "PUT",
					headers: {
						Authorization: "Bearer " + rawToken,
					},
				}
			)

			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: UPDATE_RESOURCE,
					name: "customers",
					id: idCustomer,
					data: data,
				})
			} else {
				dispatch({ type: ERROR, error: res.status })
			}
		} catch (e) {
			dispatch({ type: ERROR, e })
		}
	}

export const addChildToParent =
	(parentId, childId, parentName, childName, rawToken) =>
	async (dispatch) => {
		try {
			const res = await fetch(
				process.env.REACT_APP_API_URI +
					"/" +
					parentName +
					"/" +
					parentId +
					"/" +
					childName +
					"/" +
					childId,
				{
					method: "PUT",
					headers: {
						Authorization: "Bearer " + rawToken,
					},
				}
			)

			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: UPDATE_RESOURCE,
					name: parentName,
					id: parentId,
					data: data,
				})
			} else {
				dispatch({ type: ERROR, error: res.status })
			}
		} catch (e) {
			dispatch({ type: ERROR, e })
		}
	}

export const getResourcesByProperty =
	(name, property, rawToken) => async (dispatch) => {
		try {
			const res = await fetch(
				process.env.REACT_APP_API_URI +
					"/" +
					name +
					"/list-" +
					property,
				{
					headers: {
						Authorization: "Bearer " + rawToken,
					},
				}
			)

			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: GET_NUMBERS,
					name: name,
					property: property,
					resources: data["hydra:member"],
				})
			} else {
				dispatch({ type: ERROR, error: res.status })
			}
		} catch (e) {
			dispatch({ type: ERROR, e })
		}
	}

export const getResourceByProperty = async (
	name,
	property,
	value,
	rawToken
) => {
	try {
		const url =
			process.env.REACT_APP_API_URI +
			"/" +
			name +
			"/property/" +
			property +
			"/" +
			value
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + rawToken,
			},
		})

		if (res.ok) {
			const data = await res.json()
			return data.resource
		}
	} catch (e) {
		return null
	}
}

export const getMessagesOfUser = (id, rawToken) => async (dispatch) => {
	try {
		const url = process.env.REACT_APP_API_URI + "/users/" + id + "/messages"
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + rawToken,
			},
		})

		if (res.ok) {
			const data = await res.json()

			dispatch({
				type: GET_MESSAGES_OF_USER,
				data: data,
			})

			return data
		}
		return null
	} catch (e) {
		dispatch({
			type: ERROR,
			e,
		})
	}
}

export const resetResource = () => async (dispatch) =>
	dispatch({ type: RESET_RESOURCE })

function decodeJWT(raw) {
	const parts = raw.split(".")

	return {
		rawToken: raw,
		headers: JSON.parse(atob(parts[0])),
		payload: JSON.parse(atob(parts[1])),
		signature: parts[2],
	}
}

export const checkUniqNumber =
	(name, number, rawToken, model = false, drafted = null) =>
	async (dispatch) => {
		try {
			if (name === "bills") {
				const array = number.split("/")
				number = array[0] + "-" + array[1]
			}

			const url =
				process.env.REACT_APP_API_URI +
				"/" +
				name +
				"/checkUniqNumber/" +
				number +
				"/" +
				model +
				"/" +
				(name === "quotations" ? drafted : "")
			const res = await fetch(url, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + rawToken,
				},
			})

			if (res.ok) {
				const data = await res.json()

				dispatch({
					type: CHECK_UNIQ_NUMBER,
					name,
				})

				return data
			}
			return false
		} catch (e) {
			dispatch({
				type: ERROR,
				e,
			})
		}
	}

export const getSubResources =
	(id, resource, subResource, rawToken) => async (dispatch) => {
		try {
			const url =
				process.env.REACT_APP_API_URI +
				"/" +
				resource +
				"/" +
				id +
				"/" +
				subResource
			const res = await fetch(url, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + rawToken,
				},
			})

			if (res.ok) {
				const data = await res.json()

				dispatch({
					type: GET_RESOURCES,
					data: data,
				})

				return data
			}
			return null
		} catch (e) {
			dispatch({
				type: ERROR,
				e,
			})
		}
	}

export const getResourcesModels = (name, rawToken) => async (dispatch) => {
	try {
		const res = await fetch(
			process.env.REACT_APP_API_URI + "/" + name + "/models",
			{
				headers: {
					Authorization: "Bearer " + rawToken,
				},
			}
		)

		const data = await res.json()

		if (res.ok) {
			dispatch({
				type: GET_RESOURCES,
				name: name,
				resources: data["hydra:member"],
			})

			return {
				ok: true,
			}
		} else {
			dispatch({ type: ERROR, error: res.status })

			return {
				ok: false,
				message: data.message,
			}
		}
	} catch (e) {
		dispatch({ type: ERROR, e })
	}
}
