import React, { useState } from "react"
import PropTypes from "prop-types"
import Autosuggest from "react-autosuggest"

import "./index.scss"

const AutoSuggest = (props) => {
	const [value, setValue] = useState(props.value)
	const [suggestions, setSuggestions] = useState([])

	const getSuggestions = (value) => {
		const inputValue = value.trim().toLowerCase()
		const inputLength = inputValue.length

		return inputLength === 0
			? []
			: props.items.filter((item) =>
					item !== null
						? item.toLowerCase().slice(0, inputLength) ===
						  inputValue
						: false
			  )
	}

	const getSuggestionValue = (suggestion) => suggestion

	const renderSuggestion = (suggestion, { query, isHighlighted }) => (
		<div style={{ background: isHighlighted ? "lightgray" : "white" }}>
			{suggestion}
		</div>
	)

	const onChange = (event, { newValue }) => {
		props.onChange(event, props.name, newValue)
		setValue(newValue)
	}

	const onSuggestionsFetchRequested = ({ value }) =>
		setSuggestions(getSuggestions(value))

	const onSuggestionsClearRequested = () => setSuggestions([])

	const inputProps = {
		...props.inputProps,
		placeholder: props.placeholder,
		name: props.name,
		required: props.required,
		disabled: props.disabled,
		value:
			(value === null || value === "") &&
			props.value !== "" &&
			props.value !== undefined
				? props.value
				: value,
		onChange: onChange,
	}

	return (
		<Autosuggest
			suggestions={suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			getSuggestionValue={getSuggestionValue}
			renderSuggestion={renderSuggestion}
			inputProps={inputProps}
		/>
	)
}

AutoSuggest.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
}

export default AutoSuggest
