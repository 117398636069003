import React, { Component } from "react"
import { connect } from "react-redux"
import { getResources } from "../../redux/action"
import { isDefined } from "../../utils/Data"

class CalendarShowUser extends Component {
	componentDidMount() {
		this.props.dispatch(getResources("users", this.props.rawToken))

		if (isDefined(localStorage.getItem("calender-userSelected")))
			this.props.toggleSelectUser(
				localStorage.getItem("calender-userSelected")
			)
	}

	toggleSelectUser = (e) => {
		const user = e.target.value
		this.props.toggleSelectUser(user)
		localStorage.setItem("calender-userSelected", user)
	}

	render() {
		return (
			<select
				className={"form-row showUser"}
				name={"users"}
				onChange={(e) => this.toggleSelectUser(e)}
				value={this.props.value}
			>
				<option value={"default"}>
					Voir le planning d'un utilisateur
				</option>
				{this.props.users.map((user, i) => (
					<option value={user.id} key={i}>
						{user.firstname}
					</option>
				))}
			</select>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		users: apiReducer.users,
	}
}

export default connect(mapStateToProps)(CalendarShowUser)
