import React from "react"
import Modal from "react-modal"
import PropTypes from "prop-types"

import "../css/components/modal.scss"

Modal.setAppElement("#root")

const MyModal = ({ isOpen, handleClose, className, title, content }) => (
	<Modal
		isOpen={isOpen}
		onRequestClose={() => handleClose()}
		contentLabel="Boite de dialogue"
		className={"Modal " + className}
		overlayClassName="Overlay"
		shouldCloseOnOverlayClick={true}
	>
		<div className="modal-header">
			<div className="modal-title">{title}</div>

			<div
				className="btn-circle btn-secondary modal-close"
				title={"Fermer"}
				onClick={() => handleClose()}
			>
				<i className="fas fa-times" />
			</div>
		</div>

		<div className="modal-body">{content}</div>
	</Modal>
)

MyModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	className: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.object,
}

export default MyModal
