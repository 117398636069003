import React from "react"
import { useDispatch, useSelector } from "react-redux"

import Note from "../../components/Form/Note/index"
import { changeQuotation } from "../../redux/QuotationFormAction"

export const AccountingNote = () => {
	const dispatch = useDispatch()

	const { form } = useSelector(
		({ quotationFormReducer }: any) => quotationFormReducer
	)

	const onChange = (value: string) => {
		dispatch(changeQuotation(null, "note", value))
	}

	return (
		<Note
			note={form.note.value}
			onChangeNote={onChange}
			className={"accounting-note form-block"}
		/>
	)
}
