import {
	ADD_ROW,
	CHANGE_CITY,
	CHANGE_ROW,
	DELETE_ROW,
	REORDER,
	RESET_WRITE_QUOTATION_FORM,
} from "./QuotationWriteAction"

const initialState = {
	rowId: 0,
	form: {
		rows: [],
		city: "Reims",
	},
}

const quotationWriteReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_ROW:
			return {
				...state,
				rowId: state.rowId + 1,
				form: {
					...state.form,
					rows: state.form.rows.concat({
						id: state.rowId,
						form: action.form,
						data: {
							position: state.rowId,
							code: "",
							text: "",
							quantity: "0",
							unitPrice: "0",
							sellingPrice: 0,
						},
					}),
				},
			}
		case DELETE_ROW:
			return {
				...state,
				form: {
					...state.form,
					rows: state.form.rows.filter((row) => row.id !== action.id),
				},
			}
		case CHANGE_ROW: {
			const row = state.form.rows.find((row) => row.id === action.id)

			if (
				row &&
				(action.name === "quantity" || action.name === "unitPrice")
			) {
				const quantity =
					action.name === "quantity"
						? parseFloat(action.value) || 0
						: parseFloat(row.data.quantity) || 0
				const unitPrice =
					action.name === "unitPrice"
						? parseFloat(action.value) || 0
						: parseFloat(row.data.unitPrice) || 0

				const sellingPrice = (quantity * unitPrice).toFixed(2) || 0

				return {
					...state,
					form: {
						...state.form,
						rows: state.form.rows.map((row) =>
							row.id === action.id
								? {
										...row,
										data: {
											...row.data,
											[action.name]: action.value,
											sellingPrice,
										},
								  }
								: row
						),
					},
				}
			} else {
				return {
					...state,
					form: {
						...state.form,
						rows: state.form.rows.map((row) =>
							row.id === action.id
								? {
										...row,
										data: {
											...row.data,
											[action.name]: action.value,
										},
								  }
								: row
						),
					},
				}
			}
		}
		case CHANGE_CITY:
			return {
				...state,
				form: {
					...state.form,
					city: action.value,
				},
			}
		case RESET_WRITE_QUOTATION_FORM:
			return initialState
		case REORDER:
			return {
				...state,
				form: {
					...state.form,
					rows: [
						...state.form.rows.sort(
							(row, row2) =>
								row.data.position - row2.data.position
						),
					],
				},
			}
		default:
			return state
	}
}

export default quotationWriteReducer
