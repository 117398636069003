import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getResources } from "../../redux/action"

import "../../css/pages/bills/billsPrint.scss"
import Page from "../../components/Page/Page"
import Table from "../../components/Table/Table"
import { isDefined } from "../../utils/Data"
import { formatDate, getYearsBetweenDates } from "../../utils/Date"
import { getAmountTTC, getTotalPrice, getTva } from "../../utils/Amounts"

const BillsPrint = () => {
	const months = [
		"01",
		"02",
		"03",
		"04",
		"05",
		"06",
		"07",
		"08",
		"09",
		"10",
		"11",
		"12",
	]
	const years = getYearsBetweenDates(new Date(), new Date("January 1, 2015"))

	const dispatch = useDispatch()
	const { bills, rawToken } = useSelector(({ apiReducer }) => apiReducer)

	const getBillFiltered = () => {
		return bills.filter((bill) => {
			const date = formatDate(bill.created)
			const split = date.split("/")
			const monthDate = split[1]
			const yearDate = split[2]

			return (
				monthDate === month &&
				yearDate === year &&
				bill.model === false &&
				bill.customerName.length > 0
			)
		})
	}

	const [year, setYear] = useState("2020")
	const [month, setMonth] = useState("01")
	const [billFiltered, setBillFiltered] = useState(getBillFiltered)

	useEffect(() => {
		dispatch(getResources("bills", rawToken))
	}, [])

	useEffect(() => {
		setBillFiltered(getBillFiltered)
	}, [month, year])

	return (
		<Page title={"Liste des factures"} className={"bills-print"}>
			<select value={month} onChange={(e) => setMonth(e.target.value)}>
				{months.map((month) => (
					<option key={month} value={month}>
						{month}
					</option>
				))}
			</select>

			<select value={year} onChange={(e) => setYear(e.target.value)}>
				{years.map((year) => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</select>

			<Table
				head={
					<tr>
						<th>N° Facture</th>
						<th>Client</th>
						<th>Date</th>
						<th>Montant HT</th>
						<th>TVA</th>
						<th>Montant TTC</th>
					</tr>
				}
				body={billFiltered.map((bill, i) => (
					<tr key={i}>
						<td>{bill.number}</td>
						<td className={"customers-name"}>
							{bill.customerName !== null
								? bill.customerName
								: isDefined(bill.project.customer)
								? bill.project.customer.name
								: ""}
						</td>
						<td>{formatDate(bill.created)}</td>
						<td className={"amounts"}>
							{parseFloat(getTotalPrice(bill.totalPrice)).toFixed(
								2
							) + " €"}
						</td>
						<td className={"amounts"}>
							{parseFloat(
								getTva(bill.totalPrice, bill.vat)
							).toFixed(2)}{" "}
							€
						</td>
						<td className={"amounts"}>
							{parseFloat(
								getAmountTTC(bill.totalPrice, bill.vat)
							).toFixed(2)}{" "}
							€
						</td>
					</tr>
				))}
			/>
		</Page>
	)
}

export default BillsPrint
