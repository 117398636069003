export const RESET_FILTERED = "RESET_FILTERED",
	CHANGE_FILTERED = "CHANGE_FILTERED"

export const changeFiltered = (id, value) => (dispatch) => {
	dispatch({
		type: CHANGE_FILTERED,
		id,
		value,
	})
}

export const resetFiltered = () => (dispatch) => {
	dispatch({ type: RESET_FILTERED })
}
