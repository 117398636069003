import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import "../../css/form.scss"
import "../../css/pages/accounting/accounting.scss"
import {
	createResource,
	deleteResource,
	getNewNum,
	getNewNumWrited,
	getResource,
	getResources,
	getResourcesByProperty,
	updateResource,
} from "../../redux/action"

import {
	addGroupForm,
	addMeasure,
	addMeasureForm,
	addProduct,
	addProductForm,
	changeQuotation,
	changeTotalMeasure,
	checkFormValidity,
	deleteGroupForm,
	deleteMeasure,
	deleteMeasureForm,
	deleteProduct,
	deleteProductForm,
	resetQuotationForm,
} from "../../redux/QuotationFormAction"
import { AccountingFormModel } from "./AccountingFormModel"
import { AccountingFormTitle } from "./accounting-form-title/accounting-form-title"
import MainForm from "../accounting/MainForm"
import GroupInputForm from "../accounting/Products/GroupInputForm"
import QuotationMeasuresForm from "../accounting/Measures/QuotationMeasuresForm"
import MeasuresForm from "../accounting/Measures/MeasuresForm"
import ProductForm from "../accounting/Products/ProductForm"
import SubcontractingForm from "../accounting/Products/SubcontractingForm"
import { onErrorAlert, onSaved } from "../../utils/Alert"
import AllowanceForm from "./Products/AllowanceForm"
import ShapingForm from "./Products/ShapingForm"
import AdhesiveForm from "./Products/AdhesiveForm"
import TechnicalCostsForm from "./Products/TechnicalCostsForm"
import MovementForm from "./Products/MovementForm"
import SuppliesForm from "./Products/SuppliesForm"
import PoseForm from "./Products/PoseForm"
import { SavedButtons } from "./SavedButtons"
import { isDefined, sortGroupProduct } from "../../utils/Data"
import { datimeToDate, getDateFormatedEN } from "../../utils/Date"
import { AccountingNote } from "./AccountingNote"
import { AccountingAction, AccountingElementType } from "../../types/accounting"

interface Props {
	dispatch: any
	rawToken: string
	data: any
	name: AccountingElementType
	newNum: string
	measureFormsId: number
	productGroups: any[]
	groupInputForms: any[]
	productFormsId: number
	id: number
	history: any
	singularName: string
}

interface State {
	action: AccountingAction
	resource: any
	modelSelected: boolean
	show: boolean
	showModalSaved: boolean
}

class GeneralForm extends Component {
	private readonly id: number | string
	props: Props
	state: State

	constructor(props) {
		super(props)

		const pathname: string = window.location.pathname
		const split: any = pathname.split("/")
		this.id = split[2]

		this.state = {
			action: split[3],
			resource: null,
			modelSelected: false,
			show:
				localStorage.getItem(
					props.name + "Form-" + this.id + "-show"
				) !== null
					? JSON.parse(
							localStorage.getItem(
								props.name + "Form-" + this.id + "-show"
							)
					  )
					: true,
			showModalSaved: false,
		}
	}

	componentDidMount() {
		const { dispatch, rawToken } = this.props

		dispatch(resetQuotationForm()).then(() => {
			dispatch(getResourcesByProperty("products", "reference", rawToken))
			dispatch(getResources("productGroups", rawToken))

			this.initializeFormWhenDuplicate()
			this.initializeNumWhenCreateQuotationOrCreateOrDuplicatedBill()
			this.initializeResourceWhenUpdate()
			this.initializeWhenCreateBillFromQuotation()
		})
	}

	componentWillUnmount() {
		this.props.dispatch(resetQuotationForm())
	}

	initializeFormWhenDuplicate = () => {
		const { dispatch, rawToken, data, name } = this.props
		const { action } = this.state

		if (action === "dupliquer") {
			dispatch(getResource(name, this.id, rawToken)).then((resource) => {
				this.setState(
					{
						resource,
					},
					() => {
						Object.keys(data).map((input) =>
							input !== "number"
								? this.resourceToInput(input)
								: null
						)
						if (name === AccountingElementType.QUOTATIONS)
							if (this.state.resource.drafted === null)
								//SI LE DEVIS INITIAL N'EST PAS REDIGE
								dispatch(getNewNum(name, rawToken)).then(() =>
									this.itemToInput(
										"number",
										this.props.newNum
									)
								)
							//SI LE DEVIS INITIAL EST REDIGE
							else
								dispatch(getNewNumWrited(name, rawToken)).then(
									() =>
										this.itemToInput(
											"number",
											this.props.newNum
										)
								)
						else
							dispatch(getNewNumWrited(name, rawToken)).then(() =>
								this.itemToInput("number", this.props.newNum)
							)
					}
				)
			})
		}
	}

	initializeNumWhenCreateQuotationOrCreateOrDuplicatedBill = () => {
		const { dispatch, rawToken, name } = this.props
		const { action } = this.state

		if (
			(name === AccountingElementType.QUOTATIONS &&
				this.id === "creer") ||
			(name === AccountingElementType.BILLS &&
				action !== AccountingAction.UPDATE)
		)
			dispatch(getNewNum(name, rawToken)).then(() =>
				this.itemToInput("number", this.props.newNum)
			)
	}

	initializeResourceWhenUpdate = () => {
		const { dispatch, rawToken, name, data } = this.props
		const { action } = this.state

		if (
			this.id !== "creer" &&
			action !== AccountingAction.QUOTATION &&
			action !== AccountingAction.DUPLICATE
		) {
			dispatch(getResource(name, this.id, rawToken)).then((resource) => {
				this.setState(
					{
						resource,
					},
					() => {
						Object.keys(data).map((input) =>
							this.resourceToInput(input)
						)
					}
				)
			})
		}
	}

	initializeWhenCreateBillFromQuotation = () => {
		const { dispatch, rawToken, data } = this.props
		const { action } = this.state

		if (action === AccountingAction.QUOTATION) {
			dispatch(getNewNum(AccountingElementType.BILLS, rawToken)).then(
				() => this.itemToInput("number", this.props.newNum)
			)

			dispatch(
				getResource(AccountingElementType.QUOTATIONS, this.id, rawToken)
			).then((resource) => {
				this.setState(
					{
						resource: resource,
					},
					() => {
						Object.keys(data).map((input) =>
							input !== "number"
								? this.resourceToInput(input)
								: null
						)
					}
				)
			})
		}
	}

	resourceToInput = (input) => {
		const { resource, action } = this.state

		if (resource) {
			if (input === "project") {
				this.itemToInput(
					"project",
					resource.project !== null ? resource.project.number : ""
				)
				this.itemToInput(
					"projectId",
					resource.project !== null ? resource.project.id : null
				)
			} else if (input === "customer") {
				this.itemToInput(
					"customer",
					resource.project !== null
						? resource.project.customer !== null
							? resource.project.customer.code
							: ""
						: ""
				)
				this.itemToInput(
					"customerId",
					resource.project !== null
						? resource.project.customer !== null
							? resource.project.customer.id
							: null
						: null
				)
			} else if (input === "customerName") {
				this.itemToInput(
					"customerName",
					resource.customerName !== null
						? resource.customerName
						: resource.project.customer !== null
						? resource.project.customer.name
						: ""
				)
			} else if (input === "deliveryDelay") {
				this.itemToInput(
					"deliveryDelay",
					resource.deliveryDelay !== null
						? resource.deliveryDelay.id
						: ""
				)
			} else if (input === "settlement") {
				this.itemToInput(
					"settlement",
					resource.settlement !== null ? resource.settlement.id : ""
				)
			} else if (input === "measures") {
				this.addMeasureFormWithInput()
			} else if (input === "products") {
				this.addProductFormWithInput()
			} else if (input === "drafted") {
				this.itemToInput("drafted", resource.drafted)
			} else if (input === "created" || input === "sent") {
				if (
					action === AccountingAction.QUOTATION ||
					action === AccountingAction.DUPLICATE
				)
					this.itemToInput(input, getDateFormatedEN(new Date()))
				else this.itemToInput(input, datimeToDate(resource[input]))
			} else {
				this.itemToInput(
					input,
					resource[input] !== null ? resource[input] : ""
				)
			}
		}
	}

	itemToInput = (input, item) =>
		this.props.dispatch(changeQuotation(null, input, item))

	addMeasureFormWithInput = () => {
		const { measures } = this.state.resource
		if (measures.length > 0)
			measures.map((measure, i) => this.addMeasureForm(null, measure, i))
	}

	addMeasureForm = (e, measure = null, i = null) => {
		if (e !== null) {
			e.preventDefault()
			e.persist()
		}

		const dispatch = this.props.dispatch
		const id = i !== null ? i : this.props.measureFormsId
		let idBase = null

		if (measure !== null) {
			idBase = measure.id
			measure.id = id
		}

		dispatch(addMeasure(id, measure, idBase)).then(() => {
			dispatch(
				addMeasureForm(
					<QuotationMeasuresForm
						key={id}
						id={id}
						measure={measure}
						onDelete={(id) => this.deleteMeasureForm(id, idBase)}
						changeTotalMeasures={(name) =>
							this.changeTotalMeasures(name)
						}
					/>
				)
			)
		})
	}

	deleteMeasureForm = async (index, idBase?) => {
		const { dispatch, rawToken } = this.props
		const { action } = this.state

		this.deleteMeasure(index)
		dispatch(deleteMeasureForm(index))
		if (
			idBase !== null &&
			action !== AccountingAction.DUPLICATE &&
			action !== AccountingAction.QUOTATION
		) {
			try {
				await dispatch(
					deleteResource("quotationMeasures", idBase, rawToken)
				)
			} catch (e) {}
		}
	}

	deleteMeasure = (id) => {
		this.props.dispatch(deleteMeasure(id)).then(() => {
			this.changeTotalMeasures(null)
		})
	}

	changeTotalMeasures = (name) =>
		this.props.dispatch(changeTotalMeasure(name))

	addProductFormWithInput = () => {
		const products = this.state.resource.products
		const groupsOfProducts = products.map((product) => product.productGroup)
		// @ts-ignore
		const groups = [...new Set(groupsOfProducts.map((group) => group.id))]

		groups.map((group) => {
			const grp = groupsOfProducts.find((grp) => grp.id === group)
			this.addGroupInputForm2(grp)
		})
	}

	addGroupInputForm2 = (group) => {
		const id = group.id,
			name = group.name

		this.props.dispatch(
			addGroupForm(
				id,
				name,
				<GroupInputForm
					id={id}
					key={id}
					onDelete={() => this.deleteGroupInputForm(id)}
					products={this.state.resource.products}
					title={name}
					changeTotalPrice={() => this.changeTotalPrice()}
					canDeleteResource={
						this.state.action !== AccountingAction.DUPLICATE &&
						this.state.action !== AccountingAction.QUOTATION
					}
				/>
			)
		)
	}

	addGroupInputForm = (e, product = null) => {
		let title = ""
		let id = null
		if (e !== null) {
			e.persist()
			id = parseInt(e.target.value)
			const group = this.props.productGroups.find(
				(group) => group.id === id
			)
			title = group.name
		} else {
			id = product.productGroup.id
			title = product.productGroup.name
		}

		if (title !== "default") {
			const group = this.props.groupInputForms.find(
				(group) => group.id === id
			)
			if (group !== undefined) {
				this.addProductForm(null, group, product)
			} else {
				this.props.dispatch(
					addGroupForm(
						id,
						title,
						<GroupInputForm
							id={id}
							key={id}
							onDelete={() => this.deleteGroupInputForm(id)}
							products={null}
							title={title}
							changeTotalPrice={() => this.changeTotalPrice()}
						/>
					)
				)
			}
		}

		if (e !== null) e.target.value = "default"
	}

	deleteGroupInputForm = (index) => {
		const { dispatch, data, rawToken } = this.props
		const { action } = this.state

		dispatch(deleteGroupForm(index))
		const products = data.products.value.filter(
			(product) => product.idGroup === index
		)
		products.map(async (product) => {
			dispatch(deleteProduct(product.id))
			if (
				action !== AccountingAction.DUPLICATE &&
				action !== AccountingAction.QUOTATION
			) {
				try {
					await dispatch(
						deleteResource("products", product.idBase, rawToken)
					)
				} catch (e) {}
			}
		})
	}

	addProductForm = (e = null, group, product = null) => {
		const id = isDefined(product)
			? product.id * 99
			: this.props.productFormsId
		const idGroup = group.id
		const idBase = isDefined(product) ? product.id : null

		this.props
			.dispatch(addProduct(id, idGroup, idBase))
			.then(
				this.props.dispatch(
					addProductForm(
						idGroup,
						this.renderWithType(group, id, product)
					)
				)
			)
	}

	deleteProductForm = (index, product) => {
		this.deleteProduct(index, product)
		this.props.dispatch(deleteProductForm(this.props.id, index))
	}

	deleteProduct = (id, product) => {
		this.props.dispatch(deleteProduct(id)).then(this.changeTotalPrice)
		if (product !== null)
			this.props.dispatch(
				deleteResource("products", product.id, this.props.rawToken)
			)
	}

	renderWithType = (group, id, product) => {
		const idGroup = group.id

		switch (group.name) {
			case "Produits":
				return (
					<ProductForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			case "Sous traitance":
				return (
					<SubcontractingForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			case "Prestation":
				return (
					<AllowanceForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			case "Façonnage":
				return (
					<ShapingForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			case "Adhésif":
				return (
					<AdhesiveForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			case "Frais techniques":
				return (
					<TechnicalCostsForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			case "Déplacement":
				return (
					<MovementForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			case "Fournitures":
				return (
					<SuppliesForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			case "Pose":
				return (
					<PoseForm
						key={id}
						idGroup={idGroup}
						id={id}
						product={product}
						onDelete={() => this.deleteProductForm(id, product)}
						changeTotalPrice={() => this.changeTotalPrice()}
					/>
				)
			default:
				return
		}
	}

	changeHandler = (event, newName?, newValue?, callback?) => {
		this.props
			.dispatch(changeQuotation(event, newName, newValue))
			.then(() => {
				this.changeTotalPrice()
				if (callback !== undefined) callback()
			})
	}

	selectModel = (selected = true) =>
		this.setState({ modelSelected: selected })

	changeTotalPrice = () => {
		let totalPrice = 0
		this.props.data.products.value.map((product) => {
			totalPrice += parseFloat(product.sellingPrice) || 0
		})
		this.props
			.dispatch(
				changeQuotation(null, "totalPrice", totalPrice.toFixed(2))
			)
			.then(this.changeTotalPriceDiscount())
	}

	changeTotalPriceDiscount = () => {
		const data = this.props.data
		let discountEuros = data.discountEuros.value
		const discountPerCentage = data.discountPerCentage.value
		const totalPrice = data.totalPrice.value
		let totalDiscount = totalPrice

		if (discountEuros !== "") {
			totalDiscount = totalPrice - discountEuros
		} else if (discountPerCentage !== "") {
			discountEuros = totalPrice * (discountPerCentage / 100)
			totalDiscount = totalPrice - discountEuros
		}

		this.props.dispatch(
			changeQuotation(
				null,
				"totalPriceDiscount",
				parseFloat(totalDiscount).toFixed(2)
			)
		)
	}

	changeState = (value, callback) => {
		this.setState(
			{
				resource: value,
			},
			callback
		)
	}

	addMeasureToQuotation = (resource) => {
		this.props.data.measures.value.map(async (measure) => {
			delete measure.id
			Object.keys(measure).map((property) => {
				if (property !== "idBase")
					measure[property] = parseFloat(measure[property])
			})
			const name =
				this.props.name === AccountingElementType.QUOTATIONS
					? "quotation"
					: "bill"
			measure[name] = resource.id

			if (
				this.state.action === AccountingAction.DUPLICATE ||
				this.state.action === AccountingAction.QUOTATION ||
				this.state.modelSelected === true
			)
				try {
					await this.props.dispatch(
						createResource(
							"quotationMeasures",
							measure,
							this.props.rawToken
						)
					)
				} catch (e) {}
			else if (measure.idBase === null)
				try {
					await this.props.dispatch(
						createResource(
							"quotationMeasures",
							measure,
							this.props.rawToken
						)
					)
				} catch (e) {}
			else {
				try {
					await this.props.dispatch(
						updateResource(
							"quotationMeasures",
							measure.idBase,
							measure,
							this.props.rawToken
						)
					)
				} catch (e) {}
			}
		})
	}

	addProductsToQuotation = (resource) => {
		this.props.data.products.value.map(async (product) => {
			delete product.id
			const name =
				this.props.name === AccountingElementType.QUOTATIONS
					? "quotation"
					: "bill"
			product[name] = resource.id
			const action = this.state.action

			if (
				action === AccountingAction.DUPLICATE ||
				action === AccountingAction.QUOTATION ||
				this.state.modelSelected === true
			)
				try {
					await this.props.dispatch(
						createResource("products", product, this.props.rawToken)
					)
				} catch (e) {}
			else if (product.idBase === null)
				try {
					await this.props.dispatch(
						createResource("products", product, this.props.rawToken)
					)
				} catch (e) {}
			else {
				try {
					await this.props.dispatch(
						updateResource(
							"products",
							product.idBase,
							product,
							this.props.rawToken
						)
					)
				} catch (e) {}
			}
		})
	}

	addArticleToQuotation = (resource) => {
		if (this.state.resource !== null)
			if (this.state.resource.articles.length > 0) {
				this.state.resource.articles.map(async (article) => {
					delete article.id
					const name =
						this.props.name === AccountingElementType.QUOTATIONS
							? "quotation"
							: "bill"
					article[name] = resource.id
					try {
						this.props.dispatch(
							createResource(
								"articles",
								article,
								this.props.rawToken
							)
						)
					} catch (e) {}
				})
			}
	}

	prepareSubmit = () => {
		const data = this.props.data

		const body: any = {}
		Object.keys(data).map((input) => (body[input] = data[input].value))

		body.project = body.projectId

		body.advance = parseFloat(body.advance)
		body.discountPerCentage = parseFloat(body.discountPerCentage)
		body.discountEuros = parseFloat(body.discountEuros)
		body.totalPrice = parseFloat(body.totalPriceDiscount)
		body.vat = parseFloat(isDefined(body.vat) ? body.vat : "0.2")

		if (body.created === "") delete body.created
		if (body.sent === "") delete body.sent

		if (body.deliveryDelay === "") delete body.deliveryDelay
		if (body.settlement === "") delete body.settlement

		delete body.products
		delete body.measures

		const resourceId = this.id

		if (this.state.resource !== null)
			body.drafted =
				this.state.resource.articles.length > 0 ? new Date() : null

		if (
			this.state.action === AccountingAction.QUOTATION ||
			this.state.action === AccountingAction.DUPLICATE
		) {
			body.quotation = this.id
		}

		if (
			this.state.action === AccountingAction.QUOTATION &&
			isDefined(body.created) === false
		) {
			body.created = new Date()
		}

		if (
			this.state.action === AccountingAction.DUPLICATE &&
			this.props.name === AccountingElementType.BILLS
		) {
			body.quotation =
				this.state.resource.quotation !== null
					? this.state.resource.quotation.id
					: null
		}

		const link =
			this.props.name === AccountingElementType.QUOTATIONS
				? "devis"
				: "factures"

		return {
			resourceId,
			body,
			link,
		}
	}

	afterSubmit = (redirect, write, resourceId, link) => {
		if (redirect === true) {
			this.props.dispatch(resetQuotationForm())
			write === true
				? this.props.history.push(
						"/" + link + "/" + resourceId + "/rediger"
				  )
				: this.props.history.goBack()
		} else {
			this.props.dispatch(resetQuotationForm())
			this.confirmSavedMessage(link, resourceId)
		}
	}

	confirmSavedMessage = (link, resourceId) => {
		const type =
			this.props.name === AccountingElementType.QUOTATIONS
				? "devis"
				: "facture"
		const isModel = this.state.modelSelected
		const message =
			"Votre " +
			(isModel ? "modèle de " : "") +
			type +
			" a bien été enregistré" +
			(type === "facture" ? "e" : "")
		onSaved(
			"/" + link + "/" + resourceId + "/modifier",
			"Succès !",
			message
		)
	}

	addSubResources = (
		resource,
		addArticle = false,
		redirect,
		write,
		resourceId,
		link
	) => {
		if (addArticle === true)
			Promise.all([
				this.addMeasureToQuotation(resource),
				this.addProductsToQuotation(resource),
				this.addArticleToQuotation(resource),
			]).then(() => this.afterSubmit(redirect, write, resourceId, link))
		else
			Promise.all([
				this.addMeasureToQuotation(resource),
				this.addProductsToQuotation(resource),
			]).then(() => this.afterSubmit(redirect, write, resourceId, link))
	}

	handleSubmit = async (e, write = false, redirect = true) => {
		e.preventDefault()

		if (this.props.dispatch(checkFormValidity(this.props.data))) {
			const submit = this.prepareSubmit()
			const body = submit.body
			const link = submit.link
			let resourceId = submit.resourceId

			if (this.state.action !== AccountingAction.UPDATE) {
				try {
					await this.props
						.dispatch(
							createResource(
								this.props.name,
								body,
								this.props.rawToken
							)
						)
						.then((resource) => {
							resourceId = resource.id
							this.addSubResources(
								resource,
								true,
								redirect,
								write,
								resourceId,
								link
							)
						})
				} catch (e) {
					onErrorAlert("La sauvegarde a échouée")
				}
			} else {
				try {
					await this.props
						.dispatch(
							updateResource(
								this.props.name,
								this.id,
								body,
								this.props.rawToken
							)
						)
						.then((resource) =>
							this.addSubResources(
								resource,
								false,
								redirect,
								write,
								resourceId,
								link
							)
						)
				} catch (e) {
					onErrorAlert("La sauvegarde a échouée")
				}
			}
		}
	}

	handleVisibility = (stateName, localStorageName) => {
		this.setState({
			[stateName]: !this.state[stateName],
		})
		localStorage.setItem(
			localStorageName + "-" + this.id + "-show",
			this.state[stateName] === true ? "false" : "true"
		)
	}

	render() {
		const { data, name, singularName, productGroups, groupInputForms } =
			this.props
		const { resource, action, show } = this.state

		return (
			<div className={"box accounting"}>
				<AccountingFormTitle
					id={this.id}
					name={name === "quotations" ? "un devis" : "une facture"}
					localName={"quotationForm"}
					entity={resource}
					action={action}
					show={show}
					handleVisibility={() =>
						this.handleVisibility("show", name + "Form")
					}
				/>
				<div className={"box-body"}>
					<AccountingFormModel
						type={name}
						resourceToInput={(input) => this.resourceToInput(input)}
						changeState={(value, callback) =>
							this.changeState(value, callback)
						}
						changeHandler={(e, newName, newValue, callback) =>
							this.changeHandler(e, newName, newValue, callback)
						}
						selectModel={this.selectModel}
					/>
					<form onSubmit={this.handleSubmit}>
						<MainForm
							name={singularName}
							data={data}
							show={show}
							changeHandler={this.changeHandler}
						/>

						<AccountingNote />

						<MeasuresForm
							id={this.id}
							addMeasureForm={(e, measure, i) =>
								this.addMeasureForm(e, measure, i)
							}
							deleteMeasureForm={(i) => this.deleteMeasureForm(i)}
							deleteMeasure={(i) => this.deleteMeasure(i)}
							changeTotalMeasures={(name) =>
								this.changeTotalMeasures(name)
							}
						/>

						<div className={"form-block"}>
							<select
								className={"select-products-groups"}
								name={"productTypes"}
								onChange={this.addGroupInputForm}
							>
								<option value={"default"}>Ajouter</option>
								{sortGroupProduct(productGroups).map(
									(group, i) => (
										<option key={i} value={group.id}>
											{group.name}
										</option>
									)
								)}
							</select>

							{sortGroupProduct(groupInputForms).map((group) => {
								return group.form
							})}
						</div>

						<SavedButtons
							id={this.id}
							data={data}
							action={action}
							handleSubmit={this.handleSubmit}
						/>
					</form>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ apiReducer, quotationFormReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		newNum: apiReducer.newNum,
		projects: apiReducer.projects,
		productTypes: apiReducer.productTypes,
		productGroups: apiReducer.productGroups,
		data: quotationFormReducer.form,
		measureForms: quotationFormReducer.measureForms,
		measureTotal: quotationFormReducer.measureTotal,
		measureFormsId: quotationFormReducer.measureFormsId,
		groupInputFormsId: quotationFormReducer.groupInputFormsId,
		groupInputForms: quotationFormReducer.groupInputForms,
	}
}

// @ts-ignore
export default withRouter(connect(mapStateToProps)(GeneralForm))
