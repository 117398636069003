import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router"

import "../../css/pages/activityMonitoring.scss"
import { getResources } from "../../redux/action"
import { getYearsBetweenDates, months } from "../../utils/Date"
import Page from "../../components/Page/Page"
import { getAmounts, getTotals } from "./ActivityMonitoringCalculs"

const ActivityMonitoring = () => {
	const array = months.map((month) => [month, 0, 0, 0, 0, 0])
	const years = getYearsBetweenDates(new Date(), new Date("January 1, 2015"))
	const date = new Date()
	const yearnNow = parseInt(date.getUTCFullYear())

	const [year, setYear] = useState(yearnNow)
	const [table, setTable] = useState(array)
	const [totals, setTotals] = useState([0, 0, 0, 0, 0])

	const rawToken = useSelector(({ apiReducer }) => apiReducer.rawToken)
	const bills = useSelector(({ apiReducer }) => apiReducer.bills)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getResources("bills", rawToken))
	}, [])

	useEffect(() => {
		setTable((oldTable) => getAmounts(oldTable, bills, year))
	}, [bills, year])

	useEffect(() => {
		setTotals((oldTable) => [
			...oldTable.map((total, i) => getTotals(table, i + 1)),
		])
	}, [table])

	return (
		<Page title={"Suivi d'activité"} className={"activityMonitoring"}>
			<select
				value={year}
				onChange={(e) => setYear(parseInt(e.target.value))}
			>
				{years.map((year) => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</select>

			{bills.length > 0 ? (
				<table>
					<thead>
						<tr>
							<th>Mois</th>
							<th>Montant HT</th>
							<th>TVA</th>
							<th>Montant TTC</th>
							<th>Acompte</th>
							<th>Solde à régler</th>
						</tr>
					</thead>
					<tbody>
						{table.map((row, i) => (
							<tr key={i}>
								{row.map((col, j) => (
									<td key={j}>
										{j > 0
											? parseFloat(col).toFixed(2) + " €"
											: col}
									</td>
								))}
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td>Totaux :</td>
							{totals.map((total, i) => (
								<td key={i}>
									{parseFloat(total).toFixed(2) + " €"}
								</td>
							))}
						</tr>
					</tfoot>
				</table>
			) : (
				"Chargement ..."
			)}
		</Page>
	)
}

export default withRouter(ActivityMonitoring)
