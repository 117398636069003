import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import "../../../../css/pages/projects/modals.scss"
import MyModal from "../../../../components/MyModal"
import { updateResource } from "../../../../redux/action"

const CommentModal = ({ id, show, setShow }) => {
	const [text, setText] = useState("")
	const rawToken = useSelector(({ apiReducer }) => apiReducer.rawToken)
	const dispatch = useDispatch()

	const onEnter = useCallback(
		(e) => {
			if (e.keyCode === 13 && show === true) setShow()
		},
		[show]
	)

	useEffect(() => {
		document.addEventListener("keyup", onEnter)
		return () => document.removeEventListener("keyup", onEnter)
	}, [onEnter])

	const onChange = (e) => setText(e.target.value)

	const onSubmit = async (e) => {
		e.preventDefault()

		try {
			await dispatch(
				updateResource("steps", id, { comment: text }, rawToken)
			).then(() => {
				setShow()
				setText("")
			})
		} catch (e) {}
	}

	return (
		<MyModal
			title="Validation de la tâche"
			isOpen={show}
			handleClose={() => setShow()}
			className="modal-comment"
			content={
				<form onSubmit={onSubmit}>
					<textarea
						name={"comment"}
						placeholder={"Commentaire"}
						value={text}
						onChange={onChange}
					/>
					<button type={"submit"}>Envoyer</button>
				</form>
			}
		/>
	)
}

CommentModal.propTypes = {
	id: PropTypes.number,
	show: PropTypes.bool,
	setShow: PropTypes.func,
}

export default CommentModal
