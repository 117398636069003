import {
	ADD_GROUP_FORM,
	ADD_MEASURE,
	ADD_MEASURE_FORM,
	ADD_PRODUCT,
	ADD_PRODUCT_FORM,
	CHANGE_MEASURE,
	CHANGE_PRODUCT,
	CHANGE_QUOTATION,
	CHANGE_TOTAL_GROUP_PRODUCTS,
	CHANGE_TOTAL_MEASURE,
	CHANGE_VALIDITY_PROPERTY,
	DELETE_GROUP_FORM,
	DELETE_MEASURE,
	DELETE_MEASURE_FORM,
	DELETE_PRODUCT,
	DELETE_PRODUCT_FORM,
	RESET_FORM,
} from "./QuotationFormAction"
import { datimeToDate } from "../utils/Date"

const initialState = {
	measureFormsId: 0,
	measureForms: [],
	groupInputFormsId: 0,
	groupInputForms: [],
	productFormsId: 0,
	form: {
		number: {
			value: "",
			valid: false,
		},
		project: {
			value: "",
			valid: false,
		},
		projectId: {
			value: null,
			valid: false,
		},
		name: {
			value: "",
			valid: false,
		},
		customerId: {
			value: null,
		},
		customer: {
			value: "",
			valid: false,
		},
		customerName: {
			value: "",
			valid: false,
		},
		deliveryDelay: {
			value: "",
		},
		settlement: {
			value: "",
		},
		totalPrice: {
			value: "",
		},
		advance: {
			value: "",
		},
		discountEuros: {
			value: "",
		},
		discountPerCentage: {
			value: "",
		},
		totalPriceDiscount: {
			value: "",
		},
		vat: {
			value: "0.2",
		},
		products: {
			value: [],
		},
		measures: {
			value: [],
		},
		model: {
			value: false,
		},
		drafted: {
			value: null,
		},
		created: {
			value: datimeToDate(null),
		},
		sent: {
			value: datimeToDate(null),
		},
		note: {
			value: "",
		},
	},
	measure: {
		idBase: null,
		id: "",
		width: "",
		height: "",
		surface: "",
		quantity: "",
		totalSurface: "",
		pubCoefficient: "",
		surfCoefficient: "",
		perimeter: "",
	},
	measureTotal: {
		width: 0,
		height: 0,
		surface: 0,
		quantity: 0,
		totalSurface: 0,
		pubCoefficient: 0,
		surfCoefficient: 0,
		perimeter: 0,
	},
	product: {
		idBase: null,
		idGroup: "",
		code: "",
		reference: "",
		designation: "",
		format: "",
		quantity: "",
		surface: "",
		totalSurface: "",
		unitPrice: "",
		totalha: "",
		marginCoefficient: "",
		sellingPrice: "",
		price: "",
		shaping: "",
		rate: "",
		hours: "",
		minutes: "",
		hourlyRate: "",
		minuteRate: "",
		dailyCost: "",
		daysNumber: "",
	},
}

const quotationFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_QUOTATION:
			return {
				...state,
				form: {
					...state.form,
					[action.name]: {
						...state.form[action.name],
						value: action.value,
						valid: action.value !== "",
					},
				},
			}
		case ADD_MEASURE_FORM:
			return {
				...state,
				measureForms: state.measureForms.concat(action.form),
			}
		case DELETE_MEASURE_FORM:
			return {
				...state,
				measureForms: state.measureForms.filter(
					(form) => parseInt(form.key, 10) !== action.id
				),
			}
		case ADD_MEASURE:
			return {
				...state,
				form: {
					...state.form,
					measures: {
						...state.form.measures,
						value: [
							...state.form.measures.value,
							{
								...state.measure,
								...action.measure,
								id: action.id,
								idBase: action.idBase,
							},
						],
					},
				},
				measureFormsId: state.measureFormsId + 1,
			}
		case DELETE_MEASURE:
			return {
				...state,
				form: {
					...state.form,
					measures: {
						...state.form.measures,
						value: state.form.measures.value.filter(
							(measure) => measure.id !== action.id
						),
					},
				},
			}
		case CHANGE_MEASURE:
			return {
				...state,
				form: {
					...state.form,
					measures: {
						...state.form.measures,
						value: state.form.measures.value.map((measure) => {
							if (measure.id === action.id)
								return {
									...measure,
									[action.name]: action.value,
								}
							else return measure
						}),
					},
				},
			}
		case CHANGE_TOTAL_MEASURE:
			const measures = state.form.measures.value
			let total = 0

			if (action.name === null) {
				if (measures.length > 0) {
					let measureTotal = { ...initialState.measureTotal }
					const keys = Object.keys(measures[0])

					keys.forEach((key) => {
						total = 0
						measures.forEach(
							(measure) =>
								(total += parseFloat(measure[key]) || 0)
						)
						measureTotal[key] = total.toFixed(2)
					})

					return {
						...state,
						measureTotal: measureTotal,
					}
				}
			} else {
				state.form.measures.value.forEach(
					(measure) =>
						(total += parseFloat(measure[action.name]) || 0)
				)
				return {
					...state,
					measureTotal: {
						...state.measureTotal,
						[action.name]: total.toFixed(2),
					},
				}
			}
			return state
		case ADD_GROUP_FORM:
			return {
				...state,
				groupInputForms: state.groupInputForms.concat({
					id: action.id,
					name: action.name,
					form: action.form,
					productsForms: [],
				}),
			}
		case DELETE_GROUP_FORM:
			return {
				...state,
				groupInputForms: state.groupInputForms.filter(
					(group) => parseInt(group.id, 10) !== action.id
				),
			}
		case ADD_PRODUCT_FORM:
			return {
				...state,
				groupInputForms: [
					...state.groupInputForms.map((group) => {
						if (group.id === action.groupFormId)
							return {
								...group,
								productsForms: group.productsForms.concat(
									action.form
								),
							}
						return group
					}),
				],
				productFormsId: state.productFormsId + 1,
			}
		case DELETE_PRODUCT_FORM:
			return {
				...state,
				groupInputForms: [
					...state.groupInputForms.map((group) => {
						if (group.id === action.groupFormId)
							return {
								...group,
								productsForms: group.productsForms.filter(
									(productForm) =>
										parseInt(productForm.key, 10) !==
										action.productFormId
								),
							}
						return group
					}),
				],
			}
		case ADD_PRODUCT:
			return {
				...state,
				form: {
					...state.form,
					products: {
						...state.form.products,
						value: [
							...state.form.products.value,
							{
								...state.product,
								...action.product,
								id: action.id,
								/*type: action.group,*/ idGroup: action.idGroup,
								idBase: action.idBase,
							},
						],
					},
				},
			}
		case DELETE_PRODUCT:
			return {
				...state,
				form: {
					...state.form,
					products: {
						...state.form.products,
						value: state.form.products.value.filter(
							(product) => product.id !== action.id
						),
					},
				},
			}
		case CHANGE_PRODUCT:
			return {
				...state,
				form: {
					...state.form,
					products: {
						...state.form.products,
						value: state.form.products.value.map((product) => {
							if (product.id === action.id)
								return {
									...product,
									[action.name]: action.value,
								}
							else return product
						}),
					},
				},
			}
		case CHANGE_TOTAL_GROUP_PRODUCTS:
			const products = state.form.products.value.filter(
				(product) => product.idGroup === action.idGroup
			)
			let totalProducts = 0
			let totalMarginCoef = 0

			if (action.name === null) {
				if (products.length > 0) {
					let groupProductsTotal = {}
					const keys = Object.keys(products[0]).filter(
						(key) => key !== "marginCoefficient"
					)

					keys.forEach((key) => {
						totalProducts = 0
						totalMarginCoef = 0

						products.forEach((product) => {
							totalProducts += parseFloat(product[key]) || 0
							totalMarginCoef +=
								parseFloat(
									product.sellingPrice - product.totalha
								) || 0
						})
						groupProductsTotal[key] = totalProducts.toFixed(2)
						groupProductsTotal.marginCoefficient =
							totalMarginCoef.toFixed(2)
					})

					return {
						...state,
						groupInputForms: state.groupInputForms.map((group) =>
							group.id === action.idGroup
								? { ...group, totals: groupProductsTotal }
								: group
						),
					}
				}
			} else {
				state.form.products.value.forEach(
					(product) =>
						(totalProducts += parseFloat(product[action.name]) || 0)
				)
				return {
					...state,
					groupInputForms: state.groupInputForms.map((group) =>
						group.id === action.idGroup
							? {
									...group,
									totals: {
										...group.totals,
										[action.name]: totalProducts.toFixed(2),
									},
							  }
							: group
					),
					groupProductsTotal: {
						...state.groupProductsTotal,
						[action.name]: totalProducts.toFixed(2),
					},
				}
			}
			return state
		case CHANGE_VALIDITY_PROPERTY:
			return {
				...state,
				form: {
					...state.form,
					[action.name]: {
						...state.form[action.name],
						valid: action.validity,
					},
				},
			}
		case RESET_FORM:
			return initialState
		default:
			return state
	}
}

export default quotationFormReducer
