import React, { useEffect } from "react"
import Swal from "sweetalert2"

import { UpdateAppVersionApiRepository } from "../infrastructure/update-app-version.api.repository"
import { UpdateAppVersion } from "../domain/update-app-version"
import { GetAppVersion } from "../../get-app-version/domain/get-app-version"
import { GetAppVersionApiRepository } from "../../get-app-version/infrastructure/check-app-version.api.repository"

export const UpdateAppVersionContainer = () => {
	const updateAppVersionRepository = new UpdateAppVersionApiRepository()
	const updateAppVersion = new UpdateAppVersion(updateAppVersionRepository)

	useEffect(() => {
		_showModal()
	}, [])

	const _updateAppVersion = async () => {
		const getAppVersionRepository = new GetAppVersionApiRepository()
		const getAppVersion = new GetAppVersion(getAppVersionRepository)
		const newVersion: string = await getAppVersion.execute()

		updateAppVersion.execute(newVersion)
		_cleanCacheAndReload()
	}

	const _cleanCacheAndReload = () => {
		if ("caches" in window) {
			caches.keys().then((names) => {
				names.forEach(async (name) => {
					await caches.delete(name)
				})
			})

			window.location.reload()
		}
	}

	const _showModal = () => {
		Swal.fire({
			title: "Mise à jour",
			icon: "question",
			text: "Une nouvelle version de l'application est disponible. Souhaitez-vous faire la mise à jour ?",
			confirmButtonText: "Recharger",
			cancelButtonText: "Non",
			showCancelButton: true,
		}).then((result) => {
			if (result.value) {
				_updateAppVersion()
			}
		})
	}

	return <></>
}
