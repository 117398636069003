import React from "react"
import { useDispatch, useSelector } from "react-redux"
import CreatableSelect from "react-select/creatable"
import { changeQuotation } from "../../../redux/QuotationFormAction"

export const DeliveryDelay = () => {
	const dispatch = useDispatch()

	const { deliveryDelays } = useSelector(({ apiReducer }: any) => apiReducer)

	const form = useSelector(
		({ quotationFormReducer }: any) => quotationFormReducer.form
	)

	const items = deliveryDelays.sort((a, b) => {
		if (a.id < b.id) {
			return -1
		}
		if (a.id > b.id) {
			return 1
		}
		return 0
	})

	const options = items.map((item) => {
		return {
			value: item.id,
			label: item.name,
		}
	})

	const onChange = (obj) => {
		dispatch(changeQuotation(null, "deliveryDelay", obj.value))
	}

	const thereAreDeliveryDelays: boolean = deliveryDelays?.length > 0

	return (
		<>
			<label>Délai de livraison</label>
			<CreatableSelect
				disabled={!thereAreDeliveryDelays}
				options={options}
				name={"deliveryDelay"}
				placeholder={
					thereAreDeliveryDelays
						? "Délai de livraison"
						: "Chargement ..."
				}
				value={options.find(
					(option) => option.value === form.deliveryDelay.value
				)}
				onChange={onChange}
			/>
		</>
	)
}
