export class StepPriority {
	priority: number
	name: string
	key: string
	color: string
	className: string
}

export const stepPriorities: StepPriority[] = [
	{
		priority: 0,
		name: "Relance",
		key: "R",
		color: "#006edc",
		className: "badge-info",
	},
	{
		priority: 1,
		name: "!",
		key: "!",
		color: "#ffeb00",
		className: "badge-secondary",
	},
	{
		priority: 2,
		name: "Impresion",
		key: "I",
		color: "#f8ac59",
		className: "badge-warning",
	},
	{
		priority: 3,
		name: "Fraisage",
		key: "F",
		color: "#f8ac59",
		className: "badge-warning",
	},
	{
		priority: 4,
		name: "Lamination",
		key: "L",
		color: "#f8ac59",
		className: "badge-warning",
	},
	{
		priority: 5,
		name: "!!",
		key: "!!",
		color: "#f8ac59",
		className: "badge-warning",
	},
	{
		priority: 6,
		name: "!!!",
		key: "!!!",
		color: "#e26153",
		className: "badge-danger",
	},
	{
		priority: 7,
		name: "Point dossier",
		key: "P",
		color: "#dc2f2f",
		className: "badge-danger",
	},
]
