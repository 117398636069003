import { ActionTypes } from "../../action.types"

import { createResource, resetResource, updateResource } from "../../action"

const { ERROR } = ActionTypes

export const CHANGE_PROPERTY = "CHANGE_PROPERTY",
	RESET = "RESET",
	ADD_CONTACT = "ADD_CONTACT",
	DELETE_CONTACT = "DELETE_CONTACT",
	CHANGE_PROPERTY_CONTACT = "CHANGE_PROPERTY_CONTACT"

export const changeProperty = (name, value) => (dispatch) => {
	dispatch({
		type: CHANGE_PROPERTY,
		name: name,
		value: value,
	})
}

export const customerToForm = (customer) => (dispatch) =>
	Object.keys(customer).forEach((property) => {
		if (property === "contacts") {
		} else if (property === "projects") {
		} else dispatch(changeProperty(property, customer[property]))
	})

export const submit =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		try {
			if (id === "creer")
				await dispatch(submitCreate(id, data, rawToken, callback))
			else await dispatch(submitUpdate(id, data, rawToken, callback))
		} catch (e) {
			dispatch({
				type: ERROR,
				e,
			})
			throw new Error(e)
		}
	}

export const submitCreate =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		await dispatch(
			submitAddress(id, data, rawToken, async (address) => {
				data.address =
					address !== undefined ? (data.address = address.id) : null
				await dispatch(submitContacts(data.contacts, rawToken)).then(
					async (contacts) => {
						const body = { ...data }
						body.contacts = contacts.map((contact) => contact.id)
						try {
							await dispatch(
								createResource("customers", body, rawToken)
							).then(() => {
								if (callback !== null) callback()
							})
						} catch (e) {
							throw new Error(e)
						}
					}
				)
			})
		)
	}

export const submitUpdate =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		await dispatch(
			submitAddress(id, data, rawToken, async (address) => {
				data.address =
					address !== undefined ? (data.address = address.id) : null
				await dispatch(submitContacts(data.contacts, rawToken)).then(
					async (contacts) => {
						const body = { ...data }
						body.contacts = contacts.map((contact) => contact.id)

						try {
							await dispatch(
								updateResource("customers", id, body, rawToken)
							).then(() => {
								if (callback !== null) callback()
							})
						} catch (e) {
							throw new Error(e)
						}
					}
				)
			})
		)
	}

export const submitAddress =
	(id, data, rawToken, callback = null) =>
	async (dispatch) => {
		if (data.address.id !== undefined && data.address.id !== null) {
			try {
				await dispatch(
					updateResource(
						"adresses",
						data.address.id,
						data.address,
						rawToken
					)
				).then((address) => callback(address))
			} catch (e) {
				throw new Error(e)
			}
		} else {
			try {
				await dispatch(
					createResource("adresses", data.address, rawToken)
				).then((address) => callback(address))
			} catch (e) {
				throw new Error(e)
			}
		}
	}

export const submitContacts = (contacts, rawToken) => (dispatch) => {
	return Promise.all(
		contacts.map(async (contact) => {
			if (contact.id !== undefined && contact.id !== null) {
				try {
					return await dispatch(
						updateResource(
							"contacts",
							contact.id,
							contact,
							rawToken
						)
					)
				} catch (e) {
					throw new Error(e)
				}
			} else {
				try {
					return await dispatch(
						createResource("contacts", contact, rawToken)
					)
				} catch (e) {
					throw new Error(e)
				}
			}
		})
	)
}

export const reset = () => async (dispatch) => {
	await dispatch(resetResource())
	return await dispatch({ type: RESET })
}

export const addContact = (form, contact) => async (dispatch) => {
	dispatch({
		type: ADD_CONTACT,
		form: form,
		contact: contact,
	})
}

export const deleteContact = (id) => (dispatch) => {
	dispatch({
		type: DELETE_CONTACT,
		id,
	})
}

export const changePropertyContact = (id, name, value) => (dispatch) => {
	dispatch({
		type: CHANGE_PROPERTY_CONTACT,
		id: id,
		name: name,
		value: value,
	})
}
