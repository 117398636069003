import React from "react"
import PropTypes from "prop-types"
import { isDefined } from "../../utils/Data"

export const SelectFilter = (props) => {
	const key = props.localName + "-filter"

	const onChangeFilter = (event, onChange) => {
		const value = event.target.value
		localStorage.setItem(key, value)
		onChange(value)
	}

	return (
		<select
			style={{ width: "100%" }}
			onChange={(event) => onChangeFilter(event, props.onChange)}
			value={props.filter ? props.filter.value : ""}
		>
			{props.showDefault ? (
				<option value={""}>{props.default}</option>
			) : (
				""
			)}
			{props.options.map((option, i) => (
				<option
					key={i}
					value={isDefined(option.value) ? option.value : option}
				>
					{isDefined(option.text) ? option.text : option}
				</option>
			))}
		</select>
	)
}

SelectFilter.propTypes = {
	localName: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	default: PropTypes.string,
	showDefault: PropTypes.bool,
	filter: PropTypes.object,
}

SelectFilter.defaultProps = {
	default: "",
	showDefault: true,
}
