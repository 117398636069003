import React, { useState } from "react"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import NewWindow from "react-new-window"

import "../../css/components/topbar.scss"
import { logout } from "../../redux/action"
import { toggleSidenav } from "../../redux/designAction"
import Calendar from "../../pages/calendar/Calendar"
import { title } from "../../security/layout"
import Menu from "./Menu"

const TopBar = () => {
	const [showCalendar, setShowCalendar] = useState(false)

	const dispatch = useDispatch()
	const { user } = useSelector(({ apiReducer }) => apiReducer)
	const { sidenavOpened } = useSelector(({ designReducer }) => designReducer)

	const handleLogout = () => dispatch(logout())
	const handleSidenavOpen = () => dispatch(toggleSidenav())
	const haveMessagesNotView = () =>
		user !== null ? user.messages.length > 0 : false

	const features = {
		height: "550",
		width: "1100",
		scrollbars: 1,
		menubar: 1,
		toolbar: 1,
		location: 1,
	}

	return (
		<div className="topbar">
			<div className="left-topbar">
				<button
					className="close-menu btn"
					onClick={handleSidenavOpen}
					title={
						sidenavOpened ? "Cacher le menu" : "Afficher le menu"
					}
				>
					<i className="fa fa-bars" />
				</button>

				<Menu />
			</div>

			<div className="right-topbar">
				<div className={"right-elements"}>
					<i
						className={"fas fa-calendar-alt"}
						title={"Ouvrir le calendrier"}
						onClick={() => setShowCalendar(true)}
					/>
				</div>
				<div className={"right-elements"}>
					<Link to={"/messages"}>
						<i
							className={
								"fas fa-envelope " +
								(haveMessagesNotView() ? "messages-active" : "")
							}
							title={"Ouvrir la messagerie"}
						/>
					</Link>
					<span className={"messages-notView"}>
						{haveMessagesNotView() ? user.messages.length : ""}
					</span>
				</div>
				<div className={"right-elements"}>
					<Link
						to={"/connexion"}
						onClick={handleLogout}
						title={"Se déconnecter"}
					>
						<i className="fa fa-sign-out-alt" /> Déconnexion
					</Link>
				</div>
			</div>

			{showCalendar === true ? (
				<NewWindow
					title={"Calendrier" + title}
					onUnload={() => setShowCalendar((prevState) => !prevState)}
					features={features}
				>
					<Calendar />
				</NewWindow>
			) : (
				""
			)}
		</div>
	)
}

export default withRouter(TopBar)
