import { GetAppVersionOutput } from "./get-app-version.output"

export class GetAppVersion {
	private _getAppVersionRepository: GetAppVersionOutput

	constructor(getAppVersionRepository: GetAppVersionOutput) {
		this._getAppVersionRepository = getAppVersionRepository
	}

	async execute(): Promise<string> {
		return await this._getAppVersionRepository.getNewAppVersion()
	}
}
