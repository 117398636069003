import React from "react"
import { useSelector } from "react-redux"

import RowContainer from "./RowContainer"

const Table = () => {
	const products = useSelector(
		({ orderFormReducer }) => orderFormReducer.data.productsOrders
	)

	return (
		<table className={"articles-list"}>
			<thead>
				<tr>
					<th>Référence</th>
					<th>Désignation</th>
					<th>Stock</th>
					<th>Format</th>
					<th>Surface</th>
					<th>Qte.</th>
					<th>Surf. tot.</th>
					<th>Prix unitaire</th>
					<th>Prix total</th>
				</tr>
			</thead>
			<tbody>
				{products.map((product) => (
					<RowContainer key={product?.id} product={product} />
				))}
			</tbody>
		</table>
	)
}

export default Table
