import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router"

import "../../css/form.scss"
import "../../css/pages/customers/form.scss"
import { getResource } from "../../redux/action"
import {
	changeProperty,
	resourceToForm,
	submit,
	reset,
} from "../../redux/actions/forms/repertoireFormAction"
import { getParams } from "../../utils/Data"
import Page from "../../components/Page/Page"

const RepertoireForm = ({ history }) => {
	const { rawToken, item } = useSelector(({ apiReducer }) => apiReducer)
	const { data } = useSelector(
		({ repertoireFormReducer }) => repertoireFormReducer
	)
	const dispatch = useDispatch()
	const { id } = getParams()
	const customer = item

	const textareaLastnameRef = useRef()
	const textareaNameRef = useRef()
	const textareaEmailRef = useRef()

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		textareaLastnameRef.current.style.height =
			textareaLastnameRef.current.scrollHeight + "px"
	}, [data.lastname])

	useEffect(() => {
		textareaNameRef.current.style.height =
			textareaNameRef.current.scrollHeight + "px"
	}, [data.society])

	useEffect(() => {
		textareaEmailRef.current.style.height =
			textareaEmailRef.current.scrollHeight + "px"
	}, [data.email])

	useEffect(() => {
		dispatch(reset())
		return () => {
			dispatch(reset())
		}
	}, [])

	useEffect(() => {
		setLoading(true)
		dispatch(reset())
		if (id !== "creer")
			dispatch(getResource("repertoireContacts", id, rawToken))
		else setLoading(false)
	}, [id])

	useEffect(() => {
		if (customer !== null) {
			dispatch(resourceToForm(customer))
			setLoading(false)
		}
	}, [customer])

	const onChange = (e) =>
		dispatch(changeProperty(e.target.name, e.target.value))

	const handleSubmit = (e) => {
		e.preventDefault()
		dispatch(submit(id, data, rawToken, () => history.goBack()))
	}

	return (
		<Page
			title={
				id !== "creer"
					? "Modification d'un contact du répertoire"
					: "Création d'un contact du répertoire"
			}
		>
			{loading ? (
				"Chargement"
			) : (
				<form onSubmit={handleSubmit} className={"repertoire-form"}>
					<div className={"form-block"}>
						<h2 className={"form-block-title"}>
							Informations personnelles
						</h2>

						<div className={"form-group row"}>
							<div className={"col-12 col-md-6 col-xl-2"}>
								<label>Prénom</label>
								<input
									type={"text"}
									placeholder={"Prénom"}
									className={"form-control"}
									name={"firstname"}
									value={data.firstname}
									onChange={onChange}
								/>
							</div>
							<div className={"col-12 col-md-6 col-xl-2"}>
								<label>Nom</label>
								<textarea
									placeholder={"Nom"}
									className={"form-control"}
									name={"lastname"}
									value={data.lastname}
									onChange={onChange}
									rows={1}
									ref={textareaLastnameRef}
									style={{
										height: textareaLastnameRef.current
											? textareaLastnameRef.current
													.scrollHeight + "px"
											: "auto",
									}}
								/>
							</div>
							<div className={"col-12 col-md-6 col-xl-2"}>
								<label>Entreprise</label>
								<textarea
									placeholder={"Entreprise"}
									className={"form-control"}
									name={"society"}
									value={data.society}
									onChange={onChange}
									rows={1}
									ref={textareaNameRef}
									style={{
										height: textareaNameRef.current
											? textareaNameRef.current
													.scrollHeight + "px"
											: "auto",
									}}
								/>
							</div>
							<div className={"col-12 col-md-6 col-xl-2"}>
								<label>Métier</label>
								<input
									type={"text"}
									placeholder={"Métier"}
									className={"form-control"}
									name={"job"}
									value={data.job}
									onChange={onChange}
								/>
							</div>
							<div className={"col-12 col-md-6 col-xl-2"}>
								<label>N° Téléphone</label>
								<input
									type={"text"}
									placeholder={"N° Téléphone"}
									className={"form-control"}
									name={"phone"}
									value={data.phone}
									onChange={onChange}
								/>
							</div>
							<div className={"col-12 col-md-6 col-xl-2"}>
								<label>Adresse mail</label>
								<textarea
									placeholder={"Adresse email"}
									className={"form-control"}
									name={"email"}
									value={data.email}
									onChange={onChange}
									rows={1}
									ref={textareaEmailRef}
									style={{
										height: textareaEmailRef.current
											? textareaEmailRef.current
													.scrollHeight + "px"
											: "auto",
									}}
								/>
							</div>
						</div>
					</div>

					<div className={"form-block"}>
						<h2 className={"form-block-title"}>Adresse</h2>

						<div className={"form-group row"}>
							<div className={"col-12 col-md-2 col-xl-1"}>
								<label>N°</label>
								<input
									type={"text"}
									placeholder={"N°"}
									className={"form-control"}
									name={"address.number"}
									value={data.address.number}
									onChange={onChange}
								/>
							</div>
							<div className={"col-12 col-md-10 col-xl-4"}>
								<label>Rue</label>
								<input
									type={"text"}
									placeholder={"Rue"}
									className={"form-control"}
									name={"address.street"}
									value={data.address.street}
									onChange={onChange}
								/>
							</div>
							<div className={"col-12 col-xl-2"}>
								<label>Complément</label>
								<input
									type={"text"}
									placeholder={"Complément"}
									className={"form-control"}
									name={"address.complement"}
									value={data.address.complement}
									onChange={onChange}
								/>
							</div>
							<div className={"col-12  col-md-2 col-xl-1"}>
								<label>Code Postal</label>
								<input
									type={"text"}
									placeholder={"Code Postal"}
									className={"form-control"}
									name={"address.postalCode"}
									value={data.address.postalCode}
									onChange={onChange}
								/>
							</div>
							<div className={"col- col-md-10 col-xl-4"}>
								<label>Ville</label>
								<input
									type={"text"}
									placeholder={"Ville"}
									className={"form-control"}
									name={"address.city"}
									value={data.address.city}
									onChange={onChange}
								/>
							</div>
						</div>
						<div className={"form-block"}>
							<h2 className={"form-block-title"}>Note</h2>
							<textarea
								placeholder={"Note"}
								className={"form-control"}
								name={"note"}
								value={data.note}
								onChange={onChange}
								rows={3}
							/>
						</div>
					</div>

					{id !== "creer" ? (
						<button className={"submitButton"} title={"Modifier"}>
							Modifier
						</button>
					) : (
						<button className={"submitButton"} title={"Créer"}>
							Créer
						</button>
					)}
				</form>
			)}
		</Page>
	)
}

export default withRouter(RepertoireForm)
