import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { getColumnsWidth, getDefaultFiltered } from "../../utils/List"
import { onDelete } from "../../utils/Alert"
import { deleteResource } from "../../redux/action"
import Table from "../../components/Table"

const RepertoireList = () => {
	const frenchName = "contacts"
	const localName = "repertoireContacts"
	const apiName = "repertoireContacts"

	let localNames = [
		"firstname",
		"lastname",
		"society",
		"job",
		"phone",
		"email",
		"address",
	]
	localNames = localNames.map((name) => localName + "-" + name)

	const dispatch = useDispatch()
	const { repertoireContacts, rawToken } = useSelector(
		({ apiReducer }) => apiReducer
	)

	const handleDelete = (id) =>
		onDelete("", "Etes-vous sûr de vouloir supprimer cet article ?", () =>
			dispatch(deleteResource(apiName, id, rawToken))
		)

	const columns = [
		{
			Header: "Entreprise",
			accessor: "society",
			width: getColumnsWidth(localNames[2], 250),
		},
		{
			Header: "Prénom",
			accessor: "firstname",
			width: getColumnsWidth(localNames[0], 200),
		},
		{
			Header: "Nom",
			accessor: "lastname",
			width: getColumnsWidth(localNames[1], 200),
		},
		{
			Header: "Métier",
			accessor: "job",
			width: getColumnsWidth(localNames[3], 200),
		},
		{
			Header: "Téléphone",
			accessor: "phone",
			width: getColumnsWidth(localNames[4], 120),
		},
		{
			Header: "Adresse mail",
			accessor: "email",
			width: getColumnsWidth(localNames[5], 300),
		},
		{
			Header: "Adresse",
			accessor: "address",
			width: getColumnsWidth(localNames[6], 400),
			sortable: false,
			filterable: false,
			Cell: ({ row }) => {
				const address = row._original.address
				return address !== null ? (
					<div>
						{address.number +
							" " +
							address.street +
							(address.postalCode !== "" || address.city !== ""
								? ", " + address.postalCode + " " + address.city
								: "")}
					</div>
				) : (
					""
				)
			},
		},
		{
			Header: "Actions",
			accessor: "actions",
			sortable: false,
			resizable: false,
			width: 100,
			Cell: ({ row }) => (
				<div className={"btn-flex"}>
					<Link to={"/repertoire/" + row._original.id + "/modifier"}>
						<div
							className="btn-circle btn-warning"
							title={"Modifier"}
						>
							<i className="fa fa-edit" />
						</div>
					</Link>
					<div
						className="btn-circle btn-danger"
						title={"Supprimer"}
						onClick={() => handleDelete(row._original.id)}
					>
						<i className="fa fa-trash" />
					</div>
				</div>
			),
		},
	]

	return (
		<Table
			name={localName + "-list"}
			data={repertoireContacts}
			columns={columns}
			frenchName={frenchName}
			localName={localName}
			apiName={apiName}
			defaultFiltered={getDefaultFiltered(localName, columns)}
			defaultSorted={[
				{
					id: "society",
					desc: false,
				},
			]}
		/>
	)
}

export default RepertoireList
