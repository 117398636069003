import React, { Component } from "react"
import ReactTable from "react-table"
import { connect } from "react-redux"
import Modal from "react-modal"

import "../../../css/components/modal.scss"
import HeaderBlock from "../../../components/HeaderBlock"
import {
	createResource,
	deleteResource,
	getResources,
	updateResource,
} from "../../../redux/action"
import { onDelete } from "../../../utils/Alert"

class Settlement extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show:
				localStorage.getItem("settings-settlement-show") !== null
					? JSON.parse(
							localStorage.getItem("settings-settlement-show")
					  )
					: true,
			settlementUpdated: null,
			modalIsOpen: false,
			form: {
				name: {
					value: "",
				},
			},
		}
	}

	componentDidMount() {
		this.props.dispatch(getResources("settlements", this.props.rawToken))
	}

	handleVisibility = () => {
		this.setState((prevState) => {
			return {
				show: !prevState.show,
			}
		})
		localStorage.setItem(
			"settings-settlement-show",
			this.state.show === true ? "false" : "true"
		)
	}

	handleDelete = (id) =>
		onDelete(
			"",
			"Etes-vous sûr de vouloir supprimer ce mode règlement ?",
			() =>
				this.props.dispatch(
					deleteResource("settlements", id, this.props.rawToken)
				)
		)

	handleOpenModal = (id = null) => {
		this.setState({
			modalIsOpen: true,
		})

		if (id !== null) {
			const settlement = this.props.settlements.find((settlement) => {
				return settlement.id === parseInt(id, 10)
			})

			if (settlement !== null)
				this.setState({
					settlementUpdated: settlement,
					form: {
						...this.state.form,
						name: {
							...this.state.form.name,
							value:
								settlement.name !== null ? settlement.name : "",
						},
					},
				})
		} else {
			this.setState({
				settlementUpdated: null,
				form: {
					...this.state.form,
					name: {
						...this.state.form.name,
						value: "",
					},
				},
			})
		}
	}

	handleCloseModal = () => {
		this.setState({ modalIsOpen: false })
	}

	changeHandler = (e, newName, newValue) => {
		const name = newName !== undefined ? newName : e.target.name
		let value = newValue !== undefined ? newValue : e.target.value
		this.setState({
			form: {
				...this.state.form,
				[name]: {
					...this.state.form[name],
					value,
				},
			},
		})
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		let body = {}
		Object.keys(this.state.form).map(
			(input) => (body[input] = this.state.form[input].value)
		)

		if (this.state.settlementUpdated === null) {
			try {
				await this.props
					.dispatch(
						createResource("settlements", body, this.props.rawToken)
					)
					.then(() => {
						this.setState({
							modalIsOpen: false,
						})
					})
			} catch (e) {}
		} else {
			try {
				await this.props
					.dispatch(
						updateResource(
							"settlements",
							this.state.settlementUpdated.id,
							body,
							this.props.rawToken
						)
					)
					.then(() => {
						this.setState({
							modalIsOpen: false,
						})
					})
			} catch (e) {}
		}
	}

	render() {
		const columns = [
			{
				Header: "Mode de règlement",
				accessor: "name",
				width:
					localStorage.getItem("settings-settlement-name") !== null
						? localStorage.getItem("settings-settlement-name")
						: 1650,
			},
			{
				Header: "Actions",
				accessor: "actions",
				width: 100,
				resizable: false,
				Cell: ({ row }) => {
					return (
						<div className={"btn-flex"}>
							<div
								className="btn-circle btn-warning"
								title={"Modifier"}
								onClick={() =>
									this.handleOpenModal(row._original.id)
								}
							>
								<i className="fa fa-edit" />
							</div>
							<div
								className="btn-circle btn-danger"
								title={"Supprimer"}
								onClick={() =>
									this.handleDelete(row._original.id)
								}
							>
								<i className="fa fa-trash" />
							</div>
						</div>
					)
				},
			},
		]

		return (
			<div className={"block"}>
				<HeaderBlock
					title={"Mode de règlements"}
					handleVisibility={this.handleVisibility}
					show={this.state.show}
					handleOpenModal={this.handleOpenModal}
					addButtonActive={true}
					addButtonTitle={"Ajouter un mode de règlement"}
				/>

				<ReactTable
					style={
						this.state.show === true
							? { display: "block" }
							: { display: "none" }
					}
					data={this.props.settlements}
					columns={columns}
					keyField={"id"}
					showPagination={true}
					showPageJump={true}
					sortable={true}
					multiSort={true}
					defaultPageSize={5}
					previousText={"Précédent"}
					nextText={"Suivant"}
					ofText={"sur"}
					rowsText={"modes de règlement"}
					loadingText={"Chargement ..."}
					noDataText={"Aucun mode de règlement disponibles"}
					onResizedChange={(newResized, event) => {
						newResized.map((newResize) =>
							localStorage.setItem(
								"settings-settlement-" + newResize.id,
								newResize.value
							)
						)
					}}
				/>

				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={() => this.handleCloseModal()}
					contentLabel="Example Modal"
					className="Modal"
					overlayClassName="Overlay"
					shouldCloseOnOverlayClick={true}
				>
					<div className="modal-header">
						<div className="modal-title">
							{this.state.settlementUpdated === null
								? "Créer"
								: "Modifier"}{" "}
							un mode de règlement
						</div>

						<div
							className="btn-circle btn-secondary modal-close"
							title={"Fermer"}
							onClick={() => this.handleCloseModal()}
						>
							<i className="fas fa-times" />
						</div>
					</div>

					<div className="modal-body">
						<form
							onSubmit={this.handleSubmit}
							className={"form-block"}
						>
							<div className={"row"}>
								<div className={"col-12"}>
									<input
										type={"name"}
										name={"name"}
										placeholder={"Intitulé"}
										value={this.state.form.name.value}
										onChange={this.changeHandler}
									/>
								</div>
							</div>
							<button
								title={
									this.state.settlementUpdated === null
										? "Créer"
										: "Modifier"
								}
							>
								{this.state.settlementUpdated === null
									? "Créer"
									: "Modifier"}
							</button>
						</form>
					</div>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
		settlements: apiReducer.settlements,
	}
}

export default connect(mapStateToProps)(Settlement)
