import React from "react"
import { Link } from "react-router-dom"

import "../../../css/pages/accounting/buttons.scss"

interface Props {
	disabled: boolean
	title: string
	link: string
}

export const RedirectBtn = ({ disabled, title, link }: Props) =>
	disabled ? (
		<div className="btn-circle btn-primary btn-goto disabled" title={title}>
			<i className={"fa fa-external-link-alt"} />
		</div>
	) : (
		<Link to={link} target="_blank">
			<div className="btn-circle btn-primary btn-goto" title={title}>
				<i className={"fa fa-external-link-alt"} />
			</div>
		</Link>
	)
