export const rgbToHex = (r, g, b) =>
	"#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)

export const hexToRgb = (hexa) => {
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
	hexa = hexa.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b
	})

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexa)
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null
}

export const setContrast = (hexa) => {
	const rgb = hexToRgb(hexa)
	const o = Math.round(
		(parseInt(rgb.r) * 299 +
			parseInt(rgb.g) * 587 +
			parseInt(rgb.b) * 114) /
			1000
	)
	return o > 125 ? "black" : "white"
}
