import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

export const FreeTextInput = ({
	id,
	value,
	type,
	number,
	className,
	update,
}) => {
	const [inputValue, setInputValue] = useState(value)

	useEffect(() => {
		setInputValue(value ? value : "")
	}, [])

	const onChangeFree = (e, id, number = 1, type = "") => {
		e.preventDefault()

		const value = e.target.value
		let body = { free: value }

		if (number === 2) body = { free2: value }
		else if (number === 3) body = { free3: value }

		setInputValue(value)
		update(type, body)
	}

	return (
		<input
			key={id}
			type={"text"}
			name={"free-" + id}
			value={inputValue}
			onChange={(e) => onChangeFree(e, id, number, type)}
			className={"sr-free-input " + className}
		/>
	)
}

FreeTextInput.propTypes = {
	id: PropTypes.number.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.string,
	number: PropTypes.number,
	className: PropTypes.string,
	update: PropTypes.func.isRequired,
}

FreeTextInput.defaultProps = {
	number: 1,
	type: "",
	className: "",
}
