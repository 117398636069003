import React from "react"
import DocumentTitle from "react-document-title"
import { title } from "../../../security/layout"

interface IProps {
	id: any
	entity: any
	action: any
	name: any
	localName: any
	handleVisibility?: any
	show: any
}

export const AccountingFormTitle = ({
	id,
	entity,
	action,
	name,
	localName,
	handleVisibility,
	show,
}: IProps) => {
	const renderTitle = () => {
		let render = "Modification"

		if (id !== null && id !== "creer") {
			if (entity !== null && action !== "devis") {
				if (action === "dupliquer")
					if (entity.drafted === null && entity.model === false)
						render = "Duplication d'un brouillon"
					else if (entity.model === true)
						render = "Duplication d'un modèle"
					else render = "Duplication d'" + name
				else if (entity.drafted === null && entity.model === false)
					render = "Modification d'un brouillon"
				else if (entity.model === true)
					render = "Modification d'un modèle"
				else render = "Modification d'" + name
			} else {
				if (action === "devis")
					render = "Création d'une facture à partir d'un devis"
			}
		} else if (name === "un devis") render = "Création d'un brouillon"
		else render = "Création d'" + name

		return render
	}

	return (
		<DocumentTitle title={renderTitle() + title}>
			<div className={"box-header"}>
				<div className={"title"}>{renderTitle()}</div>
				<div className={"btn-header-right"}>
					<div
						className="btn-circle btn-primary btn-hide"
						title={show === true ? "Cacher" : "Voir"}
						onClick={() => handleVisibility("show", localName)}
					>
						<i
							className={
								show === true ? "fa fa-eye-slash" : "fa fa-eye"
							}
						/>
					</div>
				</div>
			</div>
		</DocumentTitle>
	)
}
