import axios from "axios"

const httpClientConfig = {
	baseURL: process.env.REACT_APP_API_URI,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
}

export const httpClient = axios.create(httpClientConfig)

const onResponseSuccess = (response) => response

const onResponseFail = (error) => {
	return Promise.reject(renderError(error))
}

const renderError = (error) => {
	if (error.response) return error.response.data
	else if (error.request) return error.request.message
	else return error
}

httpClient.interceptors.request.use(
	async (config) => {
		const token: string | null = getJWT()
		if (token) config.headers.Authorization = `Bearer ${token}`
		return config
	},
	(error) => Promise.reject(error)
)

httpClient.interceptors.response.use(onResponseSuccess, onResponseFail)

const getJWT = (): string | null => {
	if (localStorage.getItem("jwt")) {
		const token = JSON.parse(localStorage.getItem("jwt"))
		const tokenExpiresAt = token.payload.exp
		const currentTimestamp = Date.now() / 1000
		const threshold = 300

		if (currentTimestamp + threshold < tokenExpiresAt) {
			return token.rawToken
		}
	}
	return null
}
