import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import { createResource, getResource, updateResource } from "../../redux/action"
import Page from "../../components/Page/Page"

class ArticleCategoriesForm extends Component {
	constructor(props) {
		super(props)

		const pathname = window.location.pathname
		const split = pathname.split("/")

		this.state = {
			id: split[2],
			articleCategory: null,
			form: {
				name: {
					value: "",
				},
			},
		}
	}

	componentDidMount() {
		if (this.state.id !== "creer") {
			this.props
				.dispatch(
					getResource(
						"articleCategories",
						this.state.id,
						this.props.rawToken
					)
				)
				.then((articleCategory) => {
					this.setState({
						articleCategory: articleCategory,
					})
					Object.keys(this.state.form).map((input) =>
						this.resourceToInput(input)
					)
				})
		}
	}

	resourceToInput = (input) => {
		this.setState({
			form: {
				...this.state.form,
				[input]: {
					...this.state.form[input],
					value:
						this.state.articleCategory[input] !== null
							? this.state.articleCategory[input]
							: "",
				},
			},
		})
	}

	changeHandler = (e, newName, newValue) => {
		const name = newName !== undefined ? newName : e.target.name
		let value = newValue !== undefined ? newValue : e.target.value

		let split = name.split(".")
		if (split.length > 1) {
			this.setState({
				form: {
					...this.state.form,
					[split[0]]: {
						...this.state.form[split[0]],
						[split[1]]: {
							...this.state.form[split[0]][split[1]],
							value,
						},
					},
				},
			})
		} else {
			this.setState({
				form: {
					...this.state.form,
					[name]: {
						...this.state.form[name],
						value,
					},
				},
			})
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		let body = {}
		Object.keys(this.state.form).map(
			(input) => (body[input] = this.state.form[input].value)
		)

		if (this.state.id === "creer") {
			try {
				await this.props
					.dispatch(
						createResource(
							"articleCategories",
							body,
							this.props.rawToken
						)
					)
					.then(() => this.props.history.goBack())
			} catch (e) {}
		} else {
			try {
				await this.props
					.dispatch(
						updateResource(
							"articleCategories",
							this.state.id,
							body,
							this.props.rawToken
						)
					)
					.then(() => this.props.history.goBack())
			} catch (e) {}
		}
	}

	render() {
		return (
			<Page
				title={
					(this.state.id !== "creer" ? "Modification " : "Création") +
					" d'une catégorie d'articles"
				}
			>
				<form
					onSubmit={this.handleSubmit}
					className={"form-block form-group"}
				>
					<div className={"row"}>
						<div className={"col-12"}>
							<label>Désignation</label>
							<input
								type={"text"}
								name={"name"}
								placeholder={"Désignation"}
								value={this.state.form.name.value}
								onChange={this.changeHandler}
							/>
						</div>
					</div>

					{this.state.id !== "creer" ? (
						<button title={"Modifier"}>Modifier</button>
					) : (
						<button title={"Créer"}>Créer</button>
					)}
				</form>
			</Page>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
	}
}

export default withRouter(connect(mapStateToProps)(ArticleCategoriesForm))
