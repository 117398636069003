import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"

import "../css/components/table.scss"
import { isDefined } from "../utils/Data"

const SimpleTable = (props) => {
	const [rowSelected, setRowSelected] = useState(-1)

	const getTrProps = (state, rowInfo) => {
		if (typeof rowInfo !== "undefined") {
			let colorOfRowByAPI = null
			if (
				typeof rowInfo.row._original.color !== "undefined" &&
				rowInfo.row._original.color !== null
			)
				colorOfRowByAPI = rowInfo.row._original.color.hexa

			return {
				onClick: (e, handleOriginal) => {
					setRowSelected(rowInfo.index)
					localStorage.setItem(
						props.localName + "-rowSelected",
						rowInfo.index
					)
					if (handleOriginal) {
						handleOriginal()
					}
				},
				style: {
					border:
						rowInfo.index === rowSelected ||
						rowInfo.index ===
							parseInt(
								localStorage.getItem(
									props.localName + "-rowSelected"
								)
							)
							? "4px solid orange"
							: "4px solid transparent",
					background:
						colorOfRowByAPI !== null ? colorOfRowByAPI : "#FFFFFF",
				},
			}
		} else {
			return {
				onClick: (e, handleOriginal) => {
					if (handleOriginal) {
						handleOriginal()
					}
				},
				style: {
					background: "#FFFFFF",
					color: "#000000",
				},
			}
		}
	}

	return (
		<ReactTable
			data={props.data}
			columns={props.columns}
			filterable={isDefined(props.filterable) ? props.filterable : true}
			sortable={isDefined(props.sortable) ? props.sortable : true}
			multiSort={isDefined(props.multiSort) ? props.multiSort : true}
			keyField={isDefined(props.keyField) ? props.keyField : "id"}
			defaultPageSize={
				isDefined(props.defaultPageSize) ? props.defaultPageSize : 20
			}
			previousText={"Précédent"}
			nextText={"Suivant"}
			loadingText={"Chargement ..."}
			noDataText={"Aucun " + props.frenchName + " disponibles"}
			ofText={"sur"}
			rowsText={props.frenchName}
			style={isDefined(props.style) ? props.style : {}}
			showPagination={
				isDefined(props.showPagination) ? props.showPagination : true
			}
			showPageJump={
				isDefined(props.showPageJump) ? props.showPageJump : true
			}
			defaultSorted={
				isDefined(props.defaultSorted)
					? props.defaultSorted
					: [{ id: "id", desc: true }]
			}
			defaultFilterMethod={(filter, row) =>
				String(row[filter.id])
					.toLowerCase()
					.includes(filter.value.toLowerCase())
			}
			onResizedChange={(newResized) =>
				newResized.map((newResize) =>
					localStorage.setItem(
						props.localName + "-" + newResize.id,
						newResize.value
					)
				)
			}
			getTrProps={getTrProps}
		/>
	)
}

SimpleTable.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	frenchName: PropTypes.string.isRequired,
	localName: PropTypes.string.isRequired,
	filterable: PropTypes.bool,
	sortable: PropTypes.bool,
	multiSort: PropTypes.bool,
	keyField: PropTypes.string,
	defaultPageSize: PropTypes.number,
	style: PropTypes.object,
	showPagination: PropTypes.bool,
	showPageJump: PropTypes.bool,
	defaultSorted: PropTypes.array,
}

export default SimpleTable
