import React from "react"
import PropTypes from "prop-types"
import "../../css/components/table2.scss"

const Table = ({ className, head, body, foot }) => {
	return (
		<table className={"sr-table " + className}>
			<thead>{head}</thead>
			<tbody>{body}</tbody>
			<tfoot>{foot}</tfoot>
		</table>
	)
}

Table.propTypes = {
	children: PropTypes.string,
	head: PropTypes.node,
	body: PropTypes.node,
	foot: PropTypes.node,
}

export default Table
