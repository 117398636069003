import {
	ADD_CONTACT,
	CHANGE_PROPERTY,
	CHANGE_PROPERTY_CONTACT,
	DELETE_CONTACT,
	RESET,
} from "../../actions/forms/customerFormAction"

const initialState = {
	data: {
		code: 0,
		name: "",
		phone: "",
		homePhone: "",
		fax: "",
		email: "",
		address: {
			number: "",
			street: "",
			postalCode: "",
			city: "",
			complement: "",
		},
		contacts: [],
	},
	contactFormsId: 0,
	contactsForms: [],
	contactForm: {
		contactFormsId: null,
		lastname: "",
		firstname: "",
		email: "",
		homePhone: "",
		phone: "",
		fax: "",
	},
}

const customerFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_PROPERTY:
			const split = action.name.split(".")

			if (action.name === "code") action.value = parseInt(action.value)
			else if (action.name === "address")
				if (action.value === null)
					action.value = initialState.data.address

			if (split.length > 1) {
				return {
					...state,
					data: {
						...state.data,
						[split[0]]: {
							...state.data[split[0]],
							[split[1]]: action.value,
						},
					},
				}
			} else {
				return {
					...state,
					data: {
						...state.data,
						[action.name]: action.value,
					},
				}
			}
		case RESET:
			return initialState
		case ADD_CONTACT:
			const contact =
				action.contact !== null
					? {
							...action.contact,
							contactFormsId: state.contactFormsId,
					  }
					: {
							...initialState.contactForm,
							contactFormsId: state.contactFormsId,
					  }
			return {
				...state,
				data: {
					...state.data,
					contacts: [...state.data.contacts, contact],
				},
				contactsForms: [...state.contactsForms, action.form],
				contactFormsId: state.contactFormsId + 1,
			}
		case DELETE_CONTACT:
			return {
				...state,
				contactsForms: [
					...state.contactsForms.filter(
						(form) => form.props.id !== action.id
					),
				],
				data: {
					...state.data,
					contacts: [
						...state.data.contacts.filter(
							(contact) => contact.contactFormsId !== action.id
						),
					],
				},
			}
		case CHANGE_PROPERTY_CONTACT:
			return {
				...state,
				data: {
					...state.data,
					contacts: [
						...state.data.contacts.map((contact) => {
							if (contact.contactFormsId === action.id)
								return {
									...contact,
									[action.name]: action.value,
								}
							else return contact
						}),
					],
				},
			}
		default:
			return state
	}
}

export default customerFormReducer
