import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import {
	addChildToParent,
	deleteResource,
	getResources,
	updateResource,
} from "../../redux/action"
import Table from "../../components/Table"
import ColorPickerList from "../../components/ColorPickerList"
import { getColumnsWidth, getDefaultFiltered } from "../../utils/List"
import { TextFilter, FreeTextInput } from "../../components/List/index"
import { onDelete } from "../../utils/Alert"

const frenchName = "produits"
const localName = "product"
const apiName = "book_products"

let localNames = [
	"reference",
	"code",
	"free",
	"free2",
	"free3",
	"provider.name",
	"type.name",
	"thickness",
	"format",
	"surface",
	"designation",
	"unitPrice",
	"unity",
	"marginCoefficient",
]
localNames = localNames.map((name) => localName + "-" + name)

const ProductsList = ({ addButton, handleAdd, dontLoadDataOnMount }) => {
	const dispatch = useDispatch()
	const { products, colors, rawToken } = useSelector(
		({ apiReducer }) => apiReducer
	)

	const columns = useMemo(
		() => [
			{
				Header: "Reférence",
				accessor: "reference",
				width: getColumnsWidth(localNames[0], 90),
			},
			{
				Header: "Code",
				accessor: "code",
				width: getColumnsWidth(localNames[1], 80),
			},
			{
				Header: "Sem",
				accessor: "free",
				width: getColumnsWidth(localNames[2], 60),
				Cell: ({ row }) => {
					const id = row._original.id
					return (
						<FreeTextInput
							key={id}
							value={row._original.free}
							id={id}
							update={(type, body) => {
								dispatch(
									updateResource(
										"products" + type,
										id,
										body,
										rawToken
									)
								)
							}}
						/>
					)
				},
			},
			{
				Header: "Sem2",
				accessor: "free2",
				width: getColumnsWidth(localNames[3], 60),
				Cell: ({ row }) => {
					const id = row._original.id
					return (
						<FreeTextInput
							key={id}
							value={row._original.free2}
							id={id}
							number={2}
							update={(type, body) => {
								dispatch(
									updateResource(
										"products" + type,
										id,
										body,
										rawToken
									)
								)
							}}
						/>
					)
				},
			},
			{
				Header: "Sem3",
				accessor: "free3",
				width: getColumnsWidth(localNames[4], 60),
				Cell: ({ row }) => {
					const id = row._original.id
					return (
						<FreeTextInput
							key={id}
							value={row._original.free3}
							id={id}
							number={3}
							update={(type, body) => {
								dispatch(
									updateResource(
										"products" + type,
										id,
										body,
										rawToken
									)
								)
							}}
						/>
					)
				},
			},
			{
				Header: "Fournisseur",
				accessor: "provider.name",
				width: getColumnsWidth(localNames[5], 200),
			},
			{
				Header: "Type",
				accessor: "type.name",
				width: getColumnsWidth(localNames[6], 120),
			},
			{
				Header: "Epaisseur",
				accessor: "thickness",
				width: getColumnsWidth(localNames[7], 80),
			},
			{
				Header: "Format",
				accessor: "format",
				width: getColumnsWidth(localNames[8], 150),
			},
			{
				Header: "Surface",
				accessor: "surface",
				width: getColumnsWidth(localNames[9], 80),
			},
			{
				Header: "Désignation",
				accessor: "designation",
				width: getColumnsWidth(localNames[10], 450),
			},
			{
				Header: "Prix Unitaire",
				accessor: "unitPrice",
				width: getColumnsWidth(localNames[11], 80),
				Cell: ({ row }) => {
					const unitPrice = parseFloat(row._original.unitPrice) || 0
					const mlPrice = parseFloat(row._original.mlPrice) || 0
					const m2Price = parseFloat(row._original.squarePrice) || 0

					return (
						<div style={{ textAlign: "right" }}>
							{unitPrice > 0
								? unitPrice.toFixed(2) + " €"
								: mlPrice > 0
								? mlPrice.toFixed(2) + " €"
								: m2Price > 0
								? m2Price.toFixed(2) + " €"
								: ""}
						</div>
					)
				},
			},
			{
				Header: "Unité",
				accessor: "unity",
				width: getColumnsWidth(localNames[12], 80),
				Cell: ({ row }) => {
					const unitPrice = row._original.unitPrice
					const mlPrice = row._original.mlPrice
					const m2Price = row._original.squarePrice

					return unitPrice !== null
						? "Unitaire"
						: mlPrice !== null
						? "mL"
						: m2Price !== null
						? "m²"
						: ""
				},
			},
			{
				Header: "Coefficient",
				accessor: "marginCoefficient",
				width: getColumnsWidth(localNames[13], 80),
			},
			{
				Header: "Actions",
				accessor: "id",
				sortable: false,
				resizable: false,
				width: 150,
				Cell: ({ row }) => (
					<div className={"btn-flex"}>
						{addButton === true ? (
							<div
								className="btn-circle btn-primary"
								title={"Ajouter"}
								onClick={() => handleAdd(row._original.id)}
							>
								<i className="fas fa-upload" />
							</div>
						) : (
							""
						)}
						<div
							className="btn-circle btn-color"
							title={"Mettre en couleur"}
							onClick={() => handleColor(row._original.id)}
						>
							<i className="fa fa-tint" />
						</div>
						<Link
							to={"/produits/" + row._original.id + "/modifier"}
						>
							<div
								className="btn-circle btn-warning"
								title={"Modifier"}
							>
								<i className="fa fa-edit" />
							</div>
						</Link>
						<div
							className="btn-circle btn-danger"
							title={"Supprimer"}
							onClick={() => handleDelete(row._original.id)}
						>
							<i className="fa fa-trash" />
						</div>
					</div>
				),
			},
		],
		[]
	)

	useEffect(() => {
		dispatch(getResources("colors", rawToken))
		columns.forEach((column, i) => {
			if (i < columns.length)
				column.Filter = ({ filter, onChange }) => (
					<TextFilter
						localName={localNames[i]}
						filter={filter}
						onChange={onChange}
					/>
				)
		})
	}, [])

	const [rowOpenColor, setRowOpenColor] = useState(null)
	const [displayColorPicker, setDisplayColorPicker] = useState(false)

	const handleColor = (id = null) => {
		setRowOpenColor(id)
		setDisplayColorPicker((prev) => !prev)
	}

	const selectColor = (color = null) => {
		const colorApi = colors.find((item) => item.hexa === color.hex)
		if (typeof colorApi !== "undefined")
			dispatch(
				addChildToParent(
					rowOpenColor,
					colorApi.id,
					"products",
					"colors",
					rawToken
				)
			)
		handleColor()
	}

	const handleDelete = (id) =>
		onDelete("", "Etes-vous sûr de vouloir supprimer ce produit ?", () =>
			dispatch(deleteResource(apiName, id, rawToken))
		)

	return (
		<>
			<ColorPickerList
				display={displayColorPicker}
				handleClose={() => handleColor()}
				colors={colors.map((color) => color.hexa)}
				handleChange={(color) => selectColor(color)}
			/>

			<Table
				name={localName + "-list"}
				data={products}
				columns={columns}
				frenchName={frenchName}
				localName={localName}
				apiName={apiName}
				defaultFiltered={getDefaultFiltered(localName, columns)}
				defaultSorted={[
					{
						id: "reference",
						desc: false,
					},
				]}
				dontLoadDataOnMount={dontLoadDataOnMount}
			/>
		</>
	)
}

export default ProductsList
