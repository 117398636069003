import React from "react"
import { CirclePicker } from "react-color"

import "../css/components/color.scss"

const ColorPickerList = ({ handleClose, handleChange, display, colors }) => {
	const popover = {
		position: "fixed",
		zIndex: "2",
		top: 0,
		left: "50%",
		transform: "translateX(-50%)",
	}

	const cover = {
		position: "fixed",
		top: "0px",
		right: "0px",
		bottom: "0px",
		left: "0px",
	}

	return display ? (
		<div style={popover}>
			<div style={cover} onClick={handleClose} />
			<CirclePicker onChange={handleChange} colors={colors} />
		</div>
	) : (
		""
	)
}

export default ColorPickerList
