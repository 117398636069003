import React, { Component } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

import { deleteResource, specialPut } from "../../redux/action"
import { isDefined } from "../../utils/Data"
import { setContrast } from "../../utils/Color"
import { StepPriorityText } from "./step-priority-text"

class CalendarStep extends Component {
	handleMade = (id) =>
		this.props.dispatch(
			specialPut(id, this.props.rawToken, "steps", "made")
		)

	handleDelete = async (id) => {
		try {
			await this.props.dispatch(
				deleteResource("steps", id, this.props.rawToken)
			)
		} catch (e) {}
	}

	removeOneDay = (id) =>
		this.props.dispatch(
			specialPut(id, this.props.rawToken, "steps", "removeOneDay")
		)

	addOneDay = (id) =>
		this.props.dispatch(
			specialPut(id, this.props.rawToken, "steps", "addOneDay")
		)

	moveUp = (id) => this.props.changePosition(id)

	moveDown = (id) => this.props.changePosition(id, false)

	getStyle = (user) => {
		return {
			backgroundColor: isDefined(user.color)
				? user.color.hexa
				: "transparent",
			color: setContrast(
				isDefined(user.color) ? user.color.hexa : "#ffffff"
			),
			fontSize: ".9em",
		}
	}

	render() {
		const step = this.props.step
		const users = step.users
		const project = step.project

		return (
			<td
				className={"step"}
				style={{
					backgroundColor: step.made !== null ? "#e3ffde" : "#ffffff",
				}}
			>
				<div className={"topStep"}>
					<div className={"left"}>
						{users.map((user, i) => (
							<div key={i} className={"user"}>
								<span
									className="badge"
									style={this.getStyle(user)}
								>
									{user.firstname} :{" "}
									{step.hours !== null ? step.hours : "0"} h
								</span>
							</div>
						))}
					</div>
					<div className={"right"}>
						<div className={"btn-flex"}>
							<div
								className="btn-circle btn-primary btn-arrow btn-up"
								title={"Déplacer vers le haut"}
								onClick={() => this.moveUp(step.id)}
							>
								<i className="fa fa-arrow-up" />
							</div>
							<div
								className="btn-circle btn-primary btn-arrow btn-down"
								title={"Déplacer vers le bas"}
								onClick={() => this.moveDown(step.id)}
							>
								<i className="fa fa-arrow-down" />
							</div>
							<div
								className="btn-circle btn-primary btn-arrow btn-left"
								title={"Décaler à la veille"}
								onClick={() => this.removeOneDay(step.id)}
							>
								<i className="fa fa-arrow-left" />
							</div>
							<div
								className="btn-circle btn-primary btn-arrow btn-right"
								title={"Décaler au lendemain"}
								onClick={() => this.addOneDay(step.id)}
							>
								<i className="fa fa-arrow-right" />
							</div>
							<div
								className="btn-circle btn-success"
								title={"Marquer comme réalisée"}
								onClick={() => this.handleMade(step.id)}
							>
								<i className="fa fa-check" />
							</div>
							<div
								className="btn-circle btn-danger"
								title={"Supprimer cette tâche"}
								onClick={() => this.handleDelete(step.id)}
							>
								<i className="fa fa-trash" />
							</div>
						</div>
					</div>
				</div>
				<div className={"contentStep"}>
					<StepPriorityText priority={step.priority} />
					{isDefined(project) ? (
						<div className={"project"}>
							{isDefined(project.customer) ? (
								<Link
									to={
										"/clients/" +
										project.customer.id +
										"/modifier"
									}
								>
									{project.customer.name}
								</Link>
							) : (
								""
							)}
							<div>
								<Link to={"/projets/" + project.id}>
									{project.name + " (" + project.number + ")"}
								</Link>
							</div>
						</div>
					) : (
						""
					)}
					<div>{step.name}</div>
				</div>
			</td>
		)
	}
}

const mapStateToProps = ({ apiReducer }) => {
	return {
		rawToken: apiReducer.rawToken,
	}
}

export default withRouter(connect(mapStateToProps)(CalendarStep))
