import { isDefined } from "./Data"

/**
 * Get total price
 * @param totalPrice
 * @return {number}
 */
export const getTotalPrice = (totalPrice) =>
	isDefined(totalPrice) ? totalPrice : 0

/**
 * Get TVA
 * @param totalPrice
 * @param vat
 * @return {number}
 */
export const getTva = (totalPrice, vat) => {
	const total = getTotalPrice(totalPrice)
	const vatRate = vat !== null ? vat : 20 / 100
	return total && total !== 0 ? parseFloat(total * vatRate) : 0
}

/**
 * Get amount TTC
 * @param totalPrice
 * @param vat
 * @return {number}
 */
export const getAmountTTC = (totalPrice, vat) => {
	const total = getTotalPrice(totalPrice)
	const tva = getTva(totalPrice, vat)
	return parseFloat(total + tva)
}

/**
 * Get advance
 * @param advance
 * @return {number}
 */
export const getAdvance = (advance) =>
	isDefined(advance) && advance !== "0" ? parseFloat(advance) : 0

/**
 * Get price to paid
 * @param totalPrice
 * @param advance
 * @param paid
 * @param vat
 * @return {number}
 */
export const getPriceToPaid = (totalPrice, advance, paid, vat) => {
	const advanceFormated = getAdvance(advance)
	const amountTTC = getAmountTTC(totalPrice, vat)
	return isDefined(paid) ? parseFloat("0.00") : amountTTC - advanceFormated
}
