import { UpdateAppVersionOutput } from "./update-app-version.output"

export class UpdateAppVersion {
	private _updateAppVersionRepository: UpdateAppVersionOutput

	constructor(updateAppVersionRepository: UpdateAppVersionOutput) {
		this._updateAppVersionRepository = updateAppVersionRepository
	}

	execute(version: string): void {
		this._updateAppVersionRepository.updateAppVersion(version)
	}
}
