import { isDefined } from "./Data"

export const getDefaultFiltered = (localName, columns) => {
	let defaultFilterd = []

	columns.forEach((column) => {
		const key = localName + "-" + column.accessor + "-filter"
		if (isDefined(localStorage.getItem(key)))
			defaultFilterd = [
				...defaultFilterd,
				{
					id: column.accessor,
					value: localStorage.getItem(key),
				},
			]
	})

	return defaultFilterd
}

export const getColumnsWidth = (localKey, value) =>
	isDefined(localStorage.getItem(localKey))
		? localStorage.getItem(localKey)
		: value

export const getScrollPosition = (element) => {
	if (typeof element === "undefined" && element === null) return 0
	else return element !== null ? document.querySelector(element).scrollTop : 0
}
