import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { onDelete } from "../../../utils/Alert"
import {
	addProductToRow,
	changeProduct,
	deleteProduct,
} from "../../../redux/actions/forms/orderFormAction"
import { getResource, getResourceByProperty } from "../../../redux/action"
import { RootState } from "../../../redux/reducer"
import { ProductRowView } from "./ProductRow.view"

const ProductRowContainer = ({
	article,
	loadingProjects,
	setShowColorPicker,
	setArticleOpenedColorPicker,
}) => {
	const dispatch = useDispatch()
	const { numbers, products, rawToken }: any = useSelector(
		({ apiReducer }: RootState) => apiReducer
	)

	const [totalSurface, setTotalSurface] = useState("")
	const [total, setTotal] = useState(0)

	useEffect(() => {
		setTotalSurface(
			(
				(parseFloat(article.surface) || 0) *
				(parseFloat(article.quantity) || 0)
			).toFixed(2)
		)
	}, [article.surface, article.quantity])

	useEffect(() => {
		const total =
			(parseFloat(totalSurface) || 0) *
			(parseFloat(article.unitPrice) || 0)
		setTotal(total)
	}, [totalSurface, article.unitPrice])

	const onDeleteProduct = (productID) => {
		onDelete("", "Etes-vous sûr de vouloir supprimer cet article ?", () => {
			dispatch(deleteProduct(productID))
		})
	}

	const onChange = (e) => {
		dispatch(changeProduct(article.id, e.target.name, e.target.value))
	}

	const onChangeStock = async (e, name?: string, value?: string) => {
		const projectID: string = await getResourceByProperty(
			"projects",
			"number",
			value,
			rawToken
		)
		if (projectID) {
			const project = await dispatch(
				getResource("projects", projectID, rawToken)
			)
			dispatch(changeProduct(article.id, "project", project))
		}
	}

	const onChangeArticleColor = () => {
		setShowColorPicker(true)
		setArticleOpenedColorPicker(article)
	}

	const onChangeReference = (event, name, value) => {
		const product = products.find((product) => product.reference === value)
		if (product) dispatch(addProductToRow(product, null, article.id))
	}

	return (
		<ProductRowView
			article={article}
			loadingProjects={loadingProjects}
			products={products}
			onChangeFunctions={{
				onChangeReference,
				onChange,
				onChangeStock,
				onChangeArticleColor,
			}}
			numbers={numbers}
			total={total}
			totalSurface={totalSurface}
			onDeleteProduct={onDeleteProduct}
		/>
	)
}

export default ProductRowContainer
