import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router"

import { getResource } from "../../redux/action"
import {
	changeProperty,
	resourceToForm,
	submit,
	reset,
} from "../../redux/actions/forms/permissionFormAction"
import { getParams } from "../../utils/Data"
import Page from "../../components/Page/Page"

const PermissionForm = ({ history }) => {
	const dispatch = useDispatch()

	const { rawToken, item } = useSelector(({ apiReducer }) => apiReducer)
	const { data } = useSelector(
		({ customerFormReducer }) => customerFormReducer
	)

	const { id } = getParams()
	const permission = item

	useEffect(() => {
		return () => {
			dispatch(reset())
		}
	}, [])

	useEffect(() => {
		if (id !== "creer") {
			dispatch(getResource("permissions", id, rawToken))
		}
	}, [])

	useEffect(() => {
		if (permission !== null) {
			dispatch(resourceToForm(permission))
		}
	}, [permission])

	const onChange = (e) =>
		dispatch(changeProperty(e.target.name, e.target.value))

	const handleSubmit = (e) => {
		e.preventDefault()
		dispatch(submit(id, data, rawToken, () => history.goBack()))
	}

	return (
		<Page
			title={
				id !== "creer"
					? "Modification d'une permission"
					: "Création d'une permission"
			}
		>
			<form onSubmit={handleSubmit} className={"form-block form-group"}>
				<div className={"row"}>
					<div className={"col-12 col-lg-3"}>
						<label>Clé de la permission</label>
						<input
							type={"text"}
							name={"name"}
							placeholder={"Clé de la permission"}
							value={data.name}
							onChange={onChange}
						/>
					</div>
					<div className={"col-12 col-lg-3"}>
						<label>Description</label>
						<input
							type={"text"}
							name={"description"}
							placeholder={"Description"}
							value={data.description}
							onChange={onChange}
						/>
					</div>
				</div>

				{id !== "creer" ? (
					<button className={"submitButton"} title={"Modifier"}>
						Modifier
					</button>
				) : (
					<button className={"submitButton"} title={"Créer"}>
						Créer
					</button>
				)}
			</form>
		</Page>
	)
}

export default withRouter(PermissionForm)
