import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

const Note = ({ onChangeNote, note }) => {
	const [value, setValue] = useState(note)

	useEffect(() => {
		setValue(note)
	}, [note])

	const onChange = (e) => {
		setValue(e.target.value)
		onChangeNote(e.target.value)
	}

	return (
		<div className={"customer-note"}>
			<h3>Note</h3>
			<textarea
				onChange={onChange}
				value={value}
				placeholder={"Cliquez-ici pour écrire une note"}
			/>
		</div>
	)
}

Note.propTypes = {
	onChangeNote: PropTypes.func.isRequired,
	note: PropTypes.string.isRequired,
}

export default Note
