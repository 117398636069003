import React from "react"
import ReactLoading from "react-loading"

import OrdersArticlesModal from "./OrdersArticlesModal"
import { MyAutosuggest } from "../../../components/MyAutosuggest"

export const ProductRowView = ({
	onChangeFunctions,
	article,
	loadingProjects,
	products,
	numbers,
	total,
	totalSurface,
	onDeleteProduct,
}) => {
	const { onChange, onChangeReference, onChangeStock, onChangeArticleColor } =
		onChangeFunctions
	const projectNumber = article.project?.number ? article.project.number : ""

	return (
		<tr key={article.id} id={`article-${article.id}`} className={"article"}>
			<td className={"ref-cell cell-input"}>
				<div className={"ref-container"}>
					<OrdersArticlesModal row={article.id} />
					<MyAutosuggest
						disabled={products.length <= 0}
						searchWithoutName={true}
						items={products.map((product) => product.reference)}
						name={"reference"}
						placeholder={
							products.length <= 0
								? "Chargement ..."
								: "Référence"
						}
						value={article.reference}
						onChange={onChangeReference}
					/>
				</div>
			</td>
			<td className={"cell-input"}>
				<textarea
					value={article.designation}
					onChange={onChange}
					name={"designation"}
				/>
				<div className={"customer-name"}>
					{article.project?.customer?.name &&
						`Client : ${article.project.customer.name}`}
				</div>
			</td>
			<td className={"cell-input stock-cell"}>
				{loadingProjects ? (
					<ReactLoading
						type={"spin"}
						color={"#f8ac59"}
						height={25}
						width={25}
						className={"sr-loading"}
					/>
				) : (
					<MyAutosuggest
						key={`stock-${article.id}`}
						searchWithoutName={true}
						items={numbers?.projects?.reverse()}
						name={"stock"}
						placeholder={"N° Projet"}
						value={projectNumber}
						onChange={onChangeStock}
					/>
				)}
			</td>
			<td className={"cell-input medium-cell format-cell"}>
				<input
					type={"text"}
					name={"format"}
					value={article.format}
					onChange={onChange}
				/>
			</td>
			<td className={"cell-input small-cell text-right"}>
				<input
					type={"number"}
					min={0}
					step={0.01}
					name={"surface"}
					value={article.surface}
					onChange={onChange}
				/>
			</td>
			<td className={"cell-input small-cell text-right"}>
				<input
					type={"number"}
					min={0}
					step={0.01}
					name={"quantity"}
					value={article.quantity}
					onChange={onChange}
				/>
			</td>
			<td className={"medium-cell text-right"}>{totalSurface}</td>
			<td className={"cell-input small-cell text-right"}>
				<input
					type={"number"}
					min={0}
					step={0.01}
					name={"unitPrice"}
					value={article.unitPrice}
					onChange={onChange}
				/>
			</td>
			<td className={"total-cell text-right"}>
				{total.toFixed(2) + " €"}
			</td>
			<td
				style={{
					backgroundColor: article.color,
				}}
			>
				<div className={"flex"}>
					<div
						className="btn-circle btn-color"
						title={"Mettre en couleur"}
						onClick={() => onChangeArticleColor()}
					>
						<i className="fa fa-tint" />
					</div>
					<div
						className={"delete-article btn-circle btn-danger"}
						title={"Supprimer l'article"}
						onClick={() => onDeleteProduct(article.id)}
					>
						<i className="fas fa-trash-alt" />
					</div>
				</div>
			</td>
		</tr>
	)
}
