import React, { useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "../../css/pages/articles/list.scss"
import {
	deleteResource,
	getResources,
	updateResource,
} from "../../redux/action"
import Table from "../../components/Table"
import {
	TextFilter,
	SelectFilter,
	FreeTextInput,
} from "../../components/List/index"
import { getColumnsWidth, getDefaultFiltered } from "../../utils/List"
import { renderIfDefined } from "../../utils/Data"
import { isDefined } from "../../utils/Data"
import { onDelete } from "../../utils/Alert"

const frenchName = "articles"
const localName = "articles"
const apiName = "book_articles"

let localNames = ["code", "free", "category.name", "text"]
localNames = localNames.map((name) => localName + "-" + name)

const ArticlesList = ({ addButton, handleAdd }) => {
	const dispatch = useDispatch()
	const { articles, articleCategories, rawToken } = useSelector(
		({ apiReducer }) => apiReducer
	)

	const columns = useMemo(
		() => [
			{
				Header: "Code",
				accessor: "code",
				width: getColumnsWidth(localNames[0], 100),
			},
			{
				Header: "Sem",
				accessor: "free",
				width: getColumnsWidth(localNames[1], 60),
				// eslint-disable-next-line react/display-name
				Cell: ({ row }) => {
					const id = row._original.id
					return (
						<FreeTextInput
							key={id}
							value={renderIfDefined(row._original.free)}
							id={id}
							update={async (type, body) => {
								try {
									await dispatch(
										updateResource(
											"articles" + type,
											id,
											body,
											rawToken
										)
									)
								} catch (e) {}
							}}
						/>
					)
				},
			},
			{
				Header: "Catégorie",
				accessor: "category.name",
				width: getColumnsWidth(localNames[2], 200),
				// eslint-disable-next-line react/display-name
				Filter: ({ filter, onChange }) => (
					<SelectFilter
						filter={filter}
						localName={localNames[2]}
						onChange={onChange}
						options={articleCategories.map(
							(category) => category.name
						)}
					/>
				),
				// eslint-disable-next-line react/display-name
				Cell: ({ row }) =>
					isDefined(row._original.category) ? (
						<Link
							to={
								"/categories-articles/" +
								row._original.category.id +
								"/modifier"
							}
						>
							{row._original.category.name}
						</Link>
					) : (
						""
					),
			},
			{
				Header: "Désignation",
				accessor: "text",
				width: getColumnsWidth(localNames[3], 1300),
				// eslint-disable-next-line react/display-name
				Cell: ({ row }) => (
					<div
						className={"text"}
						dangerouslySetInnerHTML={{
							__html: row._original.text.substring(0, 1500),
						}}
					/>
				),
			},
			{
				Header: "Actions",
				accessor: "actions",
				sortable: false,
				resizable: false,
				width: addButton === true ? 150 : 100,
				// eslint-disable-next-line react/display-name
				Cell: ({ row }) => (
					<div className={"btn-flex"}>
						{addButton === true ? (
							<div
								className="btn-circle btn-primary"
								title={"Ajouter"}
								onClick={() => handleAdd(row._original.id)}
							>
								<i className="fas fa-upload" />
							</div>
						) : (
							""
						)}
						<Link
							to={"/articles/" + row._original.id + "/modifier"}
						>
							<div
								className="btn-circle btn-warning"
								title={"Modifier"}
							>
								<i className="fa fa-edit" />
							</div>
						</Link>
						<div
							className="btn-circle btn-danger"
							title={"Supprimer"}
							onClick={() => handleDelete(row._original.id)}
						>
							<i className="fa fa-trash" />
						</div>
					</div>
				),
			},
		],
		[articleCategories]
	)

	useEffect(() => {
		dispatch(getResources("articleCategories", rawToken))
		columns.forEach((column, i) => {
			if (i < columns.length && i !== 2)
				// eslint-disable-next-line react/display-name
				column.Filter = ({ filter, onChange }) => (
					<TextFilter
						localName={localNames[i]}
						filter={filter}
						onChange={onChange}
					/>
				)
		})
	}, [])

	const handleDelete = (id) =>
		onDelete(
			"",
			"Etes-vous sûr de vouloir supprimer cet article ?",
			async () => {
				try {
					await dispatch(deleteResource("articles", id, rawToken))
				} catch (e) {
					throw new Error(e)
				}
			}
		)

	return (
		<Table
			name={localName + "-list"}
			data={articles}
			columns={columns}
			frenchName={frenchName}
			localName={localName}
			apiName={apiName}
			className={"articles-list"}
			defaultFiltered={getDefaultFiltered(localName, columns)}
			defaultSorted={[
				{
					id: "code",
					desc: false,
				},
			]}
		/>
	)
}

export default ArticlesList
